import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js/index.jsx';

const AboutUs = () => {
  const { backgroundColor } = useActiveComponent();

  const fontColor = backgroundColor === '#ffffff' ? 'black' : 'lightgrey';

  return (
    <>
      <Box
        sx={{
          height: '100vh', // Full viewport height
          overflowY: 'auto', // Enable vertical scrolling of entire homepage
          mb: 0,
        }}
      >
        {/* Sticky header section with Typography and Tabs */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000, // Ensure it's above other content while scrolling
            backgroundColor: 'black', // Set background so it's visible while scrolling
            p: 1,
            mt: { lg: 2, xl: 2 },
          }}
        >
          <Box
            p={0}
            mt={0}
            mb={0}
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='h4'
              noWrap
              component='div'
              sx={{
                mt: 1,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'Montserrat',
                letterSpacing: '.3rem',
                fontSize: { xs: 12, sm: 12, md: 13, lg: 15, xl: 15 },
                flexDirection: 'center',
                color: 'white',
                textDecoration: 'none',
                cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
              }}
            >
              About
            </Typography>
          </Box>
        </Box>
        <Typography
          variant='body1'
          // noWrap
          component='div'
          sx={{
            mt: { xs: 2, sm: 2, md: 3, lg: 3, xl: 3 },
            mb: { xs: 1, sm: 3, md: 3, lg: 3, xl: 3 },
            width: { xs: '85%', sm: '85%', md: '85%', lg: '80%', xl: '80%' },
            display: { xs: 'flex', md: 'flex' },
            justifyContent: 'center',
            fontFamily: 'Montserrat',
            fontWeight: { xs: 200, sm: 200, md: 200, lg: 200, xl: 200 },
            letterSpacing: '0rem',
            fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
            color: 'white',
            textDecoration: 'none',
            whiteSpace: 'pre-line',
            textAlign: 'center',
            ml: 'auto',
            mr: 'auto',
          }}
        >
          Welcome to AfroSpiritArt, where spirit flows into creativity.
          <br /> <br />
          I’m Duke Yeboah, a multi-medium creative, weaving together ideas into
          digital expressions. My creations are simply reflections of my
          internal musings.
          <br /> <br />
          Art for me, is channeling of that endless Divine Creative Forces that
          exist within the fabric of reality. As an 'artist' I am simply a
          vessel bridging the unseen and seen, spiritual and physical worlds.{' '}
          <br /> <br /> My creations tendd to live along the blurred lines or
          the dream and awake states, as well as the ideas of simplicity and
          complexity. This is my way of contemplating the multidimensional
          fractal nature of existence.
          <br /> <br />
          So each piece for me, is a form of invitation, portal or doorway into
          the unexplored spaces of consciousness, in order to contemplate the
          nature of reality.
          <br /> <br /> These are my musings in color-full expression. Thank you
          for journeying with me.
        </Typography>
        <Box
          component='img'
          src={'./images/siteImages/moi.jpg'}
          alt='About image'
          sx={{
            width: {
              xs: 130,
              sm: 130,
              md: 250,
              lg: 220,
              xl: 220,
            },
            height: 'auto',
            borderRadius: '14px',
            display: 'block',
            margin: 'auto',
            mt: 3,
            mb: 2,
            borderRadius: '50%',
          }}
        />
      </Box>
    </>
  );
};

export default AboutUs;

// As it's  written: “If you don’t bring out what’s within you, what’s within you
//  will destroy you, but if you bring out what’s within you, what’s
//  within you will save you.”
