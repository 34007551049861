import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Button, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Tooltip from '@mui/material/Tooltip';
// import { getDownloadURL } from 'firebase/storage';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading indicators

const EditProfileDialog = ({
  openEditDialog,
  handleDialogClose,
  currentUser,
  setCurrentUser,
  updateUserProfile,
  updateUserImage,
}) => {
  const [updatedDisplayName, setUpdatedDisplayNameState] = useState(
    currentUser.displayName
  );
  const [updatedUsersname, setUpdatedUsersnameState] = useState(
    currentUser.username
  );
  const [updatedLink, setUpdatedLinkState] = useState(currentUser.link);
  const [updatedBio, setUpdatedBioState] = useState(currentUser.bio);
  const [updatedProfileImage, setUpdatedProfileImage] = useState(
    currentUser.photo || '../images/siteImages/defaultAvatar.jpg'
  );
  
  const [updatedBannerImage, setUpdatedBannerImage] = useState(
    currentUser.bannerImage || '../images/siteImages/dashboardDesign.png'
  );
  const [isProfileImageLoading, setProfileImageLoading] = useState(false);
  const [isBannerImageLoading, setBannerImageLoading] = useState(false);
  console.log('current User is = ' + JSON.stringify(currentUser.uid, null, 2));

  // const [email, setEmail] = useState(userEmail);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const validateFields = () => {
    if (updatedDisplayName.length > 20) {
      setSnackbarMessage('Display Name must be 20 characters or less.');
      setSnackbarOpen(true);
      return false;
    }
    if (updatedUsersname.length > 20) {
      setSnackbarMessage('Username must be 15 characters or less.');
      setSnackbarOpen(true);
      return false;
    }
    if (updatedBio.length > 100) {
      setSnackbarMessage('Bio must be 100 characters or less.');
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  // Unified handler for profile and banner image uploads
  const handleImageUpload = async (file, type) => {
    if (!file || !['profileImage', 'bannerImage'].includes(type)) {
      console.error('Invalid file or type.');
      return;
    }

    const storage = getStorage(); // Initialize storage
    const loadingSetter =
      type === 'profileImage' ? setProfileImageLoading : setBannerImageLoading;
    loadingSetter(true);

    try {
      // Define the file path dynamically based on type
      const filePath = `users/${currentUser.uid}/${type}/${file.name}`;
      const storageRef = ref(storage, filePath);
      await uploadBytes(storageRef, file); // Upload the file to Firebase Storage
      const downloadURL = await getDownloadURL(storageRef); // Get the download URL of the uploaded file

      // // Update Firestore and AuthContext
      // await updateUserImage(type, downloadURL);

      // Update the local state to reflect the new image immediately
      if (type === 'profileImage') {
        setUpdatedProfileImage(downloadURL);
      } else if (type === 'bannerImage') {
        setUpdatedBannerImage(downloadURL);
      }
      console.log(`${type} uploaded successfully.`);
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
    } finally {
      loadingSetter(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (!validateFields()) {
      return; // Abort submission if validation fails
    }
    try {
      // Update user profile in Firebase
      await updateUserProfile({
        displayName: updatedDisplayName,
        usersname: updatedUsersname,
        link: updatedLink,
        bio: updatedBio,
        //profileImage: updatedProfileImage, // Pass the temporary image URL
        photo: updatedProfileImage, 
        bannerImage: updatedBannerImage,
      });
      // console.log('Profile updated successfully');
      // Display success snackbar
      setSnackbarMessage('Profile updated successfully!');
      setSnackbarOpen(true);
      // Close the dialog after submission
      handleDialogClose();
      console.log('handle submit clicked');
    } catch (error) {
      console.error('Error updating profile:', error);
      // Display error snackbar
      setSnackbarMessage('Error updating profile. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleDialogClosing = () => {
    // Reset temporary images to the original values from context
    //setUpdatedProfileImage(currentUser.profileImage);
    //setUpdatedProfileImage(currentUser.photoURL);
    setUpdatedProfileImage(currentUser.photo);
    setUpdatedBannerImage(currentUser.bannerImage);
    setUpdatedDisplayNameState(currentUser.displayName);
    setUpdatedUsersnameState(currentUser.username);
    setUpdatedLinkState(currentUser.link);
    setUpdatedBioState(currentUser.bio);
    handleDialogClose();
  };

  //reflects the most up-to-date user-info even if currentUser updates after the component mounts (e.g., due to profile changes)
  useEffect(() => {
    setUpdatedDisplayNameState(currentUser.displayName || '');
    setUpdatedUsersnameState(currentUser.username || '');
    setUpdatedLinkState(currentUser.link || '');
    setUpdatedBioState(currentUser.bio || '');
    // setUpdatedProfileImage(currentUser.profileImage || currentUser.photoURL)
    // setUpdatedBannerImage(currentUser.bannerImage)
  }, [currentUser]);

  return (
    <>
      {/* Dialog for editing profile */}
      <Dialog open={openEditDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleDialogClosing}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {/* Profile Image Section */}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ mb: 1 }}>Profile Image</Typography>
              {/* Container for Avatar and Camera Icon */}
              <Box
                sx={{
                  position: 'relative', // Allows the IconButton to be positioned relative to this Box
                  width: 100,
                  height: 100,
                }}
              >
                <Avatar
                  // src={
                  //   currentUser.photoURL ||
                  //   '../images/siteImages/defaultAvatar.jpg'
                  // }
                  src={updatedProfileImage}
                  alt='Profile'
                  sx={{
                    position: 'relative',
                    width: 100,
                    height: 100,
                    mb: 2,
                    //top:120,
                  }}
                />
                {isProfileImageLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}

                <Tooltip title='Change Profile Image'>
                  <IconButton
                    component='label'
                    sx={{
                      position: 'absolute',
                      backgroundColor: 'rgba(240, 240, 240, 0.8)',
                      bottom: 10, // Position at bottom-right
                      right: -5,
                      //   top: '50%', // Center vertically
                      // left: '50%', // Center horizontally
                      // transform: 'translate(-50%, -50%)', // Adjust for centering

                      // top: 172,
                      // left: 320,
                      borderRadius: '50%',
                      p: 0.2,
                      boxShadow: 1,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        boxShadow: '0px 0px 5px inset grey',
                      },
                    }}
                    // onClick={handleMenuClick}
                  >
                    <PhotoCameraIcon
                      sx={{
                        color: 'black',
                        //backgroundColor: 'rgba(240, 240, 240, 0.8)',
                        // borderRadius: '50%',

                        fontSize: '18px',
                        p: 0.5,
                        '&:hover': { color: 'white' },
                      }}
                    />
                    {/* Hidden Input */}
                    <input
                      type='file'
                      hidden
                      accept='image/*'
                      //onChange={(e) => handleImageChange(e, 'profileImage')}
                      // onChange={(e) => handleProfileImageUpload(e.target.files[0])}
                      onChange={(e) =>
                        handleImageUpload(e.target.files[0], 'profileImage')
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {/* Banner Image Section */}
            <Typography sx={{ mt: 3, mb: 1, textAlign: 'center' }}>
              Dashboard Banner
            </Typography>
            <Box
              sx={{
                position: 'relative', // Allows the IconButton to be positioned relative to this Box
              }}
            >
              <Box
                component='img'
                // src={'../images/siteImages/dashboardDesign.png'}
                src={updatedBannerImage}
                alt='Dashboard Banner'
                sx={{
                  width: '100%',
                  height: 150,
                  objectFit: 'cover',
                  mb: 2,
                  borderRadius: '10px',
                }}
              />
              {isBannerImageLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}

              <Tooltip title='Change Banner Image'>
                <IconButton
                  component='label'
                  sx={{
                    position: 'absolute',
                    backgroundColor: 'rgba(240, 240, 240, 0.8)',
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)', // Adjust for centering
                    borderRadius: '50%',
                    p: 0.5,
                    boxShadow: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      boxShadow: '0px 0px 5px inset grey',
                    },
                  }}
                  // onClick={handleMenuClick}
                >
                  <PhotoCameraIcon
                    sx={{
                      color: 'black',
                      fontSize: '22px',
                      p: 0.3,
                      '&:hover': { color: 'white' },
                    }}
                  />
                  {/* Hidden Input */}
                  <input
                    type='file'
                    hidden
                    accept='image/*'
                    // onChange={(e) => handleImageChange(e, 'bannerImage')}
                    // onChange={(e) => handleBannerImageUpload(e.target.files[0])}
                    onChange={(e) =>
                      handleImageUpload(e.target.files[0], 'bannerImage')
                    }
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {/* <Button
              variant='outlined'
              component='label'
              // fullWidth
              style={{ width: 'medium' }}
            >
              Upload Banner Image
              <input
                type='file'
                hidden
                accept='image/*'
                onChange={(e) => handleImageChange(e, 'bannerImage')}
              />
            </Button> */}

            <TextField
              autoFocus
              margin='dense'
              label='Display Name'
              type='text'
              fullWidth
              variant='outlined'
              value={updatedDisplayName}
              inputProps={{ maxLength: 20 }}
              // onChange={(e) => setDisplayName(e.target.value)}
              onChange={(e) => setUpdatedDisplayNameState(e.target.value)}
            />

            <TextField
              margin='dense'
              label='Username'
              type='text'
              fullWidth
              variant='outlined'
              value={updatedUsersname}
              inputProps={{ maxLength: 20 }}
              // onChange={(e) => setUsersname(e.target.value)}
              onChange={(e) => setUpdatedUsersnameState(e.target.value)}
            />
            <TextField
              margin='dense'
              label='Link'
              type='text'
              fullWidth
              variant='outlined'
              value={updatedLink}
              // onChange={(e) => setLink(e.target.value)}
              onChange={(e) => setUpdatedLinkState(e.target.value)}
            />
            <TextField
              margin='dense'
              label='Bio'
              type='text'
              fullWidth
              variant='outlined'
              multiline
              rows={4}
              value={updatedBio}
              // onChange={(e) => setBio(e.target.value)}
              onChange={(e) => setUpdatedBioState(e.target.value)}
              placeholder='Bio (Max 100 characters)'
              inputProps={{ maxLength: 100 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClosing} color='primary'>
            Cancel
          </Button>
          <Button sx={{borderRadius:'20px', backgroundColor:'darkblue'}}onClick={handleSubmit} color='primary' variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
};

export default EditProfileDialog;

// const handleImageChange = async (event, type) => {
//   const file = event.target.files[0];
//   if (!file) return;

//   try {
//     const userId = auth.currentUser.uid;
//     const storageRef = ref(storage, `users/${userId}/${type}`);
//     await uploadBytes(storageRef, file);
//     const downloadURL = await getDownloadURL(storageRef);

//     // Update in Firestore
//     const userRef = doc(db, 'users', userId);
//     await setDoc(
//       userRef,
//       { [type]: downloadURL },
//       { merge: true } // Merge to avoid overwriting other fields
//     );

//     // Update Auth Context
//     setCurrentUser((prev) => ({
//       ...prev,
//       [type === 'profileImage' ? 'photoURL' : 'bannerURL']: downloadURL,
//     }));

//     console.log(`${type} updated successfully`);
//   } catch (error) {
//     console.error(`Error updating ${type}:`, error);
//   }
// };

//   // Handler for profile image upload
// const handleProfileImageUpload = async (file) => {
//   try {
//     const filePath = `users/${currentUser.uid}/profileImage`;
//     const storageRef = ref(storage, filePath);
//     await uploadBytes(storageRef, file); // Upload file to Firebase Storage
//     const downloadURL = await getDownloadURL(storageRef); // Get download URL

//     // Update Firestore and AuthContext
//     await updateUserImage('profileImage', downloadURL);
//   } catch (error) {
//     console.error('Error uploading profile image:', error);
//   }
// };

// // Handler for banner image upload
// const handleBannerImageUpload = async (file) => {
//   try {
//     const filePath = `users/${currentUser.uid}/bannerImage`;
//     const storageRef = ref(storage, filePath);
//     await uploadBytes(storageRef, file); // Upload file to Firebase Storage
//     const downloadURL = await getDownloadURL(storageRef); // Get download URL

//     // Update Firestore and AuthContext
//     await updateUserImage('bannerImage', downloadURL);
//   } catch (error) {
//     console.error('Error uploading banner image:', error);
//   }
// };

{
  /* <Button
    variant="outlined"
    component="label"
    style={{ width: 'medium'}}
    //fullwidth
  >
    
    Change Profile Image
    <input
      type="file"
      hidden
      accept="image/*"
      onChange={(e) => handleImageChange(e, 'profileImage')}
    />
  </Button> */
}

//BEFORE WHEN I was updating displayName, username and bio directly and not using currentUser
// const [updatedDisplayName, setUpdatedDisplayNameState] =
//   //   useState(userDisplayName);
//   // const [updatedUsersname, setUpdatedUsersnameState] = useState(username);
//   // // const [email, setEmail] = useState(userEmail);
//   // const [updatedLink, setUpdatedLinkState] = useState(userLink);
//   // const [updatedBio, setUpdatedBioState] = useState(userBio);

//   const [updatedDisplayName, setUpdatedDisplayNameState] =
//   useState(currentUser.displayName);
// const [updatedUsersname, setUpdatedUsersnameState] = useState(currentUser.username);
// // const [email, setEmail] = useState(userEmail);
// const [updatedLink, setUpdatedLinkState] = useState(currentUser.link);
// const [updatedBio, setUpdatedBioState] = useState(currentUser.bio);

//   console.log(updatedUsersname,updatedLink, updatedBio )
//   console.log(username,userLink, userBio )
//   console.log("current User is - "+JSON.stringify(currentUser.bio,null,2))

//   const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent default form submission
//     // console.log('handle submit clicked');
//     try {
//       // Update user profile in Firebase
//       await updateUserProfile({
//         displayName: updatedDisplayName,
//         usersname: updatedUsersname,
//         link: updatedLink,
//         bio: updatedBio,
//       });

//       console.log('Profile updated successfully');
//       // setDisplayName(updatedDisplayName);
//       // setUsersname(updatedUsersname);
//       // setUserLink(updatedLink);
//       // setUserBio(updatedBio);

//       // Close the dialog after submission
//       handleDialogClose();
//       console.log('handle submit clicked');
//     } catch (error) {
//       console.error('Error updating profile:', error);
//     }
//   };

{
  /* <TextField
              margin='dense'
              label='Email'
              type='email'
              fullWidth
              variant='outlined'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            /> */
}
