import './CSS/style.css';
import ReactDOM from 'react-dom/client';
import App from './Components/App/index.jsx';
import { StrictMode, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { artCollections } from './Data/pageData.jsx';
import { PostsProvider } from './contexts/PostsContext/index.jsx';
import { AuthProvider } from './contexts/AuthContext/index.jsx';
import { AdsProvider } from './contexts/AdsContext/index.jsx';
import { countriesList } from './Data/pageData.jsx';
import Footer from './Components/Footer/index.jsx';
import { LocationProvider } from './contexts/LocationContext/index.jsx';

const container = document.querySelector('#root');
const root = ReactDOM.createRoot(container);

root.render(
  // <StrictMode>
  <AuthProvider>
    <LocationProvider>
      <Router>
        <PostsProvider collections={artCollections}>
          {/* <AdsProvider categories={categories}> */}
          <App />
          {/* </AdsProvider> */}
        </PostsProvider>
      </Router>
    </LocationProvider>
  </AuthProvider>
  // </StrictMode>
);
