import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { Box } from '@mui/material';
import { ActiveComponentProvider } from '../ActiveComponentContext.js';
import AlertDialog from '../AlertDialog/index.jsx';

import { useAuth } from '../../contexts/AuthContext/index.jsx';
import MyAppBar from '../MyAppBar/index.jsx';
import Loading from '../Loading';
import useDelay from '../../Hooks/useDelay.js';
import Dashboard from '../../Pages/Dashboard/index.jsx';
//import PrivateRoute from '../PrivateRoute/index.jsx';
import CreatePost from '../../Pages/CreatePost/index.jsx';
import LoginDialog from '../LoginDialog/index.jsx';
import PostDetail from '../../Pages/PostDetail/index.jsx';
import MyPosts from '../../Pages/MyPosts/index.jsx';
import SavedPosts from '../../Pages/SavedPosts/index.jsx';
import UserProfiles from '../../Pages/UserProfiles/index.jsx';
import ProtectedRoute from '../ProtectedRoute/index.jsx';
import AboutUs from '../../Pages/AboutUs/index.jsx';
import Shop from '../../Pages/Shop/index.jsx';
import Gallery from '../../Pages/Gallery/index.jsx';
import Worlds from '../../Pages/Worlds/index.jsx';
import ContactUs from '../../Pages/ContactUs/index.jsx';
import UserPostsPage from '../../Pages/UserPostsPage/index.jsx';
import PostAd from '../../Pages/PostAd/index.jsx';
import { routesConfig } from '../../Data/pageData.jsx';
import CountrySelect from '../CountrySelect/index.jsx';
import SearchBar from '../SearchBar/index.jsx';
import Footer from '../Footer/index.jsx';
import CollectionPage from '../../Pages/CollectionPage/index.jsx';

// import { Canvas } from '@react-three/fiber';
// import Experience from '../Experience/index.jsx';

const App = () => {
  const {
    loginOpen,
    openLogInModal,
    handleLoginClose,
    showAlertDialog,
    setShowAlertDialog,
    openDrawer,
    setOpenDrawer,
  } = useAuth(); // Use the AuthContext to get current user

  const isDelayed = useDelay(3000); // 3000ms delay
  const location = useLocation();
  if (isDelayed) {
    return <Loading />;
  }

  return (
    <ActiveComponentProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          overflowX: 'hidden',
          overflowY: 'auto', // Allow vertical scrolling inside the app container
          backgroundColor: '#030202',
          //border:'2px solid green',
          position: 'sticky', //keep top app bar fixed
        }}
      >
        <MyAppBar />
        <Routes>
          {routesConfig.map(({ path, element, category }, index) =>
            path === '/' ? (
              <Route
                key={index}
                exact
                path={path}
                element={element}
                categoryName={category}
              />
            ) : (
              <Route
                key={index}
                path={path}
                element={element}
                categoryName={category}
              />
            )
          )}
          {/* <Route path='/userprofiles/' element={<UserProfiles />} />
          <Route path='/:username' element={<UserPostsPage />} />
          <Route path='/posts/:id' element={<PostDetail />} /> */}
          <Route path='/shop' element={<Shop />} />
          <Route path='/worlds' element={<Worlds />} />
          <Route path='/:artCollection' element={<CollectionPage />} />
          {/* <Route path='/gallery' element={<Gallery />} /> */}
          {/* <Route path="/collection/:artCollection" element={<CollectionPage />} /> */}
          {/* <PrivateRoute path="/createpost" component={CreatePost} />
        <PrivateRoute path="/dashboard" component={Dashboard} /> */}
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path='myposts' element={<MyPosts />} />
          </Route>
        </Routes>
        {/* <AlertDialog
          open={showAlertDialog}
          handleCloseAlert={() => {
            setShowAlertDialog(false);
            if (openDrawer) {
              setOpenDrawer(false);
            }
          }}
          handleLogin={openLogInModal} // Pass the function to open login modal
        /> */}
        <LoginDialog open={loginOpen} onClose={handleLoginClose} />
      </Box>
      {/* <Footer /> */}
    </ActiveComponentProvider>
  );
};

export default App;

{
  /* {
          location.pathname !== '/contactus' && (
            <Box
              sx={{
                p: 0,
                boxShadow: '-10px 0px 12px black',
              }}
            >
              <AnimatedScrollBox
                // data={youTubeData}
                height='16vh'
                category='Ads'
                path='/advertisements'
              />
            </Box>
          )
        } */
}

//Previously used for 3D animations:
// Conditional zIndex based on location.pathname
//const zIndexValue = location.pathname === '/' ? -1 : -2;

//FOR 3D - WAS UNDER FIRST BOX COMPONENT:
{
  /* <Canvas
          shadows
          camera={{
            fov: 80,
            near: 0.1,
            far: 500,
            position: [0, 0, 4.5],
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            //conditional z-index based on paths
            zIndex: zIndexValue, //-1 =  Canvas in background & uncontrollable
          }}
        >
          <Experience currentPath={location.pathname} />
        </Canvas> */
}

//PREVIOUS ROUTES SETUP BEFORE MAPPING:
{
  /* <Route exact path='/' element={<Home />} />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path='createdposts' element={<CreatedPosts />} />
            <Route path='savedposts' element={<SavedPosts />} />
          </Route>

          <Route
            path='/createpost'
            element={
              <ProtectedRoute>
                <CreatePost />
              </ProtectedRoute>
            }
          />
          <Route
            path='/postad'
            element={
              <ProtectedRoute>
                <PostAd />
              </ProtectedRoute>
            }
          />
          <Route path='/foodanddrinks' element={<FoodAndDrinks />} />
          <Route path='/rentals' element={<Rentals />} />
          <Route path='/propertiesforsale' element={<PropertiesForSale />} />
          <Route path='/visaandlegal' element={<VisaAndLegal />} />
          <Route path='/localfacilitator' element={<LocalFacilitator />} />
          <Route
            path='/translationservices'
            element={<TranslationServices />}
          />
          <Route path='/healthcareservices' element={<HealthcareServices />} />
          <Route path='/facebookgroups' element={<FacebookGroups />} />
          <Route path='/languagegroups' element={<LanguageGroups />} />
          <Route path='/othergroups' element={<OtherGroups />} />
          <Route
            path='/ecolodgesanduniquestays'
            element={<EcoLodgesAndUniqueStays />}
          />
          <Route
            path='/healthbeautyandwellness'
            element={<HealthBeautyAndWellness />}
          />

          <Route path='/travelandtours' element={<TravelAndTours />} />
          <Route path='/localevents' element={<LocalEvents />} />
          <Route path='/coworkingspaces' element={<CoWorkingSpaces />} />
          <Route path='/artsandcrafts' element={<ArtsAndCrafts />} />
          <Route path='/localclasses' element={<LocalClasses />} />
          <Route path='/marketplace' element={<MarketPlace />} />
          <Route path='/babysitting' element={<BabySitting />} />
          <Route path='/houseandpetsitting' element={<HouseAndPetSitting />} />
          <Route path='/handymanservices' element={<HandyManServices />} />
          <Route
            path='/internetserviceproviders'
            element={<InternetServiceProviders />}
          />
          <Route path='/cleaningservices' element={<CleaningServices />} />
          <Route path='/billpayingservices' element={<BillPayingServices />} />
          <Route
            path='/shoppinganddelivery'
            element={<ShoppingAndDelivery />}
          />
          <Route path='/movingservices' element={<MovingServices />} />
          <Route
            path='/shippingandmuleservices'
            element={<ShippingAndMuleServices />}
          />
          <Route path='/localdrivers' element={<LocalDrivers />} />
          <Route path='/announcements' element={<Announcements />} />
          <Route path='/discussionforums' element={<DiscussionForums />} />
          <Route path='/helpwanted' element={<HelpWanted />} />
          <Route path='/lookingfor' element={<LookingFor />} />
          <Route path='/other' element={<Other />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/contactus' element={<ContactUs />} /> */
}
{
  /* <Route path="/categories/:category" element={<CategoryPage />} /> */
}
