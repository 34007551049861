import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import { drawerList } from '../../Data/pageData.jsx';
import { useActiveComponent } from '../ActiveComponentContext.js';
//import { useAuth } from '../AuthContext/index.jsx';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import AlertDialog from '../AlertDialog';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme, Tooltip } from '@mui/material';

const TemporaryDrawer = ({ auth, openLoginModal }) => {
  //CONTEXTS
  const { activePath, setActivePath } = useActiveComponent();
  const { showAlertDialog, setShowAlertDialog, openDrawer, setOpenDrawer } =
    useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [expandedItems, setExpandedItems] = React.useState({
    realEstateOpen: false,
    socialCommunitiesOpen: false,
    homeCareOpen: false,
  });

  const resetExpandedItems = () => {
    setExpandedItems({
      realEstateOpen: false,
      socialCommunitiesOpen: false,
      homeCareOpen: false,
    });
  };
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // breakpoints for where screen size is decided

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
    // Collapse all expanded sub-items when closing the drawer
    if (!newOpen) {
      resetExpandedItems();
    }
  };

  const toggleItem = (item) => () => {
    setExpandedItems((prev) => ({
      ...prev,
      [item]: !prev[item],
    }));
  };

  const handleNavigation = (path) => {
    resetExpandedItems();
    navigate(`/${path}`); // Navigate to the respective path
    setActivePath(`/${path}`);
    setOpenDrawer(false); // Close the drawer after navigating
  };
  const handleCreatePostClick = () => {
    if (auth) {
      handleNavigation('createpost'); // Navigate to the 'create-post' path if auth is signed in
    } else {
      setShowAlertDialog(true); // Show the alert dialog if auth is not signed in
    }
  };

  const handlePostAdClick = () => {
    if (auth) {
      handleNavigation('postad'); // Navigate to the 'create-post' path if auth is signed in
    } else {
      setShowAlertDialog(true); // Show the alert dialog if auth is not signed in
    }
  };

  const menuIconColor = openDrawer === false ? 'white' : 'white';
  const menuIconBackground = openDrawer === false ? 'red' : 'black';

  const activeStyles = {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  };

  const activeIconStyles = {
    color: 'white',
  };

  //onClick={toggleDrawer(false)}
  const DrawerList = (
    <Box sx={{ width: 60 }} role='presentation'>
      <List>
        {drawerList.map((text, index) => {
          // Determine if any sub-item is active
          const isSubItemActive = text.subItems?.some(
            (subItem) => `/${subItem.path}` === activePath
          );

          return (
            <React.Fragment key={text.name}>
              {/* Use React.Fragment with key */}
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    p: 0.55,
                    //border:'2px solid red',
                    ...(activePath === `/${text.path}` && activeStyles), // Apply styles if activePath matches
                    '&:hover': {
                      backgroundColor:
                        activePath === `/${text.path}` ? 'black' : 'grey', // Keep active background black on hover
                      color: 'white', // Keep text color white on hover
                    },
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    justifyContent: 'flex-start',
                    alignItems: 'center', // Center align items
                  }}
                  onClick={() => handleNavigation(text.path)
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      p: 0,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        //border:'2px solid green',
                        pb: 0,
                        pr: 0,
                        minWidth: 'auto', // Remove default width to fit icon size
                        marginRight: 0, // Remove extra margin on the right
                        //width:2,
                        ...(activePath === `/${text.path}` && activeIconStyles), // Apply styles if activePath matches
                        '&:hover': {
                          color: 'white', // Keep icon color white on hover
                        },
                      }}
                    >
                      {/* Reduce the size of the icon */}
                      {/* {React.cloneElement(text.icon, { fontSize: isMdUp?'small':'extra-small' })} */}
                      {React.cloneElement(text.icon, { fontSize: 'small' })}

                      {/* {text.icon} */}
                    </ListItemIcon>
                    <ListItemText
                      //primary={text.name}
                      primary={
                        <Typography
                          variant='body2' // You can experiment with other variants if needed
                          sx={{
                            mt: 0,
                            mb: { xs: -0.4, sm: -0.5, md: 0, lg: 0, xl: 0 },
                            fontSize: {
                              xs: '6.5px',
                              sm: '6.5px',
                              md: '7px',
                              lg: '7px',
                              xl: '7px',
                            }, // Set the font size to 2px
                            // textAlign: 'flex-start',
                            textAlign: 'center',
                            //border:'1px solid blue'
                          }}
                        >
                          {text.name}
                        </Typography>
                      }
                      sx={{
                        // typography: 'caption', // Use caption style for smaller text
                        // fontSize: '2px', // Set font size to 2px
                        // color:'green',
                        textAlign: 'flex-start', // Center align the text
                        marginTop: {
                          xs: 0.25,
                          sm: 0.5,
                          md: 0.5,
                          lg: 0.5,
                          xl: 0.5,
                        }, // Add some space between the icon and text
                      }}
                    />
                    {text.subItems &&
                      (text.name === 'Real Estate' ? (
                        expandedItems.realEstateOpen ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      ) : expandedItems.socialCommunitiesOpen ||
                        isSubItemActive ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMoreIcon />
                      ))}
                  </Box>
                </ListItemButton>
              </ListItem>

              {text.subItems && (
                <Collapse
                  in={
                    text.name === 'Real Estate'
                      ? expandedItems.realEstateOpen || isSubItemActive
                      : text.name === 'Social & Communities'
                      ? expandedItems.socialCommunitiesOpen || isSubItemActive
                      : false
                  }
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    {text.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.name}
                        disablePadding
                        sx={{ pl: 4 }}
                      >
                        <ListItemButton
                          sx={{
                            ...(activePath === `/${subItem.path}` &&
                              activeStyles), // Apply styles if activePath matches
                            '&:hover': {
                              backgroundColor:
                                activePath === `/${subItem.path}`
                                  ? 'black'
                                  : 'grey', // Keep active background black on hover
                              color: 'white', // Keep text color white on hover
                            },
                          }}
                          onClick={() => handleNavigation(subItem.path)}
                        >
                          <ListItemIcon
                            sx={{
                              ...(activePath === `/${subItem.path}` &&
                                activeIconStyles), // Apply styles if activePath matches
                              '&:hover': {
                                color: 'white', // Keep icon color white on hover
                              },
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={subItem.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
  return (
    <>
      {openDrawer ? (
        <MenuOpenIcon
          onClick={toggleDrawer(true)}
          sx={{
            borderRadius: '10%',
            color:'black',
            backgroundColor:'white',
            p: 0,
            ml: 1,
            fontSize: { xs: 28, sm: 28, md: 28, lg: 30, xl: 30 },
            '&:hover': {
              color: 'black',
            },
          }}
        />
      ) : (
        <MenuIcon
          onClick={toggleDrawer(true)}
          sx={{
            ml: 1,
            mt:{xs:0,lg:1},
            color: 'white',
            backgroundColor: 'none',
            fontSize: { xs: 26, sm: 26, md: 28, lg: 28, xl: 28 },
            '&:hover': {
              color: 'black',
              backgroundColor:'white'
            },
          }}
        />
      )}
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start', // Align items at the start
            height: 'auto', // Allow height to be determined by content
            maxHeight: '90vh', // Optional: Set a maximum height for the Drawer
            top: '8.5%', // Adjust this value based on your AppBar height
          },
        }}
      >
        {DrawerList}
      </Drawer>
      <AlertDialog
        open={showAlertDialog}
        handleCloseAlert={() => setShowAlertDialog(false)}
        // handleSignUp={openSignUpModal}   // Pass the function to open sign-up modal
        //handleLogin={openLoginModal} // Pass the function to open login modal
      />
    </>
  );
};

export default TemporaryDrawer;
