import React, { useState } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
import { useActiveComponent } from '../ActiveComponentContext.js';
//import { useAuth } from '../AuthContext';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import { generatePath } from '../../utils/categoryUtils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccordionExpand from '../Accordian';
import DeleteIcon from '@mui/icons-material/Delete';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import XIcon from '@mui/icons-material/X';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { borderRadius } from '@mui/system';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const DashboardCards = ({
  id,
  postID,
  images = [], //array of images
  title,
  summary,
  externalLinks,
  photoURL,
  username,
  certification,
  name,
  email,
  phoneNumber,
  address,
  date,
  category,
  country,
  onClick,
  postsCount,
  vertical = '200px',
  horizontal = '450px',
  ratings,
  deletable,
  onDelete,
}) => {
  //Context for bookmark management and Alert/login dialogs
  const {
    currentUser,
    setShowAlertDialog,
    setIsBookmarkForAlert,
    bookmarks, // Provide the bookmarks state
    toggleBookmark, // Provide the toggleBookmark function
  } = useAuth();

  const { setActivePath } = useActiveComponent();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // breakpoints for where screen size is decided
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const [currentIndex, setCurrentIndex] = useState(0); // Index for the current image
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const isBookmarked = bookmarks[postID] || false;

  const showPostInfo = () => {
    onClick();
    handleClose();
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleCategoryClick = (category) => {
    const path = generatePath(category);
    setActivePath(path); // Update the active path
    navigate(path);
  };

  //Handle navigation to username page, when it's clicked
  const handleUsernameClick = (path) => {
    navigate(path);
    setActivePath(path);
  };

  //What happens when I click the card, maybe include respective links in future?
  // const handleCardClick = () => {
  //   // window.location.href = link;
  //   window.open(link, '_blank', 'noopener,noreferrer');
  // };

  //For use in Created post for user to delete their own post
  const handleDeletePost = async () => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this post?'
    );
    if (!confirmDelete) return;

    try {
      const postDocRef = doc(db, 'posts', postID);
      await deleteDoc(postDocRef);
      alert('Post deleted successfully');
      // Call the onDelete function passed as a prop to update the state in CreatedPosts
      onDelete();
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('Failed to delete post. Please try again later.');
    }
  };

  //Opens post in its own page
  const handleClick = () => {
    navigate(`/post/${postID}`, {
      state: {
        images,
        title,
        summary,
        photoURL,
        username,
        externalLinks,
        certification,
        name,
        email,
        phoneNumber,
        address,
        date,
        category,
        country,
      },
    });
  };

 

  //used to Handle Bookmark, with isBookmarked stored in context:
  const handleBookmarkClick = () => {
    if (!currentUser) {
      setShowAlertDialog(true);
      setIsBookmarkForAlert(true);
      return;
    }
    // different Info sent to firebase when post is bookmared
    toggleBookmark(postID, {
      creatorsUserId:id || null,
      title,
      summary,
      images,
      photoURL,
      username,
      externalLinks,
      certification,
      name,
      email,
      phoneNumber,
      address,
      date,
      category,
      country,
    });
  };

  //to manage sharing post via social media etc..
  const handleShare = (platform) => {
    const postUrl = `${window.location.origin}/posts/${postID}`;
    let shareUrl = '';
    const shareText = `${title}\n\n${summary}\n\n${postUrl}`; // Including title, summary, and URL

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/share?url=${encodeURIComponent(
          postUrl
        )}&text=${encodeURIComponent(title)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          postUrl
        )}&quote=${encodeURIComponent(shareText)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(shareText)}`;
        break;
        break;
      case 'message':
        shareUrl = `sms:?body=${encodeURIComponent(shareText)}`;

        break;
      case 'copy':
        navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard!');
        return;
      default:
        break;
    }
    if (shareUrl) {
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  };

  // Set height conditionally based on screen size
  const height = isMdUp && isLgUp ? vertical : '260px';
  const cardWidth = isMdUp && isLgUp ? horizontal : '480px';

  return (
    <Card
      sx={{
        p: 1.2,
        //border:'2px solid red',
        maxWidth: 400,
        borderRadius: 4,
        ml:'auto',
        mr:'auto',
        width: { xs: 300, sm: 300, md: 350, lg: cardWidth, xl: cardWidth },
        height: 230,
        minHeight: { lg: 200, xl: 200 },
        '&:hover': {
          cursor: 'pointer',
          boxShadow: '0px 4px 20px rgba(0, 0, 50, 0.2)', // Custom hover effect
          transform: 'scale(1.02)', // Optional: adds a slight zoom effect
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          backgroundColor: 'ghostwhite',
        },
      }}
      //   onClick={handleCardClick}
    >
      {images.length > 0 && (
        <Box position='relative'>
          <CardMedia
            component='img'
            image={images[currentIndex]}
            alt='post image'
            sx={{
              // m:2,
              objectFit: 'cover', // Ensures the image covers the area
              //width: cardWidth, // Ensures the image fills the width of the card
              height: 160, // Matches the height set earlier
              width: { xs: 300, sm: 300, md: 350, lg: cardWidth, xl: cardWidth },
              borderRadius: 4,
              //width: cardWidth,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '95%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              //backgroundColor: 'rgba(0, 0, 0, 0.5)',
              p: 0.5,
            }}
          >
            <Typography
              variant='body'
              color='black'
              sx={{
                backgroundColor: 'white',
                pl: 1,
                pr: 1,
                pt: 0.2,
                pb: 0.2,
                fontSize: { xs: 8, sm: 8, md: 8, lg: 9, xl: 9 },
                borderRadius: 3,
                ml: 1,
                cursor: 'pointer',
                fontFamily: 'Montserrat',
                //mb: 0.5,
              }}
              onClick={() => handleCategoryClick(category)} // Handle category click
            >
              {category}
            </Typography>

            <IconButton disableRipple onClick={handleMenuClick}>
              <MoreHorizIcon
                sx={{
                  color: 'black',
                  backgroundColor: 'white',
                  p: 0.3,
                  fontSize: 11,
                  borderRadius: '50%',
                  mr: { xs: 0, sm: 0, md: 0.1, lg: 0.2, xl: 0.2 },
                }}
              />
            </IconButton>
            <Menu
              sx={{ borderRadius: 10 }}
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                sx={{ fontSize: 10, fontFamily: 'Montserrat' }}
                onClick={showPostInfo}
              >
                Detailed View
              </MenuItem>
              {/* <MenuItem
              sx={{ fontSize: 12 }}
              //onClick={() => navigate(`/post/${postID}`)}
              onClick={handleCopyLink}
            >
              Copy Link
            </MenuItem> */}
            </Menu>
          </Box>
          {/* Arrows only show when there is more than one image */}
          {images.length > 1 && (
            <Box>
              <IconButton
                sx={{
                  position: 'absolute',
                  width: '10px',
                  height: '10px',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  color: '#030202',
                  backgroundColor: 'white',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: 'dimgrey',
                    boxShadow: '0px 2px 3px grey',
                  },
                }}
                onClick={handlePrevImage}
              >
                <ArrowBackIosIcon sx={{ fontSize: 7 }} />
              </IconButton>
              <IconButton
                sx={{
                  position: 'absolute',
                  width: '10px',
                  height: '10px',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  color: '#030202',
                  backgroundColor: 'white',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: 'dimgrey',
                    boxShadow: '0px 2px 3px grey',
                  },
                }}
                onClick={handleNextImage}
              >
                <ArrowForwardIosIcon sx={{ fontSize: 7 }} />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      <CardContent sx={{ m: 0, pt: 1, pl: 0.5, pr: 0.5 }}>
        <Typography
          gutterBottom
          variant='h6'
          component='div'
          sx={{
            display: 'flex',
            textAlign: 'flex-start',
            maxHeight: { xs: 20, sm: 30, md: 40, lg: 25, xl: 25 },
            overflowY: 'auto',
            fontFamily: 'Montserrat',
            fontSize: { xs: 10, sm: 10, md: 11, lg: 12, xl: 12 },
            cursor: 'pointer',

            '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
              backgroundColor: 'transparent', // Hide scrollbar background by default
            },
            '&:hover::-webkit-scrollbar': {
              backgroundColor: '#e0e0e0', // Show the scrollbar on hover
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: '#888', // Show the scrollbar on hover
              borderRadius: '10px', // Round the corners of the scrollbar thumb
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555', // Darker color when hovered over the scrollbar thumb
            },
          }}
          onClick={handleClick}
        >
          {title}
        </Typography>

        {/* Icons for sending links and bookmark */}
        {/* Bookmark Icon */}
        {deletable ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 0,
              mb: 0,
              ml: 0,
              mr: 0,
            }}
          >
            <Box>
              <Typography
                onClick={() => handleUsernameClick(`/${username}`)}
                variant='body1'
                color='text.secondary'
                sx={{
                  mt: 0,
                  fontFamily: 'Montserrat',
                  fontSize: { xs: 8, sm: 9, md: 9, lg: 11, xl: 11 },
                  '&:hover': {
                    cursor: 'pointer',
                    textShadow: '0px 0px 0.5px black',
                  },
                }}
              >
                {photoURL && (
                  <IconButton
                    disableRipple
                    // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                    sx={{
                      p: 0,
                    }}
                  >
                    <Avatar
                      alt={name || 'User'}
                      src={photoURL || null}
                      sx={{
                        width: { xs: 22, sm: 22, md: 22, lg: 24, xl: 24 },
                        height: { xs: 22, sm: 22, md: 22, lg: 24, xl: 24 },
                        mr: 0.5,
                        ml: 0.5,
                        mb: 0,
                        //border:'2px solid blue',
                        fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                        bgcolor: photoURL ? 'crimson' : 'violet',
                        '&:hover': {
                          cursor: 'pointer',
                          boxShadow: '0px 0px 4px black',
                        },
                      }}
                    >
                      {!photoURL && (name?.[0] || email?.[0] || 'U')}
                    </Avatar>
                  </IconButton>
                )}
                {username}
              </Typography>
            </Box>
            <Tooltip title={'Delete Post'}>
              <IconButton
                disableRipple
                onClick={handleDeletePost} // Attach the delete function here
                //</Tooltip>onClick={handleBookmarkClick}
              >
                <DeleteIcon
                  sx={{
                    fontSize: { xs: 18, sm: 18, md: 19, lg: 22, xl: 22 },
                    mt: 0,
                    color: 'indianred',
                    '&:hover': {
                      color: 'crimson',
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 0,
              mb: 0,
              ml: 0,
              mr: 0,
            }}
          >
            <Box>
              <Typography
                onClick={() => handleUsernameClick(`/${username}`)}
                variant='body1'
                color='text.secondary'
                sx={{
                  mt: 0,
                  fontSize: { xs: 8, sm: 9, md: 9, lg: 11, xl: 11 },
                  '&:hover': {
                    cursor: 'pointer',
                    textShadow: '0px 0px 0.5px black',
                  },
                }}
              >
                {photoURL && (
                  <IconButton
                    disableRipple
                    // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                    sx={{
                      p: 0,
                    }}
                  >
                    <Avatar
                      alt={name || 'User'}
                      src={photoURL || null}
                      sx={{
                        width: { xs: 22, sm: 22, md: 22, lg: 24, xl: 24 },
                        height: { xs: 22, sm: 22, md: 22, lg: 24, xl: 24 },
                        mr: 0.5,
                        ml: 0.5,
                        mb: 0,
                        //border:'2px solid blue',
                        fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                        bgcolor: photoURL ? 'crimson' : 'violet',
                        '&:hover': {
                          cursor: 'pointer',
                          boxShadow: '0px 0px 4px black',
                        },
                      }}
                    >
                      {!photoURL && (name?.[0] || email?.[0] || 'U')}
                    </Avatar>
                  </IconButton>
                )}
                {username}
              </Typography>
            </Box>
            <Tooltip title={isBookmarked ? 'Unsave Post' : 'Save Post'}>
              <IconButton disableRipple onClick={() => handleBookmarkClick()}>
                {isBookmarked ? (
                  <BookmarkIcon
                    sx={{
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 20,
                        lg: 20,
                        xl: 20,
                      },
                      mt: 0,
                      color: 'crimson',
                    }}
                  />
                ) : (
                  <BookmarkBorderIcon
                    sx={{
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 20,
                        lg: 20,
                        xl: 20,
                      },
                      mt: 0,
                      color: 'crimson',
                      '&:hover': {
                        transform: 'scale(1.11)', // Optional: adds a slight zoom effect
                        transition:
                          'transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out',
                      },
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {/* </Box> */}

        {/* <AccordionExpand postId={postID} /> */}
      </CardContent>
    </Card>
  );
};

export default DashboardCards;

//USED WHEN POSTID WAS SAVED AS currentUserId_PostId:
// const currentUserID = currentUser.uid;
// const userIDPlusPostID = postID;
// const parts = userIDPlusPostID.split('_');
// const updatedPostID = parts[1];

{
  /* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 0,
            mb: 0,
            ml: 0,
            mr: 0,
          }}
        > */
}
{
  /* <Typography
            onClick={() => handleUsernameClick(`/${username}`)}
            variant='body1'
            color='text.secondary'
            sx={{
              mt: 0,
              fontSize: { xs: 8, sm: 9, md: 9, lg: 11, xl: 11 },
              '&:hover': {
                cursor: 'pointer',
                textShadow: '0px 0px 0.5px black',
              },
            }}
          >
            {photoURL && (
              <IconButton
                disableRipple
                // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                sx={{
                  p: 0,
                }}
              >
                <Avatar
                  alt={name || 'User'}
                  src={photoURL || null}
                  sx={{
                    width: { xs: 22, sm: 22, md: 22, lg: 22, xl: 22 },
                    height: { xs: 22, sm: 22, md: 22, lg: 22, xl: 22 },
                    mr: 0.5,
                    ml: 0.5,
                    mb: 0,
                    //border:'2px solid blue',
                    fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                    bgcolor: photoURL ? 'crimson' : 'violet',
                    '&:hover': {
                      cursor: 'pointer',
                      boxShadow: '0px 0px 4px black',
                    },
                  }}
                >
                  {!photoURL && (name?.[0] || email?.[0] || 'U')}
                </Avatar>
              </IconButton>
            )}
            {username}
          </Typography> */
}

{
  /* <Box sx={{ overflowY: 'auto', mb: 0, mt: 0 }}>
          <Typography
            //width='70%'
            variant='body2'
            color='text.secondary'
            sx={{
              boxShadow: '0px 1px 2px inset grey',
              display: 'flex',
              textAlign: 'flex-start',
              // maxHeight: { xs: 60, sm: 60, md: 70, lg: 140, xl: 150 },
              minHeight: '20px',
              maxHeight: { xs: 60, sm: 60, md: 70, lg: 70, xl: 70 },
              overflowY: 'auto',
              ml: 0,
              mr: 0,
              mt: { xs: 0.5, sm: 0.5, md: 0.5, lg: 1, xl: 1 },
              mb: 0,
              p: 0.9,
              fontSize: { xs: 12, sm: 12, md: 13, lg: 12, xl: 12 },
              whiteSpace: 'pre-line', // This will preserve line breaks and spaces
              '&::-webkit-scrollbar': {
                width: '8px', // Width of the scrollbar
                backgroundColor: 'transparent', // Hide scrollbar background by default
              },
              '&:hover::-webkit-scrollbar': {
                backgroundColor: '#e0e0e0', // Show the scrollbar on hover
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Show the scrollbar on hover
                borderRadius: '10px', // Round the corners of the scrollbar thumb
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555', // Darker color when hovered over the scrollbar thumb
              },
            }}
          >
            {summary}
          </Typography>
        </Box> */
}

{
  /* <Typography
          variant='body1'
          sx={{
            mt: 0,
            fontSize: { xs: 11, sm: 11, md: 12, lg: 14, xl: 15 },
            cursor: 'pointer',
            color: 'mediumblue',
            '&:hover': {
              color: 'blue',
              cursor: 'pointer',
              textShadow: '0px 0px 0.5px blue',
            },
          }}
          onClick={() => handleCategoryClick(category)} // Handle category click
        >
      
          Category: {category}
        </Typography> */
}

{
  /* External Links & Certifications etc if they exist */
}
{
  /* {externalLinks?.length > 0 && (
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'flex-start',
              // maxHeight: { xs: 100, sm: 100, md: 180, lg: 200, xl: 200 },
              maxHeight: { xs: 60, sm: 60, md: 70, lg: 80, xl: 90 },
              overflowY: 'auto',
              color: 'blue',
              m: 0,
              ml: 0,
              mr: 0,
              mt: 0,
              fontSize: { xs: 12, sm: 12, md: 13, lg: 16, xl: 16 },
            }}
          >
            <a
              href={`${externalLinks}`}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                cursor: hover ? 'pointer' : 'default',
                textShadow: hover ? '0px 0px 0.8px black' : 'none',
                transition: 'text-shadow 0.2s ease-in-out', // Smooth transition
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {' '}
              External Links
            </a>
          </Typography>
        )} */
}

{
  /* {!deletable ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              onClick={() => handleUsernameClick(`/${username}`)}
              variant='body1'
              color='text.secondary'
              sx={{
                mt: 0,
                fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
                '&:hover': {
                  cursor: 'pointer',
                  textShadow: '0px 0px 0.5px black',
                },
              }}
            >
              Posted by:{''}
              {photoURL && (
                <IconButton
                  // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                  //onClick={() => handleClick(`/${username}`)} //sends to dynamic path made of username
                  sx={{
                    p: 0,
                    '&:hover': {
                      p: 0,
                    },
                  }}
                >
                  <Avatar
                    alt={name || 'User'}
                    src={photoURL || null}
                    sx={{
                      width: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                      height: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                      mr: 0.5,
                      ml: 0.5,
                      mb: 0.2,
                      fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                      bgcolor: photoURL ? 'crimson' : 'violet',
                      '&:hover': {
                        cursor: 'pointer',
                        boxShadow: '0px 0px 4px black',
                      },
                    }}
                  >
                    {!photoURL && (name?.[0] || email?.[0] || 'U')}
                  </Avatar>
                </IconButton>
              )}
              {username}
            </Typography>
          </Box>
        ) : (
         
        )} */
}

{
  /* Section for Email and Category */
}
// {!deletable && (
//   <Box>
//     {phoneNumber && email ? (
//       <Typography
//         variant='body1'
//         color='text.secondary'
//         sx={{
//           mt: 0,
//           fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//         }}
//       >
//         Contact: {email} ({phoneNumber})
//       </Typography>
//     ) : phoneNumber ? (
//       <Typography
//         variant='body1'
//         color='text.secondary'
//         sx={{
//           mt: 0,
//           fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//         }}
//       >
//         Phone: {phoneNumber}
//       </Typography>
//     ) : email ? (
//       <Typography
//         variant='body1'
//         color='text.secondary'
//         sx={{
//           mt: 0,
//           fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//         }}
//       >
//         Email: {email}
//       </Typography>
//     ) : null}
//   </Box>
// )}
