import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../../Firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  setDoc,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  collection,
  collectionGroup,
  where,
  query,
  writeBatch,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

//Provide context for Authentication and Login/Alert Dialogs handling
const AuthContext = createContext();

//Context to manage authentication, loading Login/Signup Alert
export const AuthProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const adminEmails = ['youradminemail@example.com']; // Replace with your admin emails



  const [loading, setLoading] = useState(true);
  const [loginOpen, setLoginOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [isBookmarkForAlert, setIsBookmarkForAlert] = useState(false);
  const [bookmarks, setBookmarks] = useState({}); // New state for bookmarks
  const [mySavedPosts, setMySavedPosts] = useState([]); // New state for saved posts
  const [userPosts, setUserPosts] = useState([]); // New state for user posts

  const [userBio, setUserBio] = useState('');
  const [userLink, setUserLink] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [username, setUsersname] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [photo, setPhoto] = useState('');
  const [bannerImage, setBannerImage] = useState('');

  const [userData, setUserData] = useState(null); // Add state to store user data

  const storage = getStorage(); // Initialize storage

  // Function to update AuthContext with new user data to be used in loginDialog
  const updateAuthContext = (userData) => {
    setCurrentUser((prev) => ({
      ...prev,
      ...userData, // Merge existing data with the new user data
      //This logic is not needed because ...userData already handles it:
      // profileImage: userData.profileImage || prev.profileImage,
      // bannerImage: userData.bannerImage || prev.bannerImage,
    }));
    setUserData(userData); // Set the complete user data in a dedicated state
    // If setUserData is simply duplicating currentUser, consider if it’s needed. If it’s used separately (e.g., 
    //for API payloads or non-reactive purposes), keep it. Otherwise, remove it.
  };

  // Function to update user profile
  const updateUserProfile = async ({
    displayName,
    usersname,
    link,
    bio,
    // profileImage,
    photo,
    bannerImage,
  }) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No authenticated user.');
      return;
    }
    try {
      const userRef = doc(db, 'users', currentUser.uid);//Prepare the Firestore Update Data
      const updateData = {
        displayName,
        username: usersname,
        link,
        bio,
      };
      // Include profileImage and photo update if provided
      // if (profileImage) {
      //   updateData.profileImage = profileImage;
      //   updateData.photo = profileImage; //update original user photoURL also
      // }
      if (photo) {
        //updateData.profileImage = profileImage;
        updateData.photo = photo; //update original user photoURL also with new Photo
      }
      if (bannerImage) {
        updateData.bannerImage = bannerImage;
      }
      // await setDoc(
      //   // Update the user's profile in the "users" collection
      //   userRef,
      //   {
      //     displayName,
      //     username: usersname,
      //     link,
      //     bio,
      //   },
      //   { merge: true } // Merge to avoid overwriting existing data
      // );

      // Update the user's profile in Firestore
      await setDoc(userRef, updateData, { merge: true });

      // Find and update all posts by the user in the "posts" collection with new userInfo update
      const postsQuery = query(
        collection(db, 'posts'),
        where('userId', '==', currentUser.uid)
      );
      const postsSnapshot = await getDocs(postsQuery);
      // console.log(
      //   'Posts to update:',
      //   postsSnapshot.docs.map((doc) => doc.id)
      // );

      // Update user's info in "savedPosts" collection
    const savedPostsQuery = query(
      collection(db, 'savedPosts'),
      where('userId', '==', currentUser.uid)
    );
    const savedPostsSnapshot = await getDocs(savedPostsQuery);

    // Update user's info in "comments" collection
    const commentsQuery = query(
      collection(db, 'comments'),
      where('userId', '==', currentUser.uid)
    );
    const commentsSnapshot = await getDocs(commentsQuery);

      if (!postsSnapshot.empty) {
        const batch = writeBatch(db);
        postsSnapshot.forEach((postDoc) => {
          const postRef = doc(db, 'posts', postDoc.id);
          const postUpdateData = { displayName, username: usersname, link, bio};
          // if (profileImage) {
          //   postUpdateData.userPhotoURL = profileImage;
          // }
          if (photo) {
            postUpdateData.userPhotoURL = photo; // Update userPhotoURL in posts
          }
          if (bannerImage) {
            postUpdateData.bannerImage = bannerImage;
          }
          // batch.update(postRef, { username: usersname });
          batch.update(postRef, postUpdateData);
          // console.log(`Queued update for post: ${postDoc.id}`);
        });

        // Update "savedPosts" collection
    // savedPostsSnapshot.forEach((savedPostDoc) => {
    //   const savedPostRef = savedPostDoc.ref;
    //   if (photo) {
    //     batch.update(savedPostRef, { photoURL: photo });
    //   }
    // });
    savedPostsSnapshot.forEach((savedPostDoc) => {
      const savedPostData = savedPostDoc.data(); // Get the data of the saved post
      const savedPostRef = savedPostDoc.ref;

       // Debugging: Log the userId field in the saved post and the current user's UID
  // console.log("Saved Post is", JSON.stringify(savedPostData, null, 2));
  // console.log("Current User UID:", currentUser.uid);
  console.log("Creators User UID:", savedPostData.creatorsUserId);
    
      // Update only if the `uniqueId` of the saved post matches the current user's ID
      if (savedPostData.creatorsUserId === currentUser.uid && photo) {
        batch.update(savedPostRef, { photoURL: photo });
      }
    });

    // Update "comments" collection
    commentsSnapshot.forEach((commentDoc) => {
      const commentRef = commentDoc.ref;
      if (photo) {
        batch.update(commentRef, { userPhotoURL: photo });
      }
    });

        await batch.commit();

        // **Update AuthContext with new values**
    const updatedUserData = {
      displayName,
      username: usersname,
      link,
      bio,
      //profileImage: profileImage || profileImage, // Ensure fallback
      photo: photo || currentUser.photoURL,
      bannerImage: bannerImage || bannerImage,   // Ensure fallback
    };
    updateAuthContext(updatedUserData);


        console.log('Batch update successful');
      } else {
        console.log('No posts found for this user.');
      }

      // // Batch write to update all relevant posts with the new username
      // const batch = writeBatch(db);
      // postsSnapshot.forEach((postDoc) => {
      //   const postRef = doc(db, 'posts', postDoc.id);
      //   batch.update(postRef, { username: usersname });
      // });
      // await batch.commit();

      // Update the user object in the context / Update AuthContext
      setCurrentUser((prev) => ({
        ...prev,
        displayName,
        username: usersname,
        link,
        bio,
        // ...(profileImage && { photoURL: profileImage }),
        ...(photo && { photoURL: photo }),
        ...(bannerImage && { bannerImage }), // Add bannerImage
      }));
    } catch (error) {
      console.error('Error updating user profile and posts:', error);
    }
  };

  // Function to update photo & profileImage or banner image
  const updateUserImage = async (type, downloadURL) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No authenticated user.');
      return;
    }

    try {
      // Update in Firestore
      const userRef = doc(db, 'users', currentUser.uid);

      // Update the user's photo if updating profileImage
      // const updateData =
      //   type === 'profileImage'
      //     ? { profileImage: downloadURL, photo: downloadURL }
      //     : { [type]: downloadURL };
      const updateData =
      type === 'photo'
        ? { photo: downloadURL } // Use only `photo` key
        : { [type]: downloadURL };

      // Update in Firestore
      await setDoc(userRef, updateData, { merge: true });
      // await setDoc(
      //   userRef,
      //   { [type]: downloadURL }, // Either profileImage or bannerImage
      //   { merge: true } // Avoid overwriting other fields
      // );

      // Update related posts if profileImage was updated
      // if (type === 'profileImage') {
      //   const postsQuery = query(
      //     collection(db, 'posts'),
      //     where('userId', '==', currentUser.uid)
      //   );
      if (type === 'photo') {
        const postsQuery = query(
          collection(db, 'posts'),
          where('userId', '==', currentUser.uid)
        );
        const postsSnapshot = await getDocs(postsQuery);

        if (!postsSnapshot.empty) {
          const batch = writeBatch(db);
          postsSnapshot.forEach((postDoc) => {
            const postRef = doc(db, 'posts', postDoc.id);
            batch.update(postRef, { userPhotoURL: downloadURL });
          });
          await batch.commit();
          console.log('Updated all posts with new profile image.');
        } else {
          console.log('No posts found for this user.');
        }
      }

      // Update in AuthContext
      setCurrentUser((prev) => ({
        ...prev,
        // [type === 'profileImage' ? 'photoURL' : 'bannerURL']: downloadURL,
        [type === 'photo' ? 'photo' : 'bannerImage']: downloadURL,
      }));

      console.log(`${type} updated successfully in Firestore and AuthContext.`);
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    }
  };

  const setUserProfile = ({
    displayName,
    username,
    userBio,
    userLink,
    //profileImage,
    photo,
    bannerImage,
  }) => {
    setDisplayName(displayName);
    setUsersname(username);
    setUserBio(userBio);
    setUserLink(userLink);
    // setProfileImage(profileImage); // Add this
    setPhoto(photo); // Add this
  setBannerImage(bannerImage);   // Add this
  };

  const openLogInModal = () => {
    setLoginOpen(true);
  };
  const handleLoginClose = () => {
    setLoginOpen(false);
    //If the drawer is open, close it
    if (openDrawer) {
      setOpenDrawer(false);
    }
  };
  //CloseAlert Dialog/modal
  const handleCloseAlert = () => {
    setOpenAlert(false);
    //Add that if the drawer is open, close it
    if (openDrawer) {
      setOpenDrawer(false);
    }
  };

  //SAVING BOOKMARKS IN STATE TO FETCH ALL USERS's BOOKMARKS
  useEffect(() => {
    if (currentUser) {
      // Fetch user's saved posts from Firebase on login
      const fetchBookmarks = async () => {
        const q = query(
          collection(db, 'savedPosts'),
          where('userId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        const bookmarksData = {};
        const savedPostsData = []; // Array to hold saved posts

        querySnapshot.forEach((doc) => {
          bookmarksData[doc.data().postId] = true;
          savedPostsData.push({ id: doc.id, ...doc.data() }); // Collect saved posts
        });

        setBookmarks(bookmarksData);
        setMySavedPosts(savedPostsData); // Set saved posts in state
      };
      // Fetch user's posts
      const fetchUserPosts = async () => {
        const q = query(
          collection(db, 'posts'),
          where('userId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);
        const userPostsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserPosts(userPostsData); // Set user posts in state
      };

      fetchBookmarks();
      fetchUserPosts();
    } else {
      setBookmarks({}); // Reset bookmarks when user logs out
      setMySavedPosts([]); // Reset saved posts when user logs out
      setUserPosts([]); // Reset user posts when user logs out
    }
  }, [currentUser]);

  const deleteUserPost = (postId) => {
    setUserPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  const toggleBookmark = async (postID, postDetails) => {

    console.log("post details are : ",JSON.stringify(postDetails,null,2))
    if (!currentUser) return;
    // const docRef = doc(db, 'savedPosts', `${currentUser.uid}_${postID}`);
    const docRef = doc(db, 'savedPosts', `${postID}`);
    const isCurrentlyBookmarked = bookmarks[postID];

    if (isCurrentlyBookmarked) {
      await deleteDoc(docRef); // selete the bookmarked post
    } else {
      await setDoc(docRef, { // save the post with userId included
        userId: currentUser.uid, // Ensure userId is included for rules validation
        postId: postID,
        //creatorsUserId: postDetails.userId, // The ID of the person who created the post
        ...postDetails,
      });
    }

    // Update local state or the UI
    setBookmarks((prev) => ({
      ...prev,
      [postID]: !isCurrentlyBookmarked,
    }));
    // Update savedPosts state accordingly
    if (isCurrentlyBookmarked) {
      setMySavedPosts((prev) => prev.filter((post) => post.id !== postID)); // Remove from saved posts
    } else {
      setMySavedPosts((prev) => [...prev, { id: postID, ...postDetails }]); // Add to saved posts
    }
  };

  // //to retrieve user data and update it with current one
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       setCurrentUser(user); // Retain the raw user object from Firebase Auth
  //       const userDocRef = doc(db, 'users', user.uid);
  //       const userDocSnap = await getDoc(userDocRef);
  //       if (userDocSnap.exists()) {
  //         const userData = userDocSnap.data();
  //         //updateAuthContext(userData); // Update Authcontext with fetched user data
  //         updateAuthContext({ ...userData, providerData: user.providerData }); // Include providerData
  //       } else {
  //         console.error('User document not found in Firestore');
  //       }
  //     } else {
  //       setCurrentUser(null);
  //       setUserData(null); // Reset user data on logout
  //     }
  //     setLoading(false); // Ensure loading state is updated
  //   });

  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userIsAdmin = adminEmails.includes(user.email);
        setIsAdmin(userIsAdmin);
      } else {
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAdmin,
        userData,
        setUserProfile,
        updateAuthContext,
        setCurrentUser,
        setDisplayName,
        setUsersname,
        setUserBio,
        setUserLink,
        updateUserProfile,
        updateUserImage,
        userBio,
        userLink,
        displayName,
        username, //pass username down
        loading,
        setLoading,
        loginOpen,
        setLoginOpen,
        openLogInModal,
        handleLoginClose,
        handleCloseAlert,
        showAlertDialog,
        setShowAlertDialog,
        openDrawer,
        setOpenDrawer,
        isBookmarkForAlert,
        setIsBookmarkForAlert,
        bookmarks, // Provide the bookmarks state
        toggleBookmark, // Provide the toggleBookmark function
        mySavedPosts, // Provide the saved posts state
        mySavedPostsLength: mySavedPosts.length, // Provide the length of saved posts
        userPosts, // Provide the user posts state
        userPostsLength: userPosts.length, // Provide the length of user posts
        deleteUserPost,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// useEffect(() => {
//   // Helper function to extract username from email
//   const extractUsername = (email) => {
//     const gmailEmail = email.includes('@gmail.com') ? email : null;
//     return gmailEmail ? gmailEmail.split('@')[0] : email.split('@')[0];
//   };
//   const unsubscribe = onAuthStateChanged(auth, (user) => {
//     if (user) {
//       const email = user.email;
//       const username = extractUsername(email);

//       setCurrentUser(user);
//       setUsersname(username); // Set username in state
//     } else {
//       setCurrentUser(null);
//     }
//     setLoading(false);
//     // setCurrentUser(user);
//     // setLoading(false);
//   });
//   return () => unsubscribe();
// }, []);

//ADDITIONAL USEEFFECT WHICH WAS LOOPING MY PROFILE DISPLAY:

// useEffect(() => {
//   const fetchUserProfile = async () => {
//     if (currentUser) {
//       const userRef = doc(db, 'users', currentUser.uid);
//       const userDoc = await getDoc(userRef);
//       if (userDoc.exists()) {
//         const userData = userDoc.data();
//         setCurrentUser((prev) => ({
//           ...prev,
//           displayName: userData.displayName,
//           username: userData.username,
//           link: userData.link,
//           bio: userData.bio,
//         }));
//       }
//     }
//   };

//   fetchUserProfile();
// }, [currentUser]);

// // Function to update user profile
// const updateUserProfile = async ({ displayName, usersname, link, bio }) => {
//   const currentUser = auth.currentUser;
//   if (!currentUser) return;
//   try {
//     const userRef = doc(db, 'users', currentUser.uid);
//     await setDoc(
//       userRef,
//       {
//         displayName,
//         username: usersname,
//         link,
//         bio,
//       },
//       { merge: true }
//     ); // Merge to avoid overwriting existing data
//     // Update the user object in the context
//     setCurrentUser((prev) => ({
//       ...prev,
//       displayName,
//       username: usersname,
//       link,
//       bio,
//     }));

//     //    // Optionally, update local state variables that represent bio, link, etc.
//     // setUserBio(bio);
//     // setUserLink(link);
//     // setDisplayName(displayName);
//     // setUsersname(usersname);
//   } catch (error) {
//     console.error('Error updating user profile:', error);
//   }
// };

//   const signOut = () => {
//     return auth.signOut().then(() => {
//       setCurrentUser(null);
//     });
//   };
