import React, { useState, useEffect } from 'react';
import { db, storage } from '../../Firebase/firebase.js';
import { collection, addDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js/index.jsx';
import IconButton from '@mui/material/IconButton';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  Backdrop,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AdForm from '../../Components/AdForm/index.jsx';
//import { useTranslation } from 'react-i18next';
//import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
//import { storage } from '../../Firebase/firebase.js';
import PaymentForm from '../../Components/PaymentForm/index.jsx';
import Container from '@mui/material/Container';
import { categories } from '../../Data/pageData.jsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClearIcon from '@mui/icons-material/Clear';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import imageCompression from 'browser-image-compression';
import { countriesList } from '../../Data/pageData.jsx';
import SearchBar from '../../Components/SearchBar/index.jsx';

// const stripePromise = loadStripe(
//   'pk_test_51Po56LJQ39B3akBrr75RaFbU2WntbcYhJzjysavoBl4kcguqAmblhxhr3f2DA2uWmEV1ywtA930VwKvWjdYL1lP800zUXiXxx5'
// );

// With this:
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const MAX_FILE_SIZE_MB = 5; // Per image limit
const MAX_TOTAL_SIZE_MB = 10; // Total upload limit for ads (smaller than posts since it's just 2 images)
const MAX_IMAGES = 1; // Change maximum number of images allowed to 1

// Add compression options
const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  initialQuality: 0.8,
};

// Add helper function for compression
const compressImage = async (file) => {
  try {
    const compressedFile = await imageCompression(file, compressionOptions);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return file;
  }
};

const PostAd = () => {
  //const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { backgroundColor } = useActiveComponent();
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [relatedLink, setRelatedLink] = useState('');
  const [images, setImages] = useState([]);
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState('');
  const [username, setUsername] = useState('');
  // const [address, setAddress] = useState({
  //   addressLine: '',
  //   city: '',
  //   state: '',
  //   zipCode: '',
  //   country: '',
  // });
  // const [city, setCity] = useState(''); // New state for city - switch back to this when geolocation is implementnted
  // const [country, setCountry] = useState(''); // New state for country - switch back to this when geolocation is implementnted
  const [city, setCity] = useState('Cuenca'); // New state for city
  const [country, setCountry] = useState('Country'); // New state for country
  const [location, setLocation] = useState({
    city: '',
    country: '',
    coords: '',
  }); // Location object

  // Ad specific states
  const [homepageAd, setHomepageAd] = useState(true);
  const [adDuration, setAdDuration] = useState('1'); // '1', '3', '6', or '12' months
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imageLimit, setImageLimit] = useState('3'); // Default to 3 images
  const [options, setOptions] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // breakpoints for where screen size is decided
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // FUNCTIONS
  //DIALOG FUNCTIONS
  const handleOpenDialog = () => {
    setPaymentVisible(true); // Ensure payment section is visible
    setOpenPayPalDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenPayPalDialog(false);
    setPaymentVisible(false); // Hide payment section when dialog closes
  };

  // Helper function to extract the dollar value from the selected durations
  const getAdCost = (selectedDuration) => {
    if (!selectedDuration) return 0;
    const parts = selectedDuration.split('-');
    return parseFloat(parts[1].substring(1)); // Extract the number after the '$'
  };

  const savePaymentDetailsToDB = async (orderDetails) => {
    const userId = currentUser.uid; // Get the current user's ID

    try {
      // Store payment details in the 'payments' collection
      const docRef = await addDoc(collection(db, 'payments'), {
        ...orderDetails,
        userId, // Associate with the user who made the payment
      });
    } catch (error) {
      console.error('Error saving payment details to db:', error);
      throw error; // Let the outer catch handle this
    }
  };

  const resetFormFields = () => {
    setTitle('');
    setRelatedLink([]);
    setCategory('');
    setName('');
    setEmail('');
    setCity(city);
    setCountry(country);
    setImages([]);
    setDate(new Date());
  };

  //To prefill parts of the form with info from user
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        // Prefill email
        setEmail(currentUser.email);
        // Fetch user data from Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        // Pre-fill form fields with user data
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Prefill name if it exists
          if (userData.name) {
            setName(userData.name);
          }
          // Set the username
          if (userData.username) {
            setUsername(userData.username);
          }
          if (userData.photo) {
            setUserPhotoURL(userData.photo);
          }
          if (userData.location) {
            setCity(userData.location.city || ''); // Pre-fill city
            setCountry(userData.location.country || ''); // Pre-fill country
          }
        }
      }
    };
    fetchUserDetails();
  }, []);

  const fontColor = backgroundColor === '#ffffff' ? '#030202' : 'lightgrey';

  const calculateTotalPrice = () => {
    let price = 0;

    // Base price for duration
    switch (adDuration) {
      case '1':
        price += 30;
        break;
      case '3':
        price += 80;
        break;
      case '6':
        price += 160;
        break;
      case '12':
        price += 330;
        break;
      default:
        price += 30; // Default to 1 month price
    }

    // Additional cost for homepage placement
    if (homepageAd) {
      price += 25;
    }

    return price;
  };

  useEffect(() => {
    const price = calculateTotalPrice();
    setTotalPrice(price);
  }, [adDuration, homepageAd]);

  // Snackbar handlers
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleImageUpload = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    // Check number of images
    if (images.length + selectedFiles.length > MAX_IMAGES) {
      setSnackbarMessage(
        `You can upload a maximum of ${MAX_IMAGES} image for ads.`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Check individual file sizes
    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
    );

    if (oversizedFiles.length > 0) {
      setSnackbarMessage(
        `Images must be smaller than ${MAX_FILE_SIZE_MB}MB each`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Calculate total size including existing images
    const existingSize = images.reduce((total, file) => total + file.size, 0);
    const newTotalSize =
      selectedFiles.reduce((total, file) => total + file.size, 0) +
      existingSize;

    if (newTotalSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
      setSnackbarMessage(
        `Total image size cannot exceed ${MAX_TOTAL_SIZE_MB}MB`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // // Check number of images
    // if (images.length + selectedFiles.length <= MAX_IMAGES) {
    // Allow only one image to be uploaded
    if (selectedFiles.length > 0) {
      try {
        setProcessing(true); // Show loading state

        // // Compress all selected images
        // const compressedImages = await Promise.all(
        //   selectedFiles.map(async (file) => {
        //     const compressed = await compressImage(file);
        //     console.log(
        //       `Original size: ${(file.size / 1024 / 1024).toFixed(2)}MB`
        //     );
        //     console.log(
        //       `Compressed size: ${(compressed.size / 1024 / 1024).toFixed(2)}MB`
        //     );
        //     return compressed;
        //   })
        // );

        // setImages([...images, ...compressedImages]);
        // Compress the selected image
        const compressedImage = await compressImage(selectedFiles[0]);
        setImages([compressedImage]); // Set the images array to contain only the compressed image
      } catch (error) {
        console.error('Error processing images:', error);
        setSnackbarMessage('Error processing images. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setProcessing(false);
      }
      // } else {
      //   setSnackbarMessage(
      //     `You can upload a maximum of ${MAX_IMAGES} images for ads.`
      //   );
      //   setSnackbarSeverity('warning');
      //   setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!title || !category) {
      setSnackbarMessage('Please fill in all required fields.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (images.length === 0) {
      setSnackbarMessage('Please upload at least one image.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // If payment is required, show payment form
    if (totalPrice > 0) {
      setShowPaymentForm(true);
      return;
    }

    // If no payment required (totalPrice === 0), proceed with ad creation
    try {
      setProcessing(true);

      // Upload compressed images to Firebase Storage
      const uploadedImageURLs = await Promise.all(
        images.map(async (image) => {
          const filename = `adImages/${Date.now()}_${Math.random()
            .toString(36)
            .substring(7)}.jpg`;
          const storageRef = ref(storage, filename);
          await uploadBytes(storageRef, image);
          return getDownloadURL(storageRef);
        })
      );

      const adData = {
        title,
        category,
        relatedLink,
        images: uploadedImageURLs, // Store the image URLs
        homepageAd,
        adDuration,
        createdAt: new Date(),
        expiresAt: new Date(
          Date.now() + parseInt(adDuration) * 30 * 24 * 60 * 60 * 1000
        ),
        userId: currentUser.uid,
        username,
        email,
        location: { city, country }, // Include city and country in the post data
        status: 'active',
        isExpired: false, // Set isExpired to false initially

      };

      await addDoc(collection(db, 'AdPosts'), adData);

      // Reset form
      resetFormFields();
      setSnackbarMessage('Ad posted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error posting ad:', error);
      setSnackbarMessage('Error posting ad. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
    // Cleanup function to revoke object URLs when component unmounts
    return () => {
      images.forEach((image) => {
        if (typeof image === 'object') {
          URL.revokeObjectURL(URL.createObjectURL(image));
        }
      });
    };
  }, [images]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      if (!showPaymentForm || totalPrice <= 0) return;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_SERVER_URL}/api/create-payment-intent`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ amount: totalPrice * 100 }),
          }
          // const response = await fetch(
          //   'http://localhost:3000/api/create-payment-intent',
          //   {
          //     method: 'POST',
          //     headers: { 'Content-Type': 'application/json' },
          //     body: JSON.stringify({ amount: totalPrice * 100 }),
          //   }
        );
        const data = await response.json();
        setOptions({
          clientSecret: data.clientSecret,
          appearance: {
            theme: 'stripe',
          },
        });
      } catch (error) {
        console.error('Error fetching client secret:', error);
        setSnackbarMessage('Error preparing payment. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchClientSecret();
  }, [showPaymentForm, totalPrice]);

  const onPaymentSuccess = async () => {
    try {
      setShowPaymentForm(false);
      setIsSubmitting(true); // Show full-screen backdrop

      // First check if user is authenticated
      if (!currentUser) {
        throw new Error('User must be authenticated');
      }

      // Upload images to Firebase Storage
      const uploadedImageURLs = await Promise.all(
        images.map(async (image) => {
          const storageRef = ref(
            storage,
            `adImages/${Date.now()}_${image.name}`
          );
          await uploadBytes(storageRef, image);
          return getDownloadURL(storageRef);
        })
      );

      const adData = {
        // Essential User Info
        userId: currentUser.uid,
        username,
        email,

        // Essential Ad Content
        title,
        category,
        relatedLink,
        images: uploadedImageURLs,

        // Ad Specific Fields
        homepageAd,
        adDuration,

        // Metadata & Status
        createdAt: new Date(),
        expiresAt: new Date(
          Date.now() + parseInt(adDuration) * 30 * 24 * 60 * 60 * 1000
        ),
        status: 'active',
        isExpired: false, // Set isExpired to false initially

        // Payment Info
        paymentAmount: totalPrice,
        paymentStatus: 'completed',
        paymentDate: new Date(),
      };

      await addDoc(collection(db, 'AdPosts'), adData);

      // Reset form and show success message
      resetFormFields();
      setSnackbarMessage('Ad posted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarMessage('Error processing ad submission');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
  <Box>
     <Box
    sx={{
      display: { xs: 'flex', sm: 'flex', lg: 'none', xl: 'none' },
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      pb: 0.5,
      height: 45,
      ml: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
      position: 'sticky',
      top: 0,
      zIndex: 1000, // Ensure it's above other content while scrolling
      mb:0
    }}
  >
    <SearchBar width={300} />
  </Box>
    
    <Box
      sx={{
        height: '100vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: {
          xs: 'auto',
          sm: 'auto',
          md: 'auto',
          lg: '1200px',
          xl: '1200px',
        }, // Add max width
        margin: '0 auto', // Center horizontally
        padding: '10px', // Add some padding
        paddingTop: '20px',
        paddingBottom: '30px',
        //border: '2px solid red',
      }}
    >
     
      {showPaymentForm ? (
        options.clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <Container maxWidth='md'>
              <PaymentForm
                onPaymentSuccess={onPaymentSuccess}
                onCancel={() => {
                  setShowPaymentForm(false);
                  setOptions({}); // Clear options instead of just clientSecret
                }}
                totalPrice={totalPrice}
              />
            </Container>
          </Elements>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )
      ) : (
        <Container maxWidth='md'>
          <Box component='form' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Images Upload */}
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography
                  variant='h6'
                  sx={{
                    mb: 2,
                    fontSize: { xs: 16, sm: 16, md: 18, lg: 18, xl: 18 },
                    fontWeight: 'bold',
                  }}
                >
                  Upload Ad Image (Maximum 1)
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='file'
                    //multiple
                    accept='image/*'
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                    id='image-upload'
                    disabled={images.length >= MAX_IMAGES}
                    required
                  />
                  <label htmlFor='image-upload'>
                    <Button
                      variant='outlined'
                      component='span'
                      disabled={images.length >= MAX_IMAGES}
                      sx={{
                        border: '1px dashed lightgrey',
                        pl: 3,
                        pr: 3,
                        pt: 1.5,
                        pb: 1.5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AddPhotoAlternateIcon
                        sx={{
                          fontSize: { xs: 21, sm: 22, md: 22, lg: 24, xl: 24 },
                          // color:
                          //   images.length >= MAX_IMAGES
                          //     ? 'rgb(200,200,200, 0.4)'
                          //     : 'rgb(0,0,139, 0.4)',
                          color:
                            images.length >= MAX_IMAGES
                              ? 'rgb(200,200,200, 0.4)'
                              : 'rgb(0,0,139, 0.4)',
                          mb: 1,
                        }}
                      />
                      Click to upload
                    </Button>
                  </label>
                </Box>

                {/* Image Preview */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    mt: 2,
                    flexWrap: 'wrap',
                  }}
                >
                  {images.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: 'relative',
                        width: 200,
                        height: 200,
                        border: '1px solid lightgrey',
                        borderRadius: 2,
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: 'inherit',
                        }}
                      />
                      <IconButton
                        size='small'
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: 'black',
                          },
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <ClearIcon fontSize='small' color='error' />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
              {/* Title Field */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder='Enter the title of your Ad'
                  //variant='standard'
                  //label='Title'
                  inputProps={{ maxLength: 100 }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  sx={{
                    mb: 0,
                    border: 'none',
                    '& .MuiInputBase-input': {
                      fontFamily: 'Arial, sans-serif',
                      fontWeight: 'bold',
                      fontSize: {
                        xs: '1rem',
                        sm: '1rem',
                        md: '1.3rem',
                        lg: '1.42rem',
                        xl: '1.42rem',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Related Link Field */}
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  label='Related Link'
                  value={relatedLink}
                  required
                  onChange={(e) => setRelatedLink(e.target.value)}
                  placeholder='https://...'
                />
              </Grid>

              {/* Category Selection */}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                    label='Category'
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    {categories.map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Parent Grid Container */}
              {/* <Grid
                      container
                      spacing={2} // Adds spacing between items
                      sx={{
                        alignItems: 'center', // Vertically center items
                        pt:3
                      }}
                    > */}
              {/* City */}
              {/* Remove for now and implement later  when Geolocation is live */}
              {/* <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                }}
              >
                <TextField
                  fullWidth
                  //placeholder='Enter the title of your post (Max.100 Characters)'
                  //variant='standard'
                  //inputProps={{ maxLength: 100 }}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  label='City'
                  //required
                  sx={{
                    mb: 0,
                    '& .MuiInputBase-input': {
                      fontFamily: 'Montserrat, sans-serif',
                      
                    },
                  }}
                />
              </Grid> */}
              {/* Country */}
              {/* Remove for now and implement later  when Geolocation is live */}
              {/* <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  pb: 0,
                  maxHeight: country ? '160px' : '50px',
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel> */}
              {/* This method allows me to display country flags */}
              {/* <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                    label='Country'
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {countriesList.find((item) => item.label === selected)
                          ?.code && (
                          <img
                            src={`https://flagcdn.com/w40/${countriesList
                              .find((item) => item.label === selected)
                              .code.toLowerCase()}.png`}
                            alt=''
                            width='20'
                            style={{ borderRadius: '3px' }}
                          />
                        )}
                        {selected}
                      </Box>
                    )}
                  >
                    {countriesList.map((cat) => (
                      <MenuItem key={cat.code} value={cat.label}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          {cat.code && (
                            <img
                              src={`https://flagcdn.com/w40/${cat.code.toLowerCase()}.png`}
                              alt=''
                              width='20'
                              style={{ borderRadius: '3px' }}
                            />
                          )}
                          {cat.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              {/* </Grid> */}

              {/* Homepage Ad Option */}
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel>Post Ad to Homepage ?</FormLabel>
                  <RadioGroup
                    row
                    value={homepageAd}
                    onChange={(e) => setHomepageAd(e.target.value === 'true')}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='No'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Yes ($25)'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Ad Duration */}
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel>Ad Duration</FormLabel>
                  <RadioGroup
                    orientation={isLgUp ? 'row' : 'column'}
                    value={adDuration}
                    onChange={(e) => setAdDuration(e.target.value)}
                  >
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label='1 Month ($30)'
                    />
                    <FormControlLabel
                      value='3'
                      control={<Radio />}
                      label='3 Months ($80)'
                    />
                    <FormControlLabel
                      value='6'
                      control={<Radio />}
                      label='6 Months ($160)'
                    />
                    <FormControlLabel
                      value='12'
                      control={<Radio />}
                      label='12 Months ($330)'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Submit Button */}
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    ml: 'auto',
                    mr: 'auto',
                    width: { lg: '30%', xl: '30%' },
                  }}
                >
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={processing}
                    sx={{
                      mt: 2,
                      backgroundColor: 'darkblue',
                      borderRadius: '20px',
                      minWidth: '200px', // Ensure button has minimum width
                    }}
                  >
                    {processing ? (
                      <CircularProgress size={24} />
                    ) : (
                      `Submit Ad $${totalPrice}.00`
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarSeverity === 'success' ? 5000 : null}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={snackbarSeverity !== 'success' ? handleSnackbarClose : null}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        open={isSubmitting}
      >
        <CircularProgress color='inherit' />
        <Typography>Processing your ad submission...</Typography>
      </Backdrop>
    </Box>
    </Box>
  );
};

export default PostAd;

//Previous select box for country:
{
  /* <Select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          // required
                          label='Country'
                        >
                          {countriesList.map((cat) => (
                            <MenuItem key={cat} value={cat.label}>
                              {cat.label}
                            </MenuItem>
                          ))}
                        </Select> */
}
