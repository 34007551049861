import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useActiveComponent } from '../ActiveComponentContext.js';
import { generatePath } from '../../utils/categoryUtils/index.js';
import AccordionExpand from '../Accordian';
import BookmarkIcon from '@mui/icons-material/Bookmark';
//import { useAuth } from '../AuthContext';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import XIcon from '@mui/icons-material/X';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Badge from '@mui/material/Badge';
import { useMediaQuery, useTheme, Box, Tooltip } from '@mui/material';
import { drawerList, categoryList } from '../../Data/pageData.jsx';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { fontFamily } from '@mui/system';

const LargePostCardDialog = ({
  images = [], //array of images
  title,
  summary,
  link,
  id,
  postID,
  postInfo,
  photoURL,
  username,
  //externalLinks,
  //certification,
  name,
  email,
  //phoneNumber,
  address,
  date,
  category,
  country,
  vertical = '550px',
  horizontal = '780px',
  navigateToCategory, // Add the navigation function prop
}) => {
  const {
    currentUser,
    setShowAlertDialog,
    setIsBookmarkForAlert,
    bookmarkCount,
    bookmarks,
    toggleBookmark,
  } = useAuth();
  const { setActivePath } = useActiveComponent();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md')); // breakpoints for where screen size is decided
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const { postCategory } = postInfo; // Extract category from postInfo

  const [currentIndex, setCurrentIndex] = useState(0); // Index for the current image
  const isBookmarked = bookmarks[postID] || false; // Check if this post is bookmarked
  const [hover, setHover] = useState(false);
  const [isBookmark, setIsBookmark] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const handleCommentsCountChange = (count) => {
    setCommentsCount(count);
  };

  //send you to respective category page when clicked
  const handleCategoryClick = (category) => {
    const path = generatePath(category);
    setActivePath(path); // Update the active path
    navigate(path);
  };

  //FUNCTIONS,
  const getCategoryIcon = (categoryName) => {
    const categoryItem = categoryList.find(
      (item) => item.name === categoryName
    );
    return categoryItem ? categoryItem.icon : null;
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  //Opens post in its own page
  const handleClick = () => {
    navigate(`/post/${postID}`, {
      state: {
        images,
        title,
        summary,
        //externalLinks,
        //certification,
        name,
        email,
        //phoneNumber,
        address,
        date,
        category,
        country,
      },
    });
  };
  //Handle navigation to username page, when it's clicked
  const handleUsernameClick = (path) => {
    navigate(path);
    setActivePath(path);
  };

  const handleShare = (platform) => {
    const postUrl = `${window.location.origin}/posts/${postID}`;
    let shareUrl = '';
    const shareText = `${title}\n\n${summary}\n\n${postUrl}`; // Including title, summary, and URL

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/share?url=${encodeURIComponent(
          postUrl
        )}&text=${encodeURIComponent(title)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          postUrl
        )}&quote=${encodeURIComponent(shareText)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=${encodeURIComponent(
          title
        )}&body=${encodeURIComponent(shareText)}`;
        break;
        break;
      case 'message':
        shareUrl = `sms:?body=${encodeURIComponent(shareText)}`;

        break;
      case 'copy':
        navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard!');
        return;
      default:
        break;
    }
    if (shareUrl) {
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleBookmarkClick = async () => {
    if (!currentUser) {
      setShowAlertDialog(true);
      setIsBookmarkForAlert(true);
      return;
    }
    // Pass required post details when adding a bookmark
    toggleBookmark(postID, {
      creatorsUserId: id || null,
      title,
      summary,
      images,
      photoURL,
      username,
      //externalLinks,
      //certification,
      name,
      email,
      //phoneNumber,
      address,
      date,
      category,
      country,
    });
  };

  // Set height conditionally based on screen size
  const height = isMdUp && isLgUp ? vertical : '200px';
  const cardWidth = isMdUp && isLgUp ? horizontal : '300px';
  // const maxWidth = 1900;
  const maxWidth = 1400;

  return (
    <Card
      sx={{
        //border:'2px solid red',
        // maxWidth: 1900,
        height: {
          xs: '100%',
          sm: '100%',
          lg: '100%',
          // md: maxWidth,
          // xl: maxWidth,
        },
        width: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '100%',
          xl: '100%',
        },
        boxSizing: 'border-box',
        ml: 0,
        mr: 0,
        backgroundColor: 'inherit',
        // boxShadow:'2px 2px 3px grey',
        p: { xs: 1.5, sm: 1.5, md: 2, lg: 2.5, xl: 2.5 },
        //overflowY: 'auto',
        borderRadius: 4,
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'column',
          xl: 'column',
        },
      }}
    >
      {/* <CardActionArea disableRipple> */}
      {images.length > 0 && (
        // <Box position='relative'>
        <CardMedia
          component='img'
          image={images[currentIndex]}
          alt='post image'
          // sx={{
          //   border:'2px solid red',
          //   objectFit: 'cover', // Ensures the image covers the area
          //   //width: cardWidth, // Ensures the image fills the width of the card
          //   width: { lg:'100%', xl: '600px' },
          //   height: {
          //     xs: '200px',
          //     sm: '200px',
          //     md: '90%',
          //     // lg: '390px',
          //     // xl: '390px',
          //     //lg: '80%',
          //     xl: '98%',
          //   }, // Matches the height set earlier
          //   //backgroundColor: 'black',
          //   //borderRadius: 4,
          //   //boxShadow: '0px 2px 3px grey',
          // }}
          sx={{
            // p:2,
            // borderRadius: 14,
            ml: 'auto',
            mr: 'auto',
            objectFit: 'contain', // Ensures the image covers the area
            width: '100%', // Fills the card width
            height: '95%', // Fills the card height
          }}
        />
      )}

      {/* Below image, all other content */}
      <CardContent
        sx={
          {
            // margin: { xs: 0, sm: 0, md: 1, lg: 0.5, xl: 0.5 },
            // height: '90%',
            // width: { xs: 'auto', sm: 'auto', md: '50%', lg: '50%', xl: '50%' },
          }
        }
      >
        {/* <Box
          gap={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            gutterBottom
            variant='h6'
            // component='div'
            sx={{
              display: 'flex',
              textAlign: 'flex-start',
              maxHeight: { xs: 40, sm: 40, md: 50, lg: 80, xl: 90 },
              overflowY: 'auto',
              fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
              fontWeight: '600',
              cursor: 'pointer',
              fontFamily: 'Montserrat',
            }}
            onClick={handleClick}
          >
            {title}
          </Typography>
        </Box> */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            //border:'2px dashed green',
            width: {
              xs: '250px',
              sm: '250px',
              md: '300px',
              lg: 'auto',
              xl: 'auto',
            },
          }}
        >
          {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          > */}
          <Typography
            variant='body1'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              //border:'1px solid red',
              mt: 1,
              mb: 1.2,
              gap: 1,
              fontSize: { xs: 10, sm: 10, md: 12, lg: 13, xl: 13 },
              // background: 'rgb(240,240,240)',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 4,
              fontFamily: 'Montserrat',
              pt: 0.3,
              pb: 0.3,
              pl: 1.5,
              pr: 1.5,
              alignItems: 'flex-end',
              '&:hover': {
                //color: 'blue',
                cursor: 'pointer',
                textShadow: '0px 0px 0.5px blue',
              },
            }}
            onClick={() => handleCategoryClick(category)} // Handle category click
          >
            {getCategoryIcon(category)}
            {category}
          </Typography>
          {/* </Box> */}
        </Box>

        {/* <Typography
          variant='h6'
          sx={{
            fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
            mt: 1,
            ml: 1,
            mb: 0.5,
            fontFamily: 'Montserrat',
          }}
        >
          Description
        </Typography> */}

        {/* <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            //boxShadow: '0px 1px 2px inset grey',
            display: 'flex',
            textAlign: 'flex-start',
            maxHeight: { xs: 80, sm: 80, md: 120, lg: 120, xl: 120 },
            overflowY: 'auto',
            borderRadius: 2,
            fontFamily: 'Montserrat',
            m: 0,
            ml: 0,
            mr: 0,
            mt: { xs: 0.5, sm: 0.5, md: 0.5, lg: 0, xl: 0 },
            mb: 1,
            p: 1,
            pb: 1,
            fontSize: { xs: 11, sm: 11, md: 13, lg: 13, xl: 13 },
            whiteSpace: 'pre-line', // This will preserve line breaks and spaces
            '&:hover': { boxShadow: '0px 1px 2px inset grey' },
            '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
              backgroundColor: 'transparent', // Hide scrollbar background by default
            },
            '&:hover::-webkit-scrollbar': {
              backgroundColor: '#e0e0e0', // Show the scrollbar on hover
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: '#888', // Show the scrollbar on hover
              borderRadius: '10px', // Round the corners of the scrollbar thumb
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555', // Darker color when hovered over the scrollbar thumb
            },
          }}
        >
          {summary}
        </Typography> */}
        {/* <Typography
          variant='body1'
          sx={{
            mt: 0,
            fontSize: { xs: 11, sm: 11, md: 12, lg: 14, xl: 15 },
            cursor: 'pointer',
            color: 'mediumblue',
            '&:hover': {
              color: 'blue',
              cursor: 'pointer',
              textShadow: '0px 0px 0.5px blue',
            },
          }}
          onClick={() => handleCategoryClick(category)} // Handle category click
        >
          
          Category: {category}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default LargePostCardDialog;

{
  /* Arrows only show when there is more than one image */
}
// {images.length > 1 && (
//   <Box>
//     <IconButton
//       disableRipple
//       sx={{
//         position: 'absolute',
//         top: '50%',
//         left: '10px',
//         transform: 'translateY(-50%)',
//         color: '#030202',
//         backgroundColor: 'white',
//         '&:hover': {
//           color: 'white',
//           backgroundColor: 'dimgrey',
//           boxShadow: '0px 2px 3px grey',
//         },
//       }}
//       onClick={handlePrevImage}
//     >
//       <ArrowBackIosIcon
//         sx={{ fontSize: { xs: 8, sm: 8, md: 12, lg: 14, xl: 14 } }}
//       />
//     </IconButton>
//     <IconButton
//       disableRipple
//       sx={{
//         position: 'absolute',
//         top: '50%',
//         right: '10px',
//         transform: 'translateY(-50%)',
//         color: '#030202',
//         backgroundColor: 'white',
//         '&:hover': {
//           color: 'white',
//           backgroundColor: 'dimgrey',
//           boxShadow: '0px 2px 3px grey',
//         },
//       }}
//       onClick={handleNextImage}
//     >
//       <ArrowForwardIosIcon
//         sx={{ fontSize: { xs: 8, sm: 8, md: 12, lg: 14, xl: 14 } }}
//       />
//     </IconButton>
//   </Box>
// )}

//PREVIOUSLY LAYERED ON TOP OF CARD MEDIA:
{
  /* <Typography
              variant='body'
              color='black'
              sx={{
                backgroundColor: 'white',
                pl: 1.2,
                pr: 1.2,
                pt: 0.5,
                pb: 0.5,
                fontSize: { xs: 12, sm: 12, md: 13, lg: 15, xl: 16 },
                borderRadius: 3,
                ml: 1.5,
                mb: 1.2,
                cursor: 'pointer',
                //mb: 0.5,
                '&:hover': {
                  color: 'white',
                  backgroundColor: 'black',
                },
              }}
              onClick={() => handleCategoryClick(category)} // Handle category click
            >
              {category}
            </Typography>
            {certification && (
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                  backgroundColor: 'white',
                  pl: 1.2,
                  pr: 1.2,
                  pt: 0.5,
                  pb: 0.5,
                  // fontSize: { xs: 11, sm: 11, md: 11, lg: 12, xl: 12 },
                  borderRadius: 6,
                  display: 'flex',
                  textAlign: 'flex-start',
                  maxHeight: { xs: 60, sm: 60, md: 70, lg: 80, xl: 90 },
                  overflowY: 'auto',
                  color: 'black',
                  m: 0,
                  ml: 'auto',
                  mb: 1.2,
                  //mr: 1,
                  //mt: 1,
                  fontSize: { xs: 12, sm: 12, md: 13, lg: 15, xl: 16 },
                }}
              >
                License:
                <VerifiedIcon
                  sx={{
                    color: 'blue',
                    width: { xs: 16, sm: 16, md: 17, lg: 19, xl: 20 },
                  }}
                />
                {''}
                {certification}
              </Typography>
            )} */
}

{
  /* Bookmark Icon */
}
{
  /* <Tooltip title={isBookmarked ? 'Unsave Post' : 'Save Post'}>
            <Badge
              badgeContent={bookmarkCount}
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  transform: 'translate(-50%, 20%)', // Adjust the percentage values as needed
                  fontSize: {
                    xs: '0.5rem',
                    sm: '0.5rem',
                    md: '0.5rem',
                    lg: '0.6rem',
                    xl: '0.6rem',
                  }, // Adjust font size
                  width: {
                    xs: '12px',
                    sm: '13px',
                    md: '15px',
                    lg: '17px',
                    xl: '17px',
                  }, // Adjust width
                  height: {
                    xs: '12px',
                    sm: '13px',
                    md: '15px',
                    lg: '17px',
                    xl: '17px',
                  }, // Adjust height
                  minWidth: {
                    xs: '10px',
                    sm: '13px',
                    md: '15px',
                    lg: '17px',
                    xl: '17px',
                  }, // Ensures the badge keeps its shape
                  color: 'white',
                  backgroundColor: 'crimson',
                  boxShadow: '1px 1px 1px grey',
                },
              }}
            >
              <IconButton disableRipple onClick={handleBookmarkClick}>
                {isBookmarked ? (
                  <BookmarkIcon
                    sx={{
                      fontSize: { xs: 25, sm: 26, md: 26, lg: 33, xl: 34 },
                      mt: 0,
                      color: 'crimson',
                    }}
                  />
                ) : (
                  <BookmarkBorderIcon
                    sx={{
                      fontSize: { xs: 25, sm: 26, md: 26, lg: 30, xl: 30 },
                      mt: 0,
                      color: 'crimson',
                      '&:hover': {
                        transform: 'scale(1.11)', // Optional: adds a slight zoom effect
                        transition:
                          'transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out',
                      },
                    }}
                  />
                )}
              </IconButton>
            </Badge>
          </Tooltip> */
}

//   <Typography
//   onClick={() => handleUsernameClick(`/${username}`)}
//   variant='body1'
//   color='text.secondary'
//   sx={{
//     ml: 1.5,
//     mt: 0,
//     fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//     '&:hover': {
//       cursor: 'pointer',
//       textShadow: '0px 0px 0.5px black',
//     },
//   }}
// >
//   {/* Posted by:{''} */}
//   {photoURL && (
//     <IconButton
//       // onClick={handleOpenUserMenu} - will send u to users page with all their posts
//       //onClick={() => handleClick(`/${username}`)} //sends to dynamic path made of username
//       sx={{
//         p: 0,
//         '&:hover': {
//           p: 0,
//         },
//       }}
//     >
//       <Avatar
//         alt={name || 'User'}
//         src={photoURL || null}
//         sx={{
//           width: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
//           height: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
//           mr: 0.5,
//           ml: 0.5,
//           mb: 0.2,
//           fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
//           bgcolor: photoURL ? 'crimson' : 'violet',
//           '&:hover': {
//             cursor: 'pointer',
//             boxShadow: '0px 0px 4px black',
//           },
//         }}
//       >
//         {!photoURL && (name?.[0] || email?.[0] || 'U')}
//       </Avatar>
//     </IconButton>
//   )}
//   {username}
// </Typography>

// {/* Section for Email and Category */}
// <Box>
//   {phoneNumber && email ? (
//     <Typography
//       variant='body1'
//       color='text.secondary'
//       sx={{
//         ml: 1.5,
//         mt: 0,
//         fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//       }}
//     >
//       Contact: {email} ({phoneNumber})
//     </Typography>
//   ) : phoneNumber ? (
//     <Typography
//       variant='body1'
//       color='text.secondary'
//       sx={{
//         ml: 1.5,
//         mt: 0,
//         fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//       }}
//     >
//       Phone: {phoneNumber}
//     </Typography>
//   ) : email ? (
//     <Typography
//       variant='body1'
//       color='text.secondary'
//       sx={{
//         ml: 1.5,
//         mt: 0,
//         fontSize: { xs: 10, sm: 10, md: 11, lg: 13, xl: 13 },
//       }}
//     >
//       Email: {email}
//     </Typography>
//   ) : null}
// </Box>

{
  /* Section for Posted By & Dates */
}
{
  /* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: 1,
          }}
        >
          <Typography
            onClick={() => handleUsernameClick(`/${username}`)}
            variant='body1'
            color='text.secondary'
            sx={{
              //ml: 1.5,
              //mt: 1,
              display: 'flex',
              alignItems: 'center',
              //border:'2px solid red',
              fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
              '&:hover': {
                cursor: 'pointer',
                textShadow: '0px 0px 0.5px black',
              },
            }}
          >
            {photoURL && (
              <IconButton
                // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                //onClick={() => handleClick(`/${username}`)} //sends to dynamic path made of username
                sx={{
                  p: 0,
                  '&:hover': {
                    p: 0,
                  },
                }}
              >
                <Avatar
                  alt={name || 'User'}
                  src={photoURL || null}
                  sx={{
                    width: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                    height: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                    mr: 0.5,
                    ml: 0.5,
                    mb: 0.2,
                    fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                    bgcolor: photoURL ? 'crimson' : 'violet',
                    '&:hover': {
                      cursor: 'pointer',
                      boxShadow: '0px 0px 4px black',
                    },
                  }}
                >
                  {!photoURL && (name?.[0] || email?.[0] || 'U')}
                </Avatar>
              </IconButton>
            )}
            {username}
          </Typography>

          <Typography
            variant='body1'
            color='text.secondary'
            sx={{
              mt: 0,
              mr: 1.5,
              fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
            }}
          >
            {date}
          </Typography>
        </Box> */
}

{
  /* Username & Date on image */
}
{
  /* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 1,
              position: 'absolute',
              bottom: '6%',
              left: '2%',
              backgroundColor: 'rgb(240,240,240,0.6)',
              borderRadius: 4,
              pr: 1,
              pt: 0.3,
              pb: 0.2,
            }}
          >
            <Typography
              onClick={() => handleUsernameClick(`/${username}`)}
              variant='body1'
              color='text.secondary'
              sx={{
                //ml: 1.5,
                //mt: 1,
                display: 'flex',
                alignItems: 'center',
                //border:'2px solid red',
                fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                '&:hover': {
                  cursor: 'pointer',
                  textShadow: '0px 0px 0.5px black',
                },
              }}
            >
              {photoURL && (
                <IconButton
                  // onClick={handleOpenUserMenu} - will send u to users page with all their posts
                  //onClick={() => handleClick(`/${username}`)} //sends to dynamic path made of username
                  sx={{
                    p: 0,
                    '&:hover': {
                      p: 0,
                    },
                  }}
                >
                  <Avatar
                    alt={name || 'User'}
                    src={photoURL || null}
                    sx={{
                      width: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                      height: { xs: 13, sm: 13, md: 15, lg: 19, xl: 19 },
                      mr: 0.5,
                      ml: 0.1,
                      mb: 0.2,
                      fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
                      bgcolor: photoURL ? 'crimson' : 'violet',
                      '&:hover': {
                        cursor: 'pointer',
                        boxShadow: '0px 0px 4px black',
                      },
                    }}
                  >
                    {!photoURL && (name?.[0] || email?.[0] || 'U')}
                  </Avatar>
                </IconButton>
              )}
              {username}
            </Typography>
          </Box> */
}
{
  /* <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgb(240,240,240,0.6)',
              borderRadius: 4,
              bottom: '6%',
              right: '3%',
            }}
          >
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{
                mt: 0,
                mr: 1,
                ml: 1,
                fontSize: { xs: 9, sm: 9, md: 10, lg: 11, xl: 11 },
              }}
            >
              {date}
            </Typography>
          </Box> */
}
