import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js/index.jsx';
import RetrievePost from '../../Components/RetrievePost/index.jsx';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import SearchBar from '../../Components/SearchBar/index.jsx';

const Shop = () => {
  const { backgroundColor } = useActiveComponent();
  const [imageUrls, setImageUrls] = useState({});
//   const [isLoading, setIsLoading] = useState(true);

  const fontColor = backgroundColor === '#ffffff' ? 'black' : 'lightgrey';

//   useEffect(() => {
//     const fetchImages = async () => {
//       try {
//         const storage = getStorage();
//         const images = {
//           aboutImage0: 'siteImages/homeBanner1.jpg',
//           aboutImage1: 'siteImages/aboutImage1.jpg',
//           aboutImage2: 'siteImages/aboutImage2.jpg',
//           aboutImage3: 'siteImages/aboutImage3.jpg',
//         };
//         const urls = {};
//         // for (const [key, path] of Object.entries(images)) {
//         //   const url = await getDownloadURL(ref(storage, path));
//         //   urls[key] = url;
//         // }
//         // Use Promise.all to fetch all image URLs concurrently
//         await Promise.all(
//           Object.entries(images).map(async ([key, path]) => {
//             const url = await getDownloadURL(ref(storage, path));
//             urls[key] = url;
//           })
//         );
//         setImageUrls(urls);
//         setIsLoading(false); // Stop loading once all images are fetched
//       } catch (error) {
//         console.error('Error fetching images:', error);
//         setIsLoading(false); // Stop loading in case of an error
//       }
//     };
//     fetchImages();
//   }, []);

//   if (isLoading) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

  return (
    <Box
      sx={{
        height: '100vh', // Full viewport height
        overflowY: 'auto', // Enable vertical scrolling of entire homepage
        mb: 0,
        backgroundColor: 'black',
        //   border:'2px dashed red'
      }}
    >
      {/* Sticky header section with Typography and Tabs */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Ensure it's above other content while scrolling
          backgroundColor: 'black', // Set background so it's visible while scrolling
          p: 2,
        }}
      >
        <Typography
          variant='h4'
          noWrap
          component='div'
          sx={{
            mt:1,
            //mt: {xs:2,sm:2,md:3,lg:5}, xl:5,
            display: { xs: 'flex', md: 'flex' },
            justifyContent: 'center',
            fontFamily: 'Montserrat',
            letterSpacing: '.3rem',
            fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
            flexDirection: 'center',
            backgroundColor: 'black',
            color: 'white',
            textDecoration: 'none',
            cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
          }}
        >
          Shop
        </Typography>
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 6, sm: 6, md: 6, lg: 20, xl: 20 },
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
          width: { xs: '80%', sm: '90%', md: '80%', lg: '100%', xl: '100%' },
          ml: 'auto',
          mr: 'auto',
          p: 0,
          mt: { xs: 8, sm: 8, md: 11, lg: 14, xl: 14 },
          mb: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            alignItems: 'center', // Center items horizontally
            justifyContent: 'center',
          }}
        >

          <Box
            component='img'
            src='/images/siteImages/redBubbleLogo.png'
            alt='Redbubble Logo'
            sx={{
              cursor: 'pointer',
              marginRight: { xs: 0, md: '10px' }, // No margin on small screens, margin on larger screens
              width: {
                xs: '50%',
                sm: '50%',
                md: '200px',
                lg: '250px',
                xl: '20%',
              }, // Adjust width based on screen size
              height: 'auto', // Maintain aspect ratio
              '&:hover': {
                transform: 'scale(1.05)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
                cursor: 'pointer',
              },
            }}
            onClick={() =>
              window.open(
                'https://www.redbubble.com/people/afro-spirit-art/shop?artistUserName=afro-spirit-art&iaCode=u-accessories',
                '_blank'
              )
            }
          />
                    <Typography
            variant='body1'
            component='div'
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 200,
              fontSize: { xs: 11, sm: 14, md: 16 },
              color: 'white',
              textAlign: 'center',
              mt:1,
              marginBottom: '5px', // Space between the text and the image
              '&:hover': {
                transform: 'scale(1.05)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
                cursor: 'pointer',
              },
            }}
          >
            Buy Merch
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            alignItems: 'center', // Center items horizontally
            justifyContent: 'center',
            //border: '2px solid red', // Optional: for visual reference
           
        }}
        >

          <Box
            component='img'
            src='/images/siteImages/EtsyLogo.png'
            alt='Etsy Logo'
            sx={{
              cursor: 'pointer',
              width: {
                xs: '50%',
                sm: '50%',
                md: '60%',
                lg: '250px',
                xl: '20%',
              }, // Adjust width based on screen size
              height: 'auto', // Maintain aspect ratio
              '&:hover': {
                transform: 'scale(1.05)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
                cursor: 'pointer',
              },
            }}
            onClick={() =>
              window.open(
                'https://www.etsy.com/shop/AfroSpiritArt?ref=shop_sugg_market',
                '_blank'
              )
            }
          />
                    <Typography
            variant='body1'
            component='div'
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 200,
              fontSize: { xs: 11, sm: 14, md: 16 },
              color: 'white',
              textAlign: 'center',
              mt:1,
              marginBottom: '10px', // Space between the text and the image
              '&:hover': {
                transform: 'scale(1.05)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
                cursor: 'pointer',
              },
            }}
          >
            Buy Prints
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Shop;

 {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row',
            },
            width: { xs: '80%', sm: '90%', md: '80%', lg: '100%', xl: '100%' },
            ml: 'auto',
            mr: 'auto',
            p: 0,
            mt: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
            mb: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
            //border:'2px solid red'
          }}
        >
          <Typography
            variant='body1'
            // noWrap
            component='div'
            sx={{
              width: { xs: 300, sm: 400, md: 480, lg: '80%', xl: '80%' },
              display: { xs: 'flex', md: 'flex' },
              justifyContent: 'center',
              // fontFamily: 'Roboto',
              fontFamily: 'Montserrat',
              fontWeight: { xs: 200, sm: 200, md: 200, lg: 200, xl: 200 },
              letterSpacing: '0rem',
              fontSize: { xs: 11, sm: 11, md: 14, lg: 16, xl: 16 },
              color: 'white',
              textDecoration: 'none',
              whiteSpace: 'pre-line',
              textAlign: 'center',
            }}
          >
            
            {`Shop Merchandise in our Redbubble Store\nor Prints in our Etsy store`}{' '}
            
          </Typography>
        </Box> */}