import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import { db, auth } from '../../Firebase/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { Box, Typography, Tab, Tabs, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LinkIcon from '@mui/icons-material/Link';
import { fontFamily } from '@mui/system';
// import { useAuth } from '../../Components/AuthContext/index.jsx';
import EditProfileDialog from '../../Components/EditProfileDialog/index.jsx';
import SearchBar from '../../Components/SearchBar/index.jsx';


const Dashboard = () => {
  const [savedPosts, setSavedPosts] = useState([]); // State for saved posts
  const {
    loading,
    setLoading,
    mySavedPosts,
    mySavedPostsLength,
    userPosts,
    userPostsLength,
    currentUser,
    setCurrentUser,
    userData,
    setUserProfile,
    displayName,
    username,
    userBio,
    userLink,
    setDisplayName,
    setUsersname,
    setUserBio,
    setUserLink,
    updateUserProfile,
    updateUserImage,
  } = useAuth();
  const [posts, setPosts] = useState(userPosts);
  const { backgroundColor } = useActiveComponent();
  const [anchorEl, setAnchorEl] = useState(null); // State for the menu anchor
  const [openEditDialog, setOpenEditDialog] = useState(false);
  // State for the second dropdown (EditIcon)
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  // const[displayName, setDisplayName] = useState(currentUser.displayName || "")
  const open = Boolean(anchorEl); // Determine if the menu is open
  const openEditMenu = Boolean(anchorElEdit);

  const gmailEmail = currentUser?.providerData?.find(
    (provider) => provider.providerId === 'google.com'
  )?.email;

  if (!gmailEmail) {
    console.error('No Gmail email found for the current user.');
  }

  console.log(
    'my currentUser DisplayName is - ' +
      JSON.stringify(currentUser.displayName, null, 2)
  );

  // Fetch user data from Firebase and set it in the context
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const user = currentUser; // const user = auth.currentUser;
        if (!user) {
          console.error('No current user found.');
          return; // Exit early if no user
        }
        const userRef = doc(db, 'users', user.uid); //user curentUser's user uid as a doc reference in 'users'
        const docSnapshot = await getDoc(userRef); //use reference to retrieve user's docs in firebase
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data(); //snapshot of user's data in firebase
          //console.log("my userData user is - "+ JSON.stringify(userData,null,2))
          // Update all states via setUserProfile
          setUserProfile({
            displayName: userData.displayName || '',
            username: userData.username || '',
            userBio: userData.bio || '',
            userLink: userData.link || '',
            profileImage: userData.profileImage || '',
            bannerImage: userData.bannerImage || '',
          });
        } else {
          console.warn('User document does not exist.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Ensure loading is stopped even if an error occurs
      }
    };

    if (currentUser) {
      fetchUserData(); // Only call when currentUser is valid
    }
  }, [currentUser]);

  const handleEditProfileClick = () => {
    setOpenEditDialog(true); // Open the dialog
    handleEditMenuClose(); // Close the menu
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false); // Close the dialog
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element to the button
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleOptionClick = (option) => {
    // Handle the option click (e.g., change profile pic or banner image)
    console.log(option); // Replace with your logic
    handleClose(); // Close the menu after an option is clicked
  };

  const handleEditMenuClick = (event) => {
    setAnchorElEdit(event.currentTarget);
  };

  const handleEditMenuClose = () => {
    setAnchorElEdit(null);
  };

  const fontColor = backgroundColor === '#ffffff' ? 'black' : 'lightgrey';

  const navigate = useNavigate();

  useEffect(() => {
    setPosts(userPosts); // Update posts whenever userPosts changes
  }, [userPosts]);

  if (!currentUser) {
    return <Typography>You need to log in to see your dashboard.</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          height: '100vh', // Full viewport height
          //overflowY: 'hidden', // Disable vertical scrolling of entire homepage
          //overflowY: 'auto', // Disable vertical scrolling of entire homepage
          //position: 'sticky',
          //border: '2px dashed blue',
        }}
      >
        {/* Sticky header section with Typography and Tabs */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000, // Ensure it's above other content while scrolling
            backgroundColor: backgroundColor, // Set background so it's visible while scrolling
            //border:'2px solid red',
            p: 0.5,
          }}
        >
          <Box
        sx={{
          display: { xs: 'flex', sm: 'flex', lg: 'none', xl: 'none' },
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          pb: 0.5,
          height: 45,
          ml: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Ensure it's above other content while scrolling
          mb:2
        }}
      >
        <SearchBar width={300} />
      </Box>
          {/* <Box position='relative'> */}
          <Box
            component='img'
            // src={currentUser.bannerURL || '../images/siteImages/dashboardDesign.png'}
            src={
              currentUser.bannerImage ||
              '../images/siteImages/dashboardDesign.png'
            }
            alt='dashboard-image'
            sx={{
              width: {
                xs: 330,
                sm: 330,
                md: 360,
                lg: 1300,
                xl: 1300,
              },
              objectFit: 'cover',
              // height: '200px',
              height: {
                xs: '80px',
                sm: '90px',
                md: '110px',
                lg: '120px',
                xl: '120px',
              },
              borderRadius: '14px',
              display: 'block',
              margin: 'auto',
              borderRadius: 4,
              mt: 0,
              mb: 1,
              backgroundRepeat: 'repeat-x',
            }}
          />

          {/* <IconButton
              sx={{
                position: 'absolute',
                bottom: 35,
                left: 186,
              }}
              onClick={handleMenuClick}
            >
              <PhotoCameraIcon
                sx={{
                  color: 'black',
                  backgroundColor: 'rgba(240, 240, 240, 0.6)',
                  borderRadius: '50%',
                  fontSize: '18px',
                  p: 0.5,
                }}
              />
            </IconButton>
            <Menu
              sx={{ borderRadius: 10, ml: 2 }}
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                sx={{ fontSize: 13, fontFamily: 'Montserrat' }}
                onClick={() => handleOptionClick('Change Profile Pic')}
              >
                Change Pic
              </MenuItem>
              <MenuItem
                sx={{ fontSize: 13, fontFamily: 'Montserrat' }}
                onClick={() => handleOptionClick('Change Banner Image')}
              >
                Change Banner
              </MenuItem>
            </Menu> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              //justifyContent:'space-around',
              //ml: { xs: 2.5, sm: 2.5, md: 4.5, lg: 10, xl: 10 },
              width: { xs: '90%' },
              mr: 'auto',
              ml: 'auto',
              //border:'2px dashed red'
            }}
          >
            <Avatar
              // src={
              //   currentUser.profileImage ||
              //   '../images/siteImages/defaultAvatar.jpg'
              // }
              src={
                currentUser.photo || currentUser.photoURL ||
                '../images/siteImages/defaultAvatar.jpg'
              }
              //src={currentUser.photoURL || '../images/siteImages/defaultAvatar.jpg'}
              // src={currentUser.photoURL}

              // src={currentUser.photoURL || '../images/siteImages/defaultAvatar.png'}
              //src={'../images/siteImages/colorful1.png'}
              alt='User Avatar'
              sx={{
                width: { xs: 80, sm: 80, md: 110, lg: 120, xl: 120 },
                height: { xs: 80, sm: 80, md: 110, lg: 120, xl: 120 },
              }}
            />
            {/* <Grid item xs={12} sm={4} md={4} lg={4} xl={4}> */}
            <Box
              sx={{
                width: { xs: 300, sm: 300, md: 800, lg: 1200, xl: 1200 },
                //borderRadius: 5,
                //boxShadow: '0px 2px 3px grey',
                p: 0,
                //position: 'fixed',
                zIndex: 500,
                mt: 0,
                //border:'2px solid blue'
              }}
            >
              {/* Profile and Edit button Title */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  pr: 0,
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    pl: 1,
                    pt: 0,
                    mb:{xs:0, sm:0, md:0,lg:1, xl:1},
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    fontSize: { xs: 11, sm: 11, md: 12, lg: 16, xl: 16 },
                  }}
                >
                  Profile
                </Typography>
                <Tooltip title='Edit Profile'>
                  <IconButton onClick={handleEditMenuClick}>
                    <EditIcon
                      sx={{
                        color: 'black',
                        fontSize: { xs: 12, sm: 12, md: 14, lg: 18, xl: 18 },
                        mb:{xs:-0.5, sm:0, md:0,lg:1, xl:1}
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ borderRadius: 10 }}
                  id='edit-menu'
                  anchorEl={anchorElEdit}
                  open={openEditMenu}
                  onClose={handleEditMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'edit-button',
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: { xs: 10, sm: 10, md: 10, lg: 13, xl: 13 },
                      fontFamily: 'Montserrat',
                    }}
                    onClick={handleEditProfileClick}
                  >
                    Edit Profile
                  </MenuItem>
                </Menu>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 0, sm: 0, md: 1, lg: 6, xl: 6 },
                }}
              >
                {/* Profile Info */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', pl: 0, alignItems:'center', }}>
                    <IconButton>
                      <AccountCircleIcon
                        sx={{
                          color: 'black',
                          fontSize: { xs: 11, sm: 11, md: 12, lg: 16, xl: 16 },
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant='body1'
                      sx={{
                        p: 1,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: 9, sm: 9, md: 11, lg: 13, xl: 13 },
                        ml: -1,
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        displayName || 'N/A'
                      )}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', pl: 0,alignItems:'center', }}>
                    <IconButton>
                      <AlternateEmailIcon
                        sx={{
                          color: 'black',
                          fontSize: { xs: 11, sm: 11, md: 12, lg: 16, xl: 16 },
                        }}
                      />
                    </IconButton>
                    <Typography
                      variant='body1'
                      sx={{
                        p: 1,
                        fontFamily: 'Montserrat',
                        fontSize: { xs: 9, sm: 9, md: 11, lg: 13, xl: 13 },
                        ml: -1,
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        username || 'N/A'
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton>
                      <LinkIcon
                        sx={{
                          color: 'black',
                          fontSize: { xs: 11, sm: 11, md: 12, lg: 16, xl: 16 },
                          transform: 'rotate(-45deg)',
                        }}
                      />
                    </IconButton>

                    {userLink ? (
                      <Typography
                        variant='body1'
                        fontFamily='Montserrat'
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontSize: {
                            xs: 9,
                            sm: 9,
                            md: 11,
                            lg: 13,
                            xl: 13,
                          },
                          // maxHeight: '165px',
                          // overflowY: 'auto',
                          // overflowX: 'hidden',
                          // gap: 0.5,
                        }}
                      >
                        <a
                          href={
                            userLink.startsWith('http://') ||
                            userLink.startsWith('https://')
                              ? userLink
                              : `https://${userLink}`
                          }
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ color: 'blue', fontStyle: 'italic' }}
                        >
                          {userLink || 'N/A'}
                        </a>
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        sx={{
                          p: 1,
                          fontFamily: 'Montserrat',
                        }}
                      >
                        Insert link
                      </Typography>
                    )}
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant='h6'
                      sx={{
                        p: 1,
                        pt: 1.2,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        fontSize: { xs: 9, sm: 9, md: 11, lg: 13, xl: 13 },
                      }}
                    >
                      Bio:
                    </Typography>
                    {/* </Box>
                <Box sx={{ display: 'flex', pl: 1 }}> */}
                    <Typography
                      variant='body1'
                      sx={{
                        p: 1,
                        pl: 0,
                        pt:1.2,
                        //border:'2px solid red',
                        fontFamily: 'Montserrat',
                        maxWidth: {
                          xs: 100,
                          sm: 100,
                          md: 200,
                          lg: 400,
                          xl: 400,
                        },
                        fontSize: { xs: 9, sm: 9, md: 11, lg: 13, xl: 13 },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1, // Limit to 2 lines
                        '&:after': {
                          content: '"..."',
                          display: 'inline',
                          color: 'gray',
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        userBio || 'N/A'
                        // "this is a long ass bio just to see what it looks like"
                      )}
                    </Typography>
                  </Box>
                  <EditProfileDialog
                    openEditDialog={openEditDialog}
                    handleDialogClose={handleDialogClose}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    // userDisplayName={currentUser.displayName}
                    userDisplayName={displayName}
                    username={username}
                    userEmail={gmailEmail || currentUser?.email}
                    // userLink={currentUser.link || ''}
                    userLink={userLink}
                    // userBio={currentUser.bio || ''}
                    userBio={userBio}
                    setDisplayName={setDisplayName}
                    setUsersname={setUsersname}
                    setUserBio={setUserBio}
                    setUserLink={setUserLink}
                    updateUserProfile={updateUserProfile}
                    updateUserImage={updateUserImage}
                  />
                </Box>
              </Box>

              {/* <Box sx={{ display: 'flex', pl: 2 }}>
                  <IconButton>
                    <ContactMailIcon sx={{ color: 'black' }} />
                  </IconButton>
                  <Typography
                    variant='body1'
                    sx={{
                      p: 1.8,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      currentUser.email || gmailEmail || 'NA'
                    )}
                  </Typography>
                </Box> */}
              {/* <Box sx={{ display: 'flex', pl: 2 }}>
                  <IconButton>
                    <LinkIcon sx={{ color: 'black' }} />
                  </IconButton>
                 
                  {userLink ? (
                    <NavLink to='/your-link'>
                      <Typography
                        variant='body1'
                        sx={{
                          p: 1.8,
                          fontFamily: 'Montserrat',
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          userLink || 'N/A'
                        )}
                      </Typography>
                    </NavLink>
                  ) : (
                    <Typography
                      variant='body1'
                      sx={{
                        p: 1.8,
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Insert your link of choice
                    </Typography>
                  )}
                </Box> */}

              {/* Account Section */}
              {/* <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    sx={{
                      pl: 3,
                      pt: 1.8,
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                    }}
                  >
                    Bio
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', pl: 1 }}>
                  <Typography
                    variant='body1'
                    sx={{
                      p: 1.8,
                      pl: 2,
                      fontFamily: 'Montserrat',
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      userBio || 'N/A'
                    )}
                  </Typography>
                </Box>
                <EditProfileDialog
                  openEditDialog={openEditDialog}
                  handleDialogClose={handleDialogClose}
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                  // userDisplayName={currentUser.displayName}
                  userDisplayName={displayName}
                  username={username}
                  userEmail={gmailEmail || currentUser?.email}
                  // userLink={currentUser.link || ''}
                  userLink={userLink}
                  // userBio={currentUser.bio || ''}
                  userBio={userBio}
                  setDisplayName={setDisplayName}
                  setUsersname={setUsersname}
                  setUserBio={setUserBio}
                  setUserLink={setUserLink}
                  updateUserProfile={updateUserProfile}
                  updateUserImage={updateUserImage}
                /> */}
            </Box>
            {/* </Grid> */}
          </Box>
        </Box>

        <Box></Box>
        {/* </Box> */}

        {/* ENtire bottom section */}
        <Box
          sx={{
            display: 'flex',
            mt: 1,
            // ml: '80px',
            // mr: '80px',
            position: 'sticky',
            // height: '60vh',
            height: 'auto',
            //border: '3px dashed red',
          }}
        >
          {/* Profile  info section */}
          {/* <Grid container spacing={0}> */}
          {/* Grid at bottom with actual posts */}
          {/* <Grid
              item
              xs={12}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              sx={{
                height: '60vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'auto',
                border:'2px solid black',
                width:'100%'
              }}
            > */}
          <Box
            sx={{
              height: '60vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              overflowY: 'auto',
              //border: '2px solid black',
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',
                xl: '100%',
              },
              ml: 'auto',
              mr: 'auto',
              pl: 'auto',
              pr: 'auto',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                backgroundColor: 'rgb(230,230,230,0.6)',
                borderRadius: 3,
                boxShadow: '0px 1px 1px inset grey',
                mb: 1.5,
                // width: '84.5%',
                width: {xs:'80%', sm:'80%', md:'60%', lg:'50%', xl:'50%'},
                ml: 'auto',
                mr: 'auto',
                pl: 0.25,
                pr: 0.25,
                zIndex: 1000,
              }}
            >
              <Tabs
                value={window.location.pathname.includes('/savedposts') ? 1 : 0}
                aria-label='Dashboard Navigation Tabs'
                sx={{
                  width: '100%', // Ensure the Tabs take up the full width of the parent Box
                  display: 'flex',
                  justifyContent: 'space-between', // Distribute space evenly
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'transparent', // Remove default indicator color
                  },
                  '& .Mui-selected': {
                    backgroundColor: 'white', // Set background for selected tab
                    boxShadow: '0px 2px 2px  grey',
                    borderRadius: 3, // Apply border radius
                  },
                  // '& .MuiTab-root': {
                  //   color: 'black', // Set text color for non-selected tabs
                  // },
                }}
              >
                <Tab
                  disableRipple
                  sx={{
                    flex: 1, // Make each tab take equal space
                    fontSize: { xs: 10, sm: 10, md: 14, lg: 14, xl: 14 }, // Responsive font size
                    m: 0.25,
                    width: '100%',
                    // pl: 15,
                    // pr: 15,
                    fontFamily: 'Montserrat',
                    whiteSpace: 'nowrap', // Prevent text from splitting into a new line
                    '&.Mui-selected': {
                      backgroundColor: 'white',

                      //color: 'black', // Change text color for selected tab
                      //borderBottom: '2px solid blue', // Underline for selected tab
                    },
                  }}
                  label={`My Posts (${userPostsLength})`}
                  component={NavLink}
                  to='/dashboard/myposts'
                />
                <Tab
                  disableRipple
                  sx={{
                    flex: 1, // Make each tab take equal space
                    fontSize: { xs: 10, sm: 10, md: 14, lg: 14, xl: 14 }, // Responsive font size
                    m: 0.25,
                    width: '100%',
                    // pl: 15,
                    // pr: 15,
                    fontFamily: 'Montserrat',
                    whiteSpace: 'nowrap', // Prevent text from splitting into a new line
                    '&.Mui-selected': {
                      backgroundColor: 'white',

                      //color: 'black', // Change text color for selected tab
                      //borderBottom: '2px solid blue', // Underline for selected tab
                    },
                  }}
                  label={`Saved (${mySavedPostsLength})`}
                  component={NavLink}
                  to='/dashboard/savedposts'
                />
              </Tabs>
            </Box>
            <Box
              sx={{
                overflowY: 'auto',
                display: 'flex',
                boxSizing: 'border-box',
                justifyContent: 'center',
                //border: '1px dashed green',
                
                // pl:'auto',
                // pr:'auto',
              }}
            >
              <Outlet context={{ posts, setPosts }} />
            </Box>

            {/* </Grid> */}
          </Box>
          {/* </Grid> */}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

//PREVIOUS useEffect with old code:

//    // Fetch user data from Firebase and set it in the context
//    useEffect(() => {
//     const fetchUserData = async () => {
//       try{
//       setLoading(true)
//       // const user = auth.currentUser;
//       const user = currentUser;
//       if (!user) {
//         console.error('No current user found.');
//         return; // Exit early if no user
//       }

//       // if (user) {
//         const userRef = doc(db, 'users', user.uid);//user curentUser's user uid as a doc reference in 'users'
//         // try {
//           const docSnapshot = await getDoc(userRef); //use reference to retrieve user's docs in firebase
//           if (docSnapshot.exists()) {
//             const userData = docSnapshot.data(); //snapshot of user's data in firebase
//             //console.log("my userData user is - "+ JSON.stringify(userData,null,2))

//           //   setDisplayName(userData.displayName || "");
//           // setUsersname(userData.username || "");
//           // setUserLink(userData.link);
//           //   setUserBio(userData.bio);
//             // Use a single state update function to avoid multiple re-renders
//              // Update all states via setUserProfile
//             setUserProfile({
//           displayName: userData.displayName || '',
//           username: userData.username || '',
//           userBio: userData.bio || '',
//           userLink: userData.link || '',
//         });
//       } else {
//         console.warn('User document does not exist.');
//       }
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     } finally {
//       setLoading(false); // Ensure loading is stopped even if an error occurs
//     }
//   };

//   if (currentUser) {
//     fetchUserData(); // Only call when currentUser is valid
//   }
// }, [currentUser]);

//   //         }
//   //       } catch (error) {
//   //         console.error('Error fetching user data:', error);
//   //       }
//   //     }
//   //     setLoading(false)
//   //   };

//   //   fetchUserData();
//   // }, [currentUser, setDisplayName, setUsersname, setUserBio, setUserLink]);

//console.log('current user Info is ' + JSON.stringify(currentUser, null, 2));
// Create a username from the text before the "@" sign in the Gmail email

//RECENTLY COMMENTE OUT
// const username = gmailEmail
//   ? gmailEmail.split('@')[0]
//   : currentUser.email.split('@')[0];

//Recently commented out
//const [usersname, setUsersname] = useState(username || '');

// const[email, setEmail] = useState(gmailEmail || currentUser.email)

// console.log("users bio is" +userBio)

//Used to fetch additional Data like link and Bio of the user
// const fetchUserData = async () => {
//   // Get the current user from auth
//   const user = auth.currentUser;

//   if (user) {
//     // Reference the user's document in Firestore
//     const userRef = doc(db, 'users', user.uid);

//     try {
//       // Fetch the document data
//       const docSnapshot = await getDoc(userRef);

//       if (docSnapshot.exists()) {
//         const userData = docSnapshot.data();
//         console.log('User bio:', userData.bio);
//         console.log('User link:', userData.link);
//         setMyBio(userData.bio);
//         setMyLink(userData.link);
//         // setUserBio(userData.bio)
//         // setUserLink(userData.link)
//       } else {
//         console.log('No such document!');
//       }
//     } catch (error) {
//       console.error('Error getting document:', error);
//     }
//   } else {
//     console.log('No user is signed in');
//   }
// };

// // Call the function where necessary, such as in a useEffect or button click
// fetchUserData();

// console.log("My Bio is " + myBio)
// console.log("My Link is " + myLink)

// const handleSubmit = async (e) => {
//   e.preventDefault();

//   try {
//     const userRef = doc(db, "users", currentUser.uid);
//     await setDoc(userRef, {
//       displayName,
//       usersname,
//       link,
//       bio,
//     }, { merge: true });

//     setOpenEditDialog(false); // Close the dialog after submission
//     await updateUserProfile(bio, link);
//   } catch (error) {
//     console.error("Error updating profile: ", error);
//   }
//    // Close the dialog after submission
//    handleDialogClose();
// };

//     const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent default form submission
//     console.log('handle submit clicked')

//     // Update user information in Firestore
//     const userRef = doc(db, 'users', currentUser.uid); // Reference to the user's document
//     await updateDoc(userRef, {
//       displayName,
//       username: usersname, // Use state value here
//       link,
//       bio,
//     });
//      // Update the currentUser state with the new values
//      const updatedUser = {
//       ...currentUser,
//       displayName,
//       username: usersname,
//       link,
//       bio,
//   };
//   setCurrentUser(updatedUser); // Update the state with the new user data
//  // console.log('Profile updated successfully!'); // Log success
//     // Close the dialog after submission
//     handleDialogClose();
//   };

// USE EFFECT FETCHING both MY POSTS & savedposts:
// useEffect(() => {
//   const fetchPostsAndSavedPosts = async () => {
//     if (currentUser && currentUser.uid) {
//       // Fetch user posts
//       const postsQuery = query(
//         collection(db, 'posts'),
//         where('userId', '==', currentUser.uid)
//       );
//       const postsSnapshot = await getDocs(postsQuery);
//       const userPosts = postsSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setPosts(userPosts);

//       // Fetch saved posts
//       const savedPostsQuery = query(
//         collection(db, 'savedPosts'),
//         where('userId', '==', currentUser.uid)
//       );

//       // Use onSnapshot to listen for real-time updates in saved posts
//       const unsubscribe = onSnapshot(savedPostsQuery, (querySnapshot) => {
//         const savedPostsData = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         console.log("saved" + savedPostsData.length)
//         setSavedPosts(savedPostsData); // Update saved posts in real-time
//       });

//       // Clean up the subscription when the component unmounts
//       return () => unsubscribe();
//     }
//   };

//   fetchPostsAndSavedPosts();
// }, [currentUser]);

{
  /* Tabs for Navigation */
}
{
  /* <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
            mb: 0,
          }}
        >
          <Tabs
            value={window.location.pathname.includes('/savedposts') ? 1 : 0}
            aria-label='Dashboard Navigation Tabs'
          >
            <Tab label='My Posts' component={NavLink} to='/dashboard/myposts' />
            <Tab
              label='Saved Posts'
              component={NavLink}
              to='/dashboard/savedposts'
            />
          </Tabs>
        </Box> */
}

{
  /* Content that scrolls /Where sticky ends*/
}
{
  /* Outlet for Nested Routes */
}
{
  /* <Outlet context={{ posts, setPosts }} /> */
}

//previous one for only my posts:
// useEffect(() => {
//     const fetchPosts = async () => {
//       if (currentUser && currentUser.uid) {
//         const q = query(
//           collection(db, 'posts'),
//           where('userId', '==', currentUser.uid)
//         );
//         const querySnapshot = await getDocs(q);
//         const userPosts = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setPosts(userPosts);
//       }
//     };

//     fetchPosts();
//   }, [currentUser]);

{
  /* Stats Section */
}
{
  /* <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant='h6'
                    sx={{
                      pl: 3,
                      pt: 1.8,
                    }}
                  >
                    Stats
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', pl: 1 }}>
                  <Typography
                    variant='body1'
                    sx={{
                      p: 1.8,
                      pl: 2,
                    }}
                  >
                    No. Posts
                  </Typography>
                  <Divider
                    sx={{
                      my: 2,
                      mx: 0,
                      width: 1.01,
                      height: 18,
                      backgroundColor: 'grey',
                    }}
                    orientation='vertical'
                  />
                  <Typography
                    variant='body1'
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      p: 1.8,
                    }}
                  >
                    No. Saves
                  </Typography>
                  <Divider
                    sx={{
                      my: 2,
                      mx: 0,
                      width: 1.01,
                      height: 18,
                      backgroundColor: 'grey',
                    }}
                    orientation='vertical'
                  />
                  <Typography
                    variant='body1'
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      p: 1.8,
                    }}
                  >
                    No. Comments
                  </Typography>
                </Box> */
}

{
  /* <Typography
              variant='h2'
              noWrap
              component='div'
              // component='a'
              // href='#home'
              sx={{
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'Raleway Dots, cursive',
                fontWeight: { xs: 1000, md: 700 },
                letterSpacing: '.3rem',
                fontSize: { xs: 18, sm: 18, md: 22, lg: 30, xl: 30 },
                flexDirection: 'center',
                color: fontColor,
                textDecoration: 'none',
                cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
              }}
            >
              My Dashboard
            </Typography> */
}
