import { useState, useEffect } from 'react';

const useDelay = (ms) => {
  const [isDelayed, setIsDelayed] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsDelayed(false), ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return isDelayed;
};

export default useDelay;
