import React,{ useEffect, useState, useContext }   from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import Footer from '../../Components/Footer/index.jsx';
import { PostsContext } from '../../contexts/PostsContext/index.jsx';
import FixedHorizontalScrollBox from '../../Components/FixedHorizontalScrollBox/index.jsx';
import { getSortedHomePageCategories } from '../../utils/categoryUtils/index.js';
import { generatePath } from '../../utils/categoryUtils/index.js';


const getRandomImage = (posts) => {
  if (!posts || posts.length === 0) return null;
  const randomIndex = Math.floor(Math.random() * posts.length);
  const images = posts[randomIndex]?.images || [];
  return images.length > 0 ? images[0] : null;
};

const HomePage = () => {
  const { postsData } = useContext(PostsContext) || {};
  const [isLoading, setIsLoading] = useState(false);
  const sortedHomePageCategories = getSortedHomePageCategories(postsData);

  //console.log('sorted Categories are:', JSON.stringify(sortedHomePageCategories, null, 2))
  
  const navigate = useNavigate();
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
    
            <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Ensure it's above other content while scrolling
          backgroundColor: 'black', // Set background so it's visible while scrolling
          // p: 1,
          // mt:{lg:2, xl:2}
          p: 2,
        }}
      >
        <Typography
          //variant='h6'
          sx={{
            color: 'white',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontSize: { xs: 16, sm: 16, md: 16, lg: 18, xl: 18 },
          }}
        >
          Collections
        </Typography>
        <Typography
          sx={{
            color: 'white',
            textAlign: 'center',
            fontWeight:100,
            mt: 1,
            mb: 1,
            fontSize: { xs: 11, sm: 11, md: 13, lg: 14, xl: 14 },
            fontStyle:'italic',
            cursor:'pointer',
            '&:hover':{
              color:'skyblue',
              transform: 'scale(1.02)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',

            }
          }}
          onClick={()=>handleNavigation('contactus')}
        >
          (Click image to view collection)
        </Typography>
      </Box>
      <Box
      sx={{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        height: '100vh', // Full viewport height
        overflowY: 'auto', // Enable vertical scrolling of entire homepage
        pt:{xs:4,sm:2,md:2,lg:4,xl:4},
        pb: 8,
        //border:'2px dashed red',
        gap:7,
      }}
    >

      {sortedHomePageCategories.map(({ artCollection, posts }, index) => {
        const imageSrc = posts[0]?.images[0]; // First image from the collection

        const randomImageSrc = getRandomImage(posts);
        // const randomImageIndex = Math.random(posts.images.length)
        // console.log(randomImageIndex)
        return (
          <Box
            key={`${artCollection}-${index}`}
            sx={{
              display:'flex',
              flexDirection:'column',
              alignItmes:'center',
              margin: 2,
              p:1,
              cursor: 'pointer',
              textAlign: 'center',
              //border:'3px solid blue',
              boxBorder:'box-sizing',
              '&:hover': {
                transform: 'scale(1.03)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              },
              
            }}
            onClick={() => navigate(`/${artCollection}`)}
          >
            {randomImageSrc && (
            <Box
            component="img"
              // src={imageSrc}
              src={randomImageSrc}
              alt={artCollection}
              sx={{
                objectFit:'cover',
                width: {xs:'300px',sm:'600px',md:'600px',lg:'600px', xl:'600px'},
                height: {xs:'500px',sm:'500px',md:'500px',lg:'500px', xl:'500px'},
                borderRadius: '12px',
                '&:hover': {
                  boxShadow: {xs:'none',sm:'none',md:'0px 0px 11px grey',lg:'0px 0px 11px grey',xl:'0px 0px 11px grey', },}
              }}
            />
          )}
            <Typography variant="h9" sx={{ marginTop: 1, color:'white', fontFamily:'Montserrat' }}>
              {artCollection}
            </Typography>
          </Box>
        );
      })}
    
    {/* {sortedHomePageCategories
    // .slice(0, itemsToShow)
    .map(({ artCollection, posts }, index) => (
      <React.Fragment key={`${artCollection}-${index}`}>
        {posts && posts.length > 0 && (
          <FixedHorizontalScrollBox
            data={posts}
            height='40vh'
            category={artCollection}
            path={generatePath(artCollection)}
            categoryLength={posts.length}
          />
        )}
      </React.Fragment>
    ))} */}
    </Box>
    </Box>
);

};

export default HomePage;

//PREVIOUS HOMEPAGE RETURN:
  // return (
  //   <Box
  //     sx={{
  //       display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       gap: 2.5,
  //       height: '100vh',
  //       backgroundColor: '#030202',
  //     }}
  //   >
  //     <Typography
  //       onClick={() => navigate('/shop')} // Navigate to /shop
  //       sx={{
  //         color: 'white',
  //         fontFamily: 'Montserrat',
  //         fontSize: { xs: 24, sm: 24, md: 26, lg: 30, xl: 30 },
  //         fontWeight: 100,
  //         '&:hover': {
  //           transform: 'scale(1.08)', // Optional: adds a slight zoom effect
  //           transition:
  //             'transform 0.8s ease-in-out, box-shadow 0.8s ease-in-out',
  //           cursor: 'pointer',
  //           color: 'pink',
  //         },
  //       }}
  //     >
  //       Shop
  //     </Typography>
  //     <Typography
  //       onClick={() => navigate('/gallery')} // Navigate to /gallery
  //       sx={{
  //         color: 'white',
  //         fontFamily: 'Montserrat',
  //         fontSize: { xs: 24, sm: 24, md: 20, lg: 30, xl: 30 },
  //         fontWeight: 100,
  //         '&:hover': {
  //           transform: 'scale(1.08)', // Optional: adds a slight zoom effect
  //           transition:
  //             'transform 0.8s ease-in-out, box-shadow 0.8s ease-in-out',
  //           cursor: 'pointer',
  //           color: 'lightyellow',
  //         },
  //       }}
  //     >
  //       Gallery
  //     </Typography>
  //     <Typography
  //       onClick={() => navigate('/worlds')} // Navigate to /worlds
  //       sx={{
  //         color: 'white',
  //         fontFamily: 'Montserrat',
  //         fontSize: { xs: 24, sm: 24, md: 20, lg: 30, xl: 30 },
  //         fontWeight: 100,
  //         '&:hover': {
  //           transform: 'scale(1.08)', // Optional: adds a slight zoom effect
  //           transition:
  //             'transform 0.8s ease-in-out, box-shadow 0.8s ease-in-out',
  //           cursor: 'pointer',
  //           color: 'MediumAquaMarine',
  //         },
  //       }}
  //     >
  //       Worlds
  //     </Typography>
  //   </Box>
  // );



      {/* Sticky header section with Typography and Tabs */}
      {/* <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Ensure it's above other content while scrolling
          backgroundColor: 'black', // Set background so it's visible while scrolling
          p: 2,
        }}
      > 
          <Typography
            variant='h4'
            noWrap
            component='div'
            sx={{
              mt: 1,
              display: { xs: 'flex', md: 'flex' },
              justifyContent:'center',
              fontFamily: 'Montserrat',
              letterSpacing: '.3rem',
              fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
              flexDirection: 'center',
              backgroundColor:'black',
              color: 'white',
              textDecoration: 'none',
              cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
            }}

          >
            Gallery
          </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          },
          width: { xs: '80%', sm: '90%', md: '80%', lg: '100%', xl: '100%' },
          ml: 'auto',
          mr: 'auto',
          p: 0,
          mt: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
          mb: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
          //border:'2px solid red'
        }}
      >
        <Typography
          variant='body1'
          // noWrap
          component='div'
          sx={{
            width: { xs: 300, sm: 400, md: 480, lg: '80%', xl: '80%' },
            display: { xs: 'flex', md: 'flex' },
            justifyContent: 'center',
            // fontFamily: 'Roboto',
            fontFamily: 'Montserrat',
            fontWeight: { xs: 200, sm: 200, md: 200, lg: 200, xl: 200 },
            letterSpacing: '0rem',
            fontSize: { xs: 11, sm: 11, md: 14, lg: 16, xl: 16 },
            color: "white",
            textDecoration: 'none',
            whiteSpace: 'pre-line',
            textAlign: 'center',
          }}
        >
            Browse through my Artworks 
          
        </Typography>
      </Box>
    </Box> */}
