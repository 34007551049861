import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import { useAuth } from '../AuthContext';
import { useAuth } from '../../contexts/AuthContext';

export default function AlertDialog({
  open,
  handleCloseAlert,
  handleLogin,
  isBookmark = false,
}) {
  const { openLogInModal } = useAuth();

  return (
    <Dialog
      open={open}
      onClose={handleCloseAlert}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {!isBookmark ? 'Want to Create a Post or Ad?' : 'Want to Save a post?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          To {!isBookmark ? 'create' : 'save'} a Post or Ad, you need an
          Account. SIGN UP or LOG IN.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseAlert(); // Close the AlertDialog
            //openLogInModal();// Open the LoginDialog
            handleLogin();
          }}
        >
          Sign Up / Log In
        </Button>
      </DialogActions>
    </Dialog>
  );
}
