import React, { createContext, useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';

// Create a context for Ads
export const AdsContext = createContext();

// Handles state and functions relating to fetching ads from Firebase
export const AdsProvider = ({ children, categories }) => {
  const [adsData, setAdsData] = useState({});
//   const [loading, setLoading] = useState(true);
  //const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const unsubscribe = [];

    // Define the fetchCategoryData function for ads
    const fetchCategoryData = (category) => {
      const q = query(
        collection(db, 'AdPosts'), // Ensure you're querying the 'Ads' collection
        where('category', '==', category)
      );
      return onSnapshot(q, (querySnapshot) => {
        const adsDataArray = [];
        querySnapshot.forEach((doc) => {
          adsDataArray.push({ ...doc.data(), id: doc.id });
        });
        setAdsData((prevData) => {
          const newData = {
            ...prevData,
            [category]: adsDataArray,
          };
          // Only update state if data has changed
          if (JSON.stringify(prevData[category]) !== JSON.stringify(adsDataArray)) {
            return newData;
          }
          return prevData;
        });
      });
    };

    // Fetch ads data for each category
    categories.forEach((category) => {
      unsubscribe.push(fetchCategoryData(category));
    });
    // Cleanup on unmount
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [categories]);

  return (
    <AdsContext.Provider
      value={{ adsData}}
    >
      {children}
    </AdsContext.Provider>
  );
};


  //     setAdsData((prevData) => ({
    //       // ...prevData,
    //       // [category]: adsDataArray,
    //     }));
    //     // Only update state if data has changed
    //     if (JSON.stringify(prevData[category]) !== JSON.stringify(adsDataArray)) {
    //       return newData;
    //     }
    //     return prevData;

    //   });
    // };