import React, { useState, useEffect } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { AddBoxSharp } from '@mui/icons-material';
import { fontFamily } from '@mui/system';

const PaymentForm = ({ onPaymentSuccess, onCancel, totalPrice }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setProcessing(true);
    // setError(null);

    if (!stripe || !elements) {
      setError('Stripe is not loaded. Please try again later.');
      // setProcessing(false);
      return;
    }
    setProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        // clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/createpost`,
        },
        redirect: 'if_required',
      });

      if (error) {
        setError(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        onPaymentSuccess();
      }
    } catch (err) {
      setError('Payment failed');
    } finally {
      setProcessing(false);
    }
  };

  // if (!clientSecret) {
  //   return <CircularProgress />;
  // }


  return (
    <Box
    sx={{
      width: '100%',
      maxWidth: '700px', // Increase from default
      margin: '0 auto',
      padding: '20px',
    }}
    >
      <Typography
        variant='h6'
        sx={{
          fontFamily: 'Montserrat',
          mb: 3,
          mt: 2,
          textAlign: 'center',
         
        }}
      >
        Complete Payment to Submit Your Post
      </Typography>

      {/* {clientSecret ? ( */}
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '12px',
          }}
        >
          <Box
            sx={{
              mb: 1,
              //width: '30%',
              width: '95%', // Update from 30%
        maxWidth: '500px', // Add max-width
              boxShadow: '0px 1px 4px grey',
              borderRadius: '12px',
              p: 2,
            }}
          >
            <Typography
              variant='h6'
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '16px',
                mb: 1,
                fontWeight: '700',
              }}
            >
              Total Price: ${totalPrice}
            </Typography>

            {/* Render Payment Element */}
            <PaymentElement />
          </Box>

          {error && (
            <Typography color='error' sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}
          >
            <Button
              onClick={onCancel}
              variant='outlined'
              sx={{ borderRadius: '20px' }}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={processing || !stripe}
              sx={{
                backgroundColor: 'darkblue',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              {processing ? (
                <>
                  <CircularProgress size={20} />
                  <span>Processing Payment...</span>
                </>
              ) : (
                `Pay $${totalPrice}`
              )}
            </Button>
          </Box>
        </form>
      {/* )  */}
      {/* : (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          Initializing payment...
        </Typography>
      )} */}
    </Box>
  );
};

export default PaymentForm;

//const [clientSecret, setClientSecret] = useState(null);

  // useEffect(() => {
  //   const fetchClientSecret = async () => {
  //     try {
  //       const response = await fetch('http://localhost:3000/api/create-payment-intent', {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({ amount: totalPrice * 100 }) // Convert to cents
  //       });
  //       const data = await response.json();
  //       setClientSecret(data.clientSecret);
  //     } catch (error) {
  //       console.error('Error fetching client secret:', error);
  //       setError('Failed to initialize payment');
  //     }
  //   };

  //   if (totalPrice > 0) {
  //     fetchClientSecret();
  //   }
  // }, [totalPrice]);
  
  // // Fetch client secret when the component mounts
  // useEffect(() => {
  //   const createPaymentIntent = async () => {
  //     try {
  //       // const response = await fetch('/api/create-payment-intent', {
  //       const response = await fetch(
  //         'http://localhost:3000/api/create-payment-intent',
  //         {
  //           method: 'POST',
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify({ amount: totalPrice * 100 }), // Convert dollars to cents
  //         }
  //       );
  //       const data = await response.json();
  //       setClientSecret(data.clientSecret);
  //       if (!data.clientSecret) {
  //         throw new Error('No clientSecret received from backend.');
  //       }
  //       setClientSecret(data.clientSecret);
  //     } catch (error) {
  //       console.error('Error initializing payment:', error.message);
  //       setError('Failed to initialize payment. Please try again later.');
  //     }
  //   };

  //   createPaymentIntent();
  // }, [totalPrice]);




// const PaymentForm = ({ onPaymentSuccess, onCancel, totalPrice }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);
//   const [processing, setProcessing] = useState(false);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setProcessing(true);
//     setError(null);

//     if (!stripe || !elements) {
//       setError('Stripe is not loaded. Please try again later.');
//       setProcessing(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     if (!cardElement || cardElement.isEmpty()) {
//       setError('Please enter your card details.');
//       setProcessing(false);
//       return;
//     }

//     try {
//       const response = await fetch('/api/create-payment-intent', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ amount: totalPrice * 100 }), // $5.00. Convert dollars to cents
//       });

//       const { clientSecret } = await response.json();

//       const result = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: elements.getElement(CardElement),
//         },
//       });

//       if (result.error) {
//         setError(result.error.message);
//         setProcessing(false);
//       } else {
//         onPaymentSuccess();
//       }
//     } catch (err) {
//       setError('Payment failed. Please try again later.');
//       setProcessing(false);
//     }
//   };

//   return (
//     <Box>
//       <Typography
//         variant='h6'
//         sx={{
//           fontFamily: 'Montserrat',
//           mb: 3,
//           mt: 2,
//           textAlign: 'center',
//         }}
//       >
//         Pay To Submit Post
//       </Typography>

//       <form
//         onSubmit={handleSubmit}
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           marginTop: '12px',
//         }}
//       >
//         <Box
//           sx={{
//             mb: 1,
//             width: '30%',
//             boxShadow: '0px 1px 4px grey',
//             borderRadius: '12px',
//             p: 2,
//           }}
//         >
//           {/* <Typography variant="h6">Pay $5 to create a post</Typography> */}
//           <Typography
//             variant='h6'
//             sx={{
//               fontFamily: 'Montserrat',
//               fontSize: '16px',
//               mb: 1,
//               fontWeight: '700',
//             }}
//           >
//             Total Price: ${totalPrice}
//           </Typography>

//           <CardElement />
//         </Box>
//         {error && (
//           <Typography color='error' sx={{ mt: 1 }}>
//             {error}
//           </Typography>
//         )}
//         <Box
//           sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}
//         >
//           <Button
//             onClick={onCancel}
//             variant='outlined'
//             sx={{ borderRadius: '20px' }}
//           >
//             Cancel
//           </Button>
//           <Button
//             type='submit'
//             variant='contained'
//             // disabled={!stripe || processing}
//             disabled={processing || !stripe}
//             sx={{ backgroundColor: 'darkblue', borderRadius: '20px' }}
//           >
//             {/* {processing ? 'Processing...' : 'Pay'} */}
//             {processing ? <CircularProgress size={20} /> : 'Pay'}
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default PaymentForm;
