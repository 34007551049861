import React, { createContext, useState, useEffect } from 'react';
import { db } from '../../Firebase/firebase';
import {
  onSnapshot,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { countriesList } from '../../Data/pageData.jsx';
import { getStorage, ref, getDownloadURL } from 'firebase/storage'; // Import Firebase functions

export const PostsContext = createContext();

//Handles state and functions relating to fetching posts from firebase
export const PostsProvider = ({ children, collections }) => {
  // const [categoriesData, setCategoriesData] = useState({});
  const [postsData, setPostsData] = useState({});
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const unsubscribe = [];
    //ddefining the fetchCategoryData
    const fetchCategoryData = (collectionName) => {
      try {
        const q = query(
          collection(db, 'galleryPosts'),
          where('artCollection', '==', collectionName)
        );
        return onSnapshot(q, (querySnapshot) => {
          const posts = [];
          querySnapshot.forEach((doc) => {
            posts.push({ ...doc.data(), id: doc.id });
          });
          setPostsData((prevData) => ({
            ...prevData,
            [collectionName]: posts,
          }));
        });
      } catch (error) {
        console.error(
          `Error fetching posts for collection "${collectionName}":`,
          error
        );
        return () => {}; // Return a no-op function if an error occurs
      }
    };

    //running the fetchCategoryData
    collections.forEach((collectionName) => {
      unsubscribe.push(fetchCategoryData(collectionName));
    });

    setLoading(false);

    // Cleanup on unmount
    return () => {
      unsubscribe.forEach((unsub) => unsub());
    };
  }, [collections]);

  return (
    <PostsContext.Provider
      value={{
        // categoriesData,
        postsData,
        loading,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};

//const [adPostsData, setAdPostsData] = useState([]); // For advertisement posts
  // const defaultCountry = countriesList.find(
  //   (country) => country.label === 'Ecuador'
  // );
  //const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const [xploras360Image, setXploras360Image] = useState(''); // State to store the logo Image URL
  // const [dashboardImage, setDashboardImage] = useState(''); // State to store the dashboard image URL
  // const [defaultAvatarImage, setDefaultAvatarImage] = useState('');

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const storage = getStorage(); // Initialize Firebase Storage
  //     const imageRef = ref(storage, 'siteImages/xploras360.png'); // Reference to the image in Firebase

  //     // try {
  //     //   const url = await getDownloadURL(imageRef); // Get the image URL
  //     //   setXploras360Image(url); // Update the state with the URL
  //     // } catch (error) {
  //     //   console.error('Error fetching xploras360 image:', error); // Handle errors
  //     // }

  //     // Fetch dashboard design image
  //     const dashboardImageRef = ref(storage, 'siteImages/dashboardDesign.png');
  //     try {
  //       const dashboardUrl = await getDownloadURL(dashboardImageRef);
  //       setDashboardImage(dashboardUrl); // Update the state with the dashboard image URL
  //     } catch (error) {
  //       console.error('Error fetching dashboard design image:', error);
  //     }
  //     // Fetch dashboard design image
  //     const defaultAvatarImageRef = ref(
  //       storage,
  //       'siteImages/defaultAvatar.jpg'
  //     );
  //     try {
  //       const defaultAvatarURL = await getDownloadURL(defaultAvatarImageRef);
  //       setDefaultAvatarImage(defaultAvatarURL); // Update the state with the dashboard image URL
  //     } catch (error) {
  //       console.error('Error fetching default Avatar image:', error);
  //     }
  //   };

  //   fetchImage();
  // }, []);


    // // Fetch advertisement posts
    // const fetchAdPosts = () => {
    //   try {
    //     const q = query(collection(db, 'AdPosts'));
    //     const unsub = onSnapshot(q, (querySnapshot) => {
    //       const adPosts = [];
    //       querySnapshot.forEach((doc) => {
    //         adPosts.push({ ...doc.data(), id: doc.id });
    //       });
    //       setAdPostsData(adPosts);
    //     });
    //     unsubscribe.push(unsub);
    //   } catch (error) {
    //     console.error('Error fetching advertisement posts:', error);
    //   }
    // };

    // fetchAdPosts();