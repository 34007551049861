import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import FontFaceObserver from 'fontfaceobserver';


const Loading = () => {

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFaceObserver('Raleway Dots');

    font.load().then(() => {
      setFontLoaded(true);
    }).catch(() => {
      setFontLoaded(true); // Fallback if font fails to load
    });
  }, []);

  if (!fontLoaded) {
    return null; // Or a fallback spinner/loading indicator
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor:'#030202'
      }}
    >
           <Box
                component='img'
                src={'./images/siteImages/afrospiritartlogo.png'} // Use a placeholder if the image URL is not available
                alt='afrospiritartLogo'
                sx={{
                  width: {
                    xs: 36,
                    sm: 36,
                    md: 40,
                    lg: 50,
                    xl: 50,
                  },
                  height: 'auto',
                  mt:{xs:1,lg:2, xl:2},
                }}
              />

      <Typography
                variant='h6'
                noWrap
                component='div'
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  fontFamily: 'Montserrat',
                  textShadow: '0px 0px 0px black',
                  letterSpacing: '.3rem',
                  fontSize: { xs: 18, sm: 19, md: 22, lg: 24, xl: 26 },
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                afrospiritart
              </Typography>
    </Box>
  );
};

export default Loading;