// Function to sort categoriesData based on the most recent post timestamp
export const getSortedCategories = (postsData) => {
  return Object.keys(postsData)
    .map((category) => {
      const posts = postsData[category]; // Get posts based on the category
      // If there are posts, sort them by timestamp and find the most recent timestamp
      if (posts && posts.length > 0) {
        // Sort category's array of posts in descending order by timestamp
        const sortedPosts = posts.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        // Get the most recent timestamp
        const mostRecentTimestamp = new Date(
          sortedPosts[0].timestamp
        ).getTime();
        // Reassign the sorted posts back to the specific category
        postsData[category] = sortedPosts;
        return { category, mostRecentTimestamp };
      }
      return { category, mostRecentTimestamp: 0 }; // Categories with no posts have timestamp 0
    })
    .filter((item) => item.mostRecentTimestamp > 0) // Filter out categories with no posts
    .sort((a, b) => b.mostRecentTimestamp - a.mostRecentTimestamp) // Sort categories by most recent post
    .map((item) => item.category); // Extract just the category names
};

// Function to sort categoriesData based on the most recent post timestamp and filter by country
export const getSortedCategoriesbyCountry = (
  categoriesData,
  selectedCountry = 'Cuenca'
) => {
  const homePageCategories = [
    'AfroFuture',
    'AstroFuture',
    
    'Pride',
    'Goddesses',
    'Beyond The Veil',
    'Ancestral Protectors',
    'Angelic Splendor',
    'Bright Black Future',
    'Canvas Works',
    'Psychedelic Reveries',
    'Photography',
    'Afro Samurai',
    'A Long Way from Home',
    // 'Adinkra Mmba',
    'Adinkra Worlds',
    'Collages',
    'Space Beings',
    'Robo-Gods',
    'Collages',
    'Spirit Bots',
    'Space Odysseyers',
    'Spirit Toys',
    'The Hybrids',
    'Metal Heads',
    'Spirit Masks',
    'Nature Bots',
    'Familial Strangers',
    'Afro Heads',
    'Tech Heads',
  ];

  // Create a mapping of category to its index in homePageCategories
  const categoryOrder = homePageCategories.reduce(
    (acc, artCollection, index) => {
      acc[artCollection] = index;
      return acc;
    },
    {}
  );

  return (
    Object.keys(categoriesData)
      .map((artCollection) => {
        // Filter posts by the selected country
        const posts = postsData[artCollection]?.filter(
          (post) => post.location?.country === selectedCountry
          //filter by City too
        );

        // If there are posts after filtering, sort them by timestamp and find the most recent timestamp
        if (posts && posts.length > 0) {
          // Sort the category's array of posts in descending order by timestamp
          const sortedPosts = posts.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          // Get the most recent timestamp
          const mostRecentTimestamp = new Date(
            sortedPosts[0].timestamp
          ).getTime();
          // Reassign the sorted posts back to the specific category
          categoriesData[category] = sortedPosts;
          return { category, mostRecentTimestamp };
        }
        return { category, mostRecentTimestamp: 0 }; // Categories with no posts have timestamp 0
      })
      .filter((item) => item.mostRecentTimestamp > 0) // Filter out categories with no posts
      //.sort((a, b) => b.mostRecentTimestamp - a.mostRecentTimestamp) // Sort categories by the most recent post
      .sort((a, b) => categoryOrder[a.category] - categoryOrder[b.category]) // Sort categories by their original order
      .map((item) => item.category)
  ); // Extract just the category names
};

// ... existing code ...

export const getSortedHomePageCategories = (postsData) => {
  const homePageCategories = [
    'AfroFuture',
    'AstroFuture',
    'Pride',
    'Goddesses',
    'Beyond The Veil',
    'Ancestral Protectors',
    'Angelic Splendor',
    'Bright Black Future',
    'Canvas Works',
    'Psychedelic Reveries',
    'Photography',
    'Afro Samurai',
    'A Long Way from Home',
    // 'Adinkra Mmba',
    'Adinkra Worlds',
    'Collages',
    'Space Beings',
    'Robo-Gods',
    'Collages',
    'Spirit Bots',
    'Space Odysseyers',
    'Spirit Toys',
    'The Hybrids',
    'Metal Heads',
    'Spirit Masks',
    'Nature Bots',
    'Familial Strangers',
    'Afro Heads',
    'Tech Heads',
  ];
  // Filter categories with non-empty arrays of posts
  return homePageCategories
    .filter(
      (artCollection) =>
        postsData[artCollection] && postsData[artCollection].length > 0
    )
    .map((artCollection) => {
      const posts = postsData[artCollection];

      // Sort posts by timestamp
      const sortedPosts = posts.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      return { artCollection, posts: sortedPosts };
    });

  // //filters the homePageCategories to include only those categories that exist in categoriesData
  // return homePageCategories
  //   .filter((artCollection) => postsData[artCollection])
  //   .map((artCollection) => {
  //     const frontpagePosts = postsData[artCollection].filter(
  //       (post) => post.isPublic === true
  //     );

  //     //sorts posts by timestamp
  //     if (frontpagePosts.length > 0) {
  //       const sortedPosts = frontpagePosts.sort(
  //         (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  //       );
  //       return { artCollection, posts: sortedPosts };
  //     }
  //     return null;
  //   })
  //   .filter(Boolean);
};

export const getFeaturedPosts = (postsData) => {
  const currentDate = new Date(); // Get the current date

  return Object.keys(postsData) // Loop through all categories in categoriesData
    .flatMap((artCollection) => {
      // Filter posts where boostPost / featurePost is true
      // const boostedPosts = categoriesData[category].filter(
      //   (post) => post.frontpage === true
      // );
      // const featuredPosts = categoriesData[category].filter(
      //   (post) => post.featurePost === true
      // );
      const featuredPosts = postsData[artCollection].filter((post) => {
        if (post.featurePost) {
          // Check if featuredUntil exists and if the current date is less than or equal to it
          if (post.featuredUntil) {
            const featuredUntilDate = new Date(post.featuredUntil);
            return currentDate <= featuredUntilDate; // Only include if not expired
          }
          return true; // If no featuredUntil date, consider it featured
        }
        return false; // If featurePost is false, exclude it
      });

      // Return the category and its boosted posts if any
      // return boostedPosts.length > 0 ? boostedPosts : [];
      return featuredPosts.length > 0 ? featuredPosts : [];
    });
};

// Function to sort posts by timestamp
export const sortPostsByTimestamp = (posts) => {
  return posts.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
};

// Function to generate standardized paths for categories
export const generatePath = (artCollection) => {
  return `/${artCollection
    .replace(/&/g, 'and')
    .replace(/-/g, '')
    .replace(/ /g, '')
    .replace(/,/g, '')
    .toLowerCase()}`;
};
