import React, { useState, useContext } from 'react';
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db,} from '../../Firebase/firebase';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import GoogleButton from 'react-google-button';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/AuthContext';
import { PostsContext } from '../../contexts/PostsContext';
//import { getStorage, ref, getDownloadURL } from 'firebase/storage'; // Import Firebase functions


const LoginDialog = ({ open, onClose }) => {
  //const { xploras360Image, dashboardImage, defaultAvatarImage} = useContext(PostsContext);
  const { updateAuthContext } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      //After successful sign in, Firebase returns a result object containing various information about the sign-in, including the signed-in user.
      const result = await signInWithPopup(auth, provider);
      const user = result.user; // Firebase User object representing the signed-in user
      const gmailEmail = user.providerData.find(
        (provider) => provider.providerId === 'google.com'
      )?.email; // Extract the actual Gmail email used to sign in
      const username = gmailEmail
        ? gmailEmail.split('@')[0]
        : user.email.split('@')[0];

      // Reference to the user's data document in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      let userData;
      if (!userDocSnap.exists()) {
        const displayName = user.displayName?.split(' ')[0] || 'User';
        // if NEW USER - the document doesn't already exist, so create it with the initial data below:
        userData = {
          name: user.displayName,
          displayName: displayName,
          username: username,
          uniqueId: user.uid,
          gmailEmail: gmailEmail,
          email: user.email, // Email address linked to Gmail account, could be different from gmail email
          photo: user.photoURL ||'../images/siteImages/defaultAvatar.jpg', // Single source of truth with fallback instead of profileImage
          bannerImage: '../images/siteImages/dashboardDesign.png', //Default banner
          bio: '',
          link: '',
          signUpMethod: 'googleSignIn',
          //dashboardPhoto: '',
          //profileImage: user.photoURL, // Default to Google photoURL
        };
        await setDoc(userDocRef, userData); //add this userData into firebase 'users' db as intial sign up data
      } else {
        userData = userDocSnap.data(); // If the document exists, retrieve the data from firebase
      }

      // Prioritize `profileImage` and `bannerImage` from Firestore if they exist
      const updatedAuthData = {
        ...userData,
        // profileImage: user.photoURL || userData.profileImage, // Fallback to Google photoURL
        // profileImage: userData.profileImage || user.photoURL, // Fallback to Google photoURL
        photo: userData.photo || user.photoURL, // Fallback to Google photoURL
        bannerImage:
          userData.bannerImage || '../images/siteImages/dashboardDesign.png', // Ensure bannerImage exists
      };
      // Update the AuthContext with the user data
      // updateAuthContext(userData); // Replace with your actual AuthContext update function
      updateAuthContext(updatedAuthData); // Replace with your actual AuthContext update function
      onClose(); // Close dialog on successful login
    } catch (error) {
      setError(`Google sign-in failed: ${error.message}`);
    }
  };

  // const handleLogin = async (email, password) => {
  //   try {
  //     const userCredential = await signInWithEmailAndPassword(auth, email, password);
  //     const user = userCredential.user;
  //     const userIsAdmin = adminEmails.includes(user.email);
  //     setIsAdmin(userIsAdmin);
  //     onClose();
  //   } catch (error) {
  //     setError(`Login failed: ${error.message}`);
  //   }
  // };
  

  // Handle Email/Password Sign-Up
  const handleEmailSignUp = async () => {
    try {
      //This function creates a new user account with the specified email and password.
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      //If the sign-up is successful, Firebase returns a userCredential object,
      //which contains information about the newly created user.like the user's uid, email, displayName, and more.
      const user = userCredential.user;
      const username = user.email.split('@')[0];
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      // Store user data in Firestore
      //doc(db, 'users', user.uid) creates a reference to a document in the users collection, with the document ID set to the user's unique ID (user.uid).
      //setDoc then creates or updates this document with the provided data, which in this case is the user's email.
      let userData; // Initialize user data
      if (!userDocSnap.exists()) {
        const displayName = username; // Default display name for email sign-ups
        userData = {
          name: displayName,
          displayName: displayName,
          username: username,
          uniqueId: user.uid,
          email: user.email, // User's email
          photo:  '../images/siteImages/defaultAvatar.jpg', // Single key for user photo
          //profileImage: '../images/siteImages/defaultAvatar.jpg', // No profile image for email sign-ups by default
          bannerImage: '../images/siteImages/dashboardDesign.png', //Default banner
          //dashboardPhoto: '', // Placeholder dashboard photo
          bio: '', // Empty bio
          link: '', // Empty link
          signUpMethod: 'emailSignUp', // Sign-up method
        };
        // Save the user data in Firestore
        await setDoc(userDocRef, userData);
      } else {  
        userData = userDocSnap.data();// If the document exists, retrieve the data
      }
      // Update the AuthContext with the new user's data
      const updatedAuthData = {
        ...userData,
        //profileImage: userData.profileImage || '', // Ensure a profileImage key exists
        photo: userData.photo, // Fallback to Google photoURL
        // bannerImage:
        //   userData.bannerImage || '../images/siteImages/dashboardDesign.png', // Ensure a bannerImage key exists
          bannerImage:
          userData.bannerImage || '../images/siteImages/dashboardDesign.png', // Ensure bannerImage exists
     
        };
      updateAuthContext(updatedAuthData); // Update AuthContext
      onClose(); // Close dialog on successful sign-up
    } catch (error) {
      setError(`Sign-up failed: ${error.message}`);
    }
  };

  // Handle Email/Password Sign-In
  const handleEmailSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await fetchUserDataAndSetContext(user);
      onClose(); // Close dialog on successful login
    } catch (error) {
      setError(`Sign-in failed: ${error.message}`);
    }
  };

  const handleSubmit = () => {
    if (isSignUp) {
      handleEmailSignUp();
    } else {
      handleEmailSignIn();
    }
  };

  const fetchUserDataAndSetContext = async (user) => {
    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const updatedAuthData = {
        ...userData,
        profileImage: userData.profileImage || '',
        bannerImage:
          userData.bannerImage || '../images/siteImages/dashboardDesign.png',
      };
      updateAuthContext(updatedAuthData);
    } else {
      throw new Error('User data not found in Firestore.');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: 'calc(100vh - 15px)',
          // overflowY: 'auto',
          height: '80vh',
          borderRadius: '18px',
          width: '550px',
          maxWidth: '650px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Box
          component='img'
          src={'./images/siteImages/xploras360.png' } // Use a placeholder if the image URL is not available
          alt='Xploras360Logo'
          sx={{
            width: {
              xs: 38,
              sm: 38,
              md: 40,
              lg: 40,
              xl: 40,
            },
            height: 'auto',
            ml: { xs: 1.2, sm: 1.2, md: 1, lg: 1, xl: 1 },
            mt: { xs: 0, sm: 0, md: 1, lg: 2, xl: 2 },
          }}
        />
        <Box sx={{ mt: 0, mb: 1, fontSize: { xs: 17, sm: 17, md: 19, lg: 20, xl: 20 }, }}>
          {isSignUp ? 'Sign Up' : 'Login'} to Xploras360
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <GoogleButton type="light" style={{borderRadius:3, text: isSignUp ? 'Sign up' : 'Login'}}/> */}
        <Box
          sx={{
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
          }}
        >
          <GoogleButton
            label={isSignUp ? 'Sign up with Google' : 'Login with Google'}
            type='light'
            style={{
              borderRadius: 29,
              marginBottom: '36px',
              paddingLeft: '16px',
              boxShadow: '0px 1px 0px grey', // Disable default box-shadow
            }} // Reset the borderRadius since the parent handles it
            onClick={handleGoogleSignIn}
          />
        </Box>
        <hr
          style={{
            border: '1px solid lightgrey',
            width: '80%',
            my: 2,
            marginTop: '15px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TextField
            sx={{
              mt: { xs: 2, sm: 2, md: 3, lg: 4, xl: 4 },
              width: { md: '60%', lg: '60%', xl: '60%' },
            }}
            margin='dense'
            label='Email Address'
            type='email'
            fullWidth
            variant='outlined'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              sx: {
                borderRadius: 3,
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            sx={{
              mt: { xs: 2, sm: 2, md: 3, lg: 3, xl: 3 },
              width: { md: '60%', lg: '60%', xl: '60%' },
              borderRadius: 4,
            }}
            margin='dense'
            label='Password'
            type='password'
            fullWidth
            variant='outlined'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              sx: {
                borderRadius: 3,
              },
            }}
          />
        </Box>
        <TextField sx={{ opacity: 0, height: '2px' }} />

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
          }}
        >
          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{
              width: { md: '160px', lg: '200px', xl: '200px' },
              borderRadius: 20,
            }}
          >
            {isSignUp ? 'Sign Up' : 'Log In'}
          </Button>
        </Box>

        <Button
          disableRipple
          variant='text'
          fullWidth
          onClick={() => setIsSignUp(!isSignUp)}
          sx={{
            mt: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
            fontSize: { xs: 12, sm: 12, md: 13, lg: 14, xl: 14 },
          }}
        >
          {isSignUp
            ? 'Already have an account? Log In'
            : "Don't have an account? Sign Up"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
