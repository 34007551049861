import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase';
// import { opencage } from 'opencage-api-client';
import * as opencage from 'opencage-api-client';

const LocationContext = createContext();

export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [userLocation, setUserLocation] = useState(null);
  const [locationConsent, setLocationConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const updateUserLocation = async (location) => {
    // if (!currentUser || (userLocation && userLocation.coords === location.coords)) return;

    // //second potential solution from chatgpt
    // if (!currentUser) return;

    // const isSameLocation =
    //   userLocation &&
    //   userLocation.city === location.city &&
    //   userLocation.country === location.country;

    // if (isSameLocation) return; // Exit if city and country are the same

    //third solution from cursor:

    if (
      !currentUser ||
      (userLocation &&
        userLocation.city === location.city &&
        userLocation.country === location.country)
    )
      return;

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        location: location,
        locationLastUpdated: new Date().toISOString(),
      });
      setUserLocation(location);
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const detectCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        console.error('Geolocation is not supported by this browser.');
        reject(new Error('Geolocation is not supported'));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            console.log('Latitude:', latitude, 'Longitude:', longitude); // Log latitude and longitude

            if (!opencage || !opencage.geocode) {
              console.error(
                'Opencage API is not properly imported or initialized.'
              );
              reject(new Error('Opencage API is not available'));
              return;
            }

            console.log('Opencage object:', opencage);
            const result = await opencage.geocode({
              q: `${latitude},${longitude}`,
              key: import.meta.env.VITE_OPENCAGE_API_KEY,
              //key: '5a0b6e16edf14a109d710370814e84f6'
            });
            console.log('Geocoding result:', result); // Log the result of the geocoding API call

            if (result.results.length > 0) {
              const location = {
                city:
                  result.results[0].components.city ||
                  result.results[0].components.town ||
                  'Unknown',
                country: result.results[0].components.country || 'Unknown',
                coords: { latitude, longitude },
              };
              resolve(location);
            } else {
              console.warn('No geocoding results found.');
              reject(new Error('Location not found'));
            }
          } catch (error) {
            reject(error);
          }
        },
        // (error) => reject(error)
        (error) => {
          console.error('Geolocation error:', error); // Log the error
          reject(error);
        }
      );
    });
  };

//   const checkLocationChange = async (currentLocation, storedLocation) => {
//     if (!storedLocation) return true;
//     // console.log("current location city is" + currentLocation.city.toLowerCase())
//     // console.log("stored location city is" + storedLocation.city.toLowerCase())

//     // console.log("current location Country is" + currentLocation.country.toLowerCase())
//     // console.log("stored location Country is" + storedLocation.country.toLowerCase())
//     // return (
//     // //   currentLocation.city !== storedLocation.city ||
//     // //   currentLocation.country !== storedLocation.country

//     // currentLocation.city?.toLowerCase() !== storedLocation.city?.toLowerCase() ||
//     // currentLocation.country?.toLowerCase() !== storedLocation.country?.toLowerCase()

//     // );
//     console.log('Comparing locations:');
//     console.log('Current Location:', currentLocation);
//     console.log('Stored Location:', storedLocation);

//     const cityChanged =
//       currentLocation.city?.toLowerCase() !==
//       storedLocation.city?.toLowerCase();
//     const countryChanged =
//       currentLocation.country?.toLowerCase() !==
//       storedLocation.country?.toLowerCase();

//     console.log('City Changed:', cityChanged);
//     console.log('Country Changed:', countryChanged);

//     return cityChanged || countryChanged;
//   };

const checkLocationChange = async (currentLocation, storedLocation) => {
    if (!storedLocation) {
      console.log('No stored location. Treating as a location change.');
      return true; // If no stored location, treat as changed
    }
  
    console.log('Comparing locations:');
    console.log('Current Location:', JSON.stringify(currentLocation, null, 2));
    console.log('Stored Location:', JSON.stringify(storedLocation, null, 2));
  
    // Ensure properties are defined before comparison
    const currentCity = currentLocation.city?.toLowerCase() || '';
    const storedCity = storedLocation.city?.toLowerCase() || '';
    const currentCountry = currentLocation.country?.toLowerCase() || '';
    const storedCountry = storedLocation.country?.toLowerCase() || '';
  
    const cityChanged = currentCity !== storedCity;
    const countryChanged = currentCountry !== storedCountry;
  
    console.log('City Changed:', cityChanged);
    console.log('Country Changed:', countryChanged);
  
    return cityChanged || countryChanged;
  };
  
  const value = {
    userLocation,
    setUserLocation,
    updateUserLocation,
    detectCurrentLocation,
    checkLocationChange,
    locationConsent,
    setLocationConsent,
    isLoading,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};
