import Typography from '@mui/material/Typography';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js/index.jsx';
import { db } from '../../Firebase/firebase.js';
import { collection, addDoc, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { categories, artCollections } from '../../Data/pageData.jsx';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import Container from '@mui/material/Container';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentForm from '../../Components/PaymentForm/index.jsx';
import { fontSize, fontStyle } from '@mui/system';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
//import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { categoryAndTags } from '../../Data/pageData.jsx';
import SearchBar from '../../Components/SearchBar/index.jsx';

// const stripePromise = loadStripe(
//   'pk_test_51Po56LJQ39B3akBrr75RaFbU2WntbcYhJzjysavoBl4kcguqAmblhxhr3f2DA2uWmEV1ywtA930VwKvWjdYL1lP800zUXiXxx5'
// );

// With this:
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const compressionOptions = {
  maxSizeMB: 1, // Maximum file size in MB
  maxWidthOrHeight: 1920, // Max width/height of the output image
  useWebWorker: true, // Use web workers for better performance
  initialQuality: 0.8, // Initial quality of compression (0 to 1)
};

const compressImage = async (file) => {
  try {
    const compressedFile = await imageCompression(file, compressionOptions);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return file; // Return original file if compression fails
  }
};

const uploadImages = async (images) => {
  const storage = getStorage();
  return Promise.all(
    images.map(async (image) => {
      // Generate a unique filename
      const filename = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.jpg`;
      const storageRef = ref(storage, `images/${filename}`);

      // Upload compressed image
      await uploadBytes(storageRef, image);
      return getDownloadURL(storageRef);
    })
  );
};

const CreatePost = () => {
  // const { t } = useTranslation(); // Multi-language support
  const { currentUser } = useAuth();
  const { backgroundColor } = useActiveComponent();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [artCollection, setArtCollection] = useState('');
  const [inEtsy, setInEtsy] = useState(false);
  const [inRedBubble, setInRedbubble] = useState(false);
  const [nftAvailable, setNftAvailable] = useState(false);
  const [images, setImages] = useState([]);
  const [date, setDate] = useState(new Date());
  const [city, setCity] = useState('Cuenca'); // New state for city - reinstate when Geolocation is live
  const [country, setCountry] = useState('Ecuador');
  const [location, setLocation] = useState({
    city: '',
    country: '',
    coords: '',
  }); // Location object

  //const [frontPage, setFrontPage] = useState(false);
  const [addToNewsletter, setAddToNewsletter] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userPhotoURL, setUserPhotoURL] = useState('');
  const [postIsPublic, setPostIsPublic] = useState(true); // default is 'public' (true)
  //const [isExpired, setIsExpired] = useState(false);
  const [username, setUsername] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [featurePost, setFeaturePost] = useState(false); // Default to 'No'

  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [imageLimit, setImageLimit] = useState('1');
  //const imageLimit = 3;
  const [uploadProgress, setUploadProgress] = useState(0); // Progress indicator

  // Add this at the top of your component
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'success', 'error', 'warning', 'info'
  const [clientSecret, setClientSecret] = useState(null);

  // Initialize Firestore
// const db = getFirestore();

  // Snackbar handlers
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const calculateTotalPrice = () => {
    const exemptUserIDs = [
      '4UFD7MrvMZgBTdpRUVGTporbRoD2',
      'IIPdQcAXahaJpp4JthyOJtvEXff1',
    ];
    const isExempt = currentUser && exemptUserIDs.includes(currentUser.uid);
    if (isExempt) return 0; // Exempt users always have a totalPrice of 0.
    let price = 0;
    if (imageLimit === '9') price += 3;
    if (imageLimit === '12') price += 5;
    if (imageLimit === '15') price += 6;
    if (featurePost) price += 5;

    return price;
  };

  const handlePaymentSuccess = () => {
    setPaymentCompleted(true);
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch(
          'http://localhost:3000/api/create-payment-intent',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ amount: totalPrice * 100 }), // Use totalPrice instead of hardcoded value
          }
        );
        const data = await response.json();
        console.log(data.clientSecret);
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };
    if (showPaymentForm && totalPrice > 0) fetchClientSecret();
  }, [showPaymentForm, totalPrice]);

  useEffect(() => {
    const price = calculateTotalPrice();
    setTotalPrice(price);
  }, [imageLimit, featurePost, currentUser]); // Recalculate when relevant inputs change

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser) {
        // Prefill email
        setEmail(currentUser.email);

        // Fetch user data from Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Prefill name if it exists
          if (userData.name) {
            setName(userData.name);
          }
          // Set the username
          if (userData.username) {
            setUsername(userData.username);
          }
          if (userData.photo) {
            setUserPhotoURL(userData.photo);
          }
          if (userData.location) {
            setCity(userData.location.city || ''); // Pre-fill city
            setCountry(userData.location.country || ''); // Pre-fill country
          }
        }
      }
    };
    fetchUserDetails();
  }, []);

  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the selected tag
      setSelectedTags((prevTags) => [...prevTags, value]);
    } else {
      // Remove the unselected tag
      setSelectedTags((prevTags) => prevTags.filter((tag) => tag !== value));
    }
  };

  // Add these constants at the top of your file
  const MAX_FILE_SIZE_MB = 5; // Per image limit
  const MAX_TOTAL_SIZE_MB = 50; // Total upload limit
  const RECOMMENDED_SIZE_MB = 2; // Recommended size after compression

  // Add a helper function to show users optimization tips
  const showSizeWarning = (fileSize) => {
    if (fileSize > RECOMMENDED_SIZE_MB * 1024 * 1024) {
      setSnackbarMessage(
        `Tip: Consider optimizing your image. Images around ${RECOMMENDED_SIZE_MB}MB or smaller work best.`
      );
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    }
  };

  // Update the handleImageChange function
  const handleImageChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const maxImages = parseInt(imageLimit);

    // Check individual file sizes
    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE_MB * 1024 * 1024
    );

    if (oversizedFiles.length > 0) {
      setSnackbarMessage(
        `Images must be smaller than ${MAX_FILE_SIZE_MB}MB each`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Calculate total size including existing images
    const existingSize = images.reduce((total, file) => total + file.size, 0);
    const newTotalSize =
      selectedFiles.reduce((total, file) => total + file.size, 0) +
      existingSize;

    if (newTotalSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
      setSnackbarMessage(
        `Total image size cannot exceed ${MAX_TOTAL_SIZE_MB}MB`
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Check number of images
    if (images.length + selectedFiles.length <= maxImages) {
      try {
        setIsPosting(true);

        // Compress all selected images
        const compressedImages = await Promise.all(
          selectedFiles.map(async (file) => {
            const compressed = await compressImage(file);

            // Log compression results
            console.log(
              `Original size: ${(file.size / 1024 / 1024).toFixed(2)}MB`
            );
            console.log(
              `Compressed size: ${(compressed.size / 1024 / 1024).toFixed(2)}MB`
            );

            return compressed;
          })
        );

        setImages([...images, ...compressedImages]);
      } catch (error) {
        console.error('Error processing images:', error);
        setSnackbarMessage('Error processing images. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setIsPosting(false);
      }
    } else {
      setSnackbarMessage(`You can upload a maximum of ${maxImages} images.`);
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  // const handleAddressChange = (event) => {
  //   const { name, value } = event.target;
  //   setAddress((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!currentUser) {
    //   setSnackbarMessage('You must be signed in to create a post.');
    //   setSnackbarSeverity('error');
    //   setSnackbarOpen(true);
    //   return;
    // }
    if (images.length === 0) {
      setSnackbarMessage('Please upload at least one image.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (title.trim().length === 0 || title.length > 100) {
      setSnackbarMessage('Invalid title');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // If payment is required, show the payment form
    if (totalPrice > 0) {
      setShowPaymentForm(true);
      return;
    }
    // Proceed with post creation directly if no payment is required
    try {
      setIsPosting(true); // Start loading
      // const storage = getStorage();
      // const uploadedImageURLs = await Promise.all(
      //   images.map(async (image) => {
      //     const storageRef = ref(storage, `images/${image.name}`);
      //     await uploadBytes(storageRef, image);
      //     const downloadURL = await getDownloadURL(storageRef);
      //     return downloadURL;
      //   })
      // );
      const uploadedImageURLs = await uploadImages(images);

      const postDate = new Date();
      const newPost = {
        title,
        description,
        artCollection,
        timestamp: postDate.toISOString(),
        images: uploadedImageURLs,
        readableDate: postDate.toLocaleDateString(),
        readableTime: postDate.toLocaleTimeString(),
        inEtsy, // New field
        inRedBubble, // New field
        nftAvailable, // New field
        // userId: currentUser.uid,
        // userPhotoURL,
        // username,
        // name,
        // email,
        // location: { city, country }, // Include city and country in the post data
        //address,
        // featurePost,
        // isPublic: postIsPublic,
        // isExpired: false,
        // bookmarkCount: 0,
        // tags: selectedTags, 
      };

      // Set the featuredUntil date if featurePost is true
      // if (featurePost) {
      //   const expirationDate = new Date();
      //   expirationDate.setDate(expirationDate.getDate() + 14); // Add 14 days
      //   newPost.featuredUntil = expirationDate.toISOString(); // Store the expiration date
      // }

      const docRef = await addDoc(collection(db, 'galleryPosts'), newPost);

       // Update the newPost object with the generated ID
    newPost.id = docRef.id;
    // Optional: If you want to update the document with the new ID in Firestore
    await updateDoc(docRef, { id: docRef.id });

      setTitle('');
      setDescription('');
      setArtCollection('');
      setImages([]);
      setDate(new Date());
      setSnackbarMessage('Post created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setInEtsy(false)
      setInRedbubble(false)
      setNftAvailable(false)
      // setName(name);
      // setEmail(email);
      // setCity(city);
      // setCountry(country);
      // setAddress({
      //   addressLine: '',
      //   city: '',
      //   state: '',
      //   zipCode: '',
      //   country: '',
      // });
      // setFeaturePost(false);
      // setSelectedTags([]);
    } catch (e) {
      console.error('Error adding document: ', e);
      setSnackbarMessage('Failed to create post. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsPosting(false);
    }
  };

  const fontColor = backgroundColor === '#ffffff' ? 'black' : 'lightgrey';
  return (
    <>
      <Box
        sx={{
          height: '100vh',
          overflowY: showPaymentForm ? 'hidden' : 'auto',
          display: 'flex',
        }}
      >
        {showPaymentForm ? (
          clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <Container maxWidth='xl' sx={{ pb: 0 }}>
                <PaymentForm
                  onPaymentSuccess={async () => {
                    try { // Ensure the payment form closes
                      setShowPaymentForm(false);
                      // Proceed with form submission after payment is completed
                      setIsPosting(true); // Start posting process
                      const uploadedImageURLs = await uploadImages(images);

                      const postDate = new Date();
                      const newPost = {
                        title,
                        description,
                        artCollection,
                        timestamp: postDate.toISOString(),
                        images: uploadedImageURLs,
                        name,
                        email,
                        location: { city, country }, // Include city and country in the post data
                        readableDate: postDate.toLocaleDateString(),
                        readableTime: postDate.toLocaleTimeString(),
                      };
                      // Save post to Firestore
                      const docRef = await addDoc(
                        collection(db, 'galleryPosts'),
                        newPost
                      );
                      // Reset form fields
                      setTitle('');
                      setDescription('');
                      setArtCollection('');
                      setImages([]);
                      setDate(new Date());
                      setSnackbarMessage('Post created successfully!');
                      setSnackbarSeverity('success');
                      setSnackbarOpen(true);
                      setInEtsy(false)
                      setInRedbubble(false)
                      setNftAvailable(false)
                       // setSelectedTags([]);
                       // setName(name);
                      // setEmail(email);
                      // setCity(city);
                      // setCountry(country);
                      // setFeaturePost(false);
                      //setAddToNewsletter(false);
                    } catch (error) {
                      console.error('Error adding document: ', error);
                      setSnackbarMessage(
                        'Failed to create post. Please try again.'
                      );
                      setSnackbarSeverity('error');
                      setSnackbarOpen(true);
                    } finally {
                      setIsPosting(false);
                    }
                  }}
                  onCancel={() => {
                    setShowPaymentForm(false);
                    setClientSecret(null); // Clear the client secret when canceling
                  }}
                  totalPrice={totalPrice}
                />
              </Container>
            </Elements>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          )
        ) : (
          <Container maxWidth='xl' sx={{ pb: 0 }}>
            <Box
              sx={{
                height: '100vh',
                mt: 0,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                component='form'
                onSubmit={handleSubmit}
                sx={{
                  mt: 2,
                  mb: 0,
                  ml: {
                    xs: 'auto',
                    sm: 'auto',
                    md: 'auto',
                    lg: 'auto',
                    xl: 'auto',
                  },
                  mr: {
                    xs: 'auto',
                    sm: 'auto',
                    md: 'auto',
                    lg: 'auto',
                    xl: 'auto',
                  },
                  p: 1,
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '90%',
                    xl: '80%',
                  },
                  boxSizing: 'border-box',
                }}
              >
                <Grid
                  container
                  spacing={3}
                  sx={
                    {
                      //border: '1px dashed blue',
                    }
                  }
                >
                  {/* Title */}

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={8}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                    }}
                  >
                    <TextField
                      fullWidth
                      placeholder='Enter piece title (Max.100 Characters)'
                      variant='standard'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      inputProps={{ maxLength: 100 }}
                      required
                      sx={{
                        backgroundColor: 'white',
                        mb: 0,
                        '& .MuiInputBase-input': {
                          fontFamily: 'Montserrat, sans-serif',
                          fontWeight: 'bold',
                          fontSize: {
                            xs: '1rem',
                            sm: '1rem',
                            md: '1.3rem',
                            lg: '1.42rem',
                            xl: '1.42rem',
                          },
                        },
                      }}
                    />
                    {/* Description */}
                    <TextField
                      fullWidth
                      label='Description'
                      variant='outlined'
                      multiline
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      sx={{ backgroundColor: 'white' }}
                      //required
                    />

                    {/* Collection */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        pb: 0,
                        //maxHeight: artCollection ? '160px' : '50px',
                        backgroundColor: 'white',
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>Collection</InputLabel>
                        <Select
                          value={artCollection}
                          onChange={(e) => setArtCollection(e.target.value)}
                          required
                          label='Collection'
                        >
                          {artCollections.map((piece) => (
                            <MenuItem key={piece} value={piece}>
                              {piece}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={6} lg={4} >
                          <FormControl
                            component='fieldset'
                            sx={{
                              backgroundColor: 'white',
                              p: 0.5,
                              borderRadius: 1,
                            }}
                          >
                            <FormLabel
                              component='legend'
                              sx={{ color: 'black', pt: 3, fontSize:{xs:14, sm:14, md:16, lg:16, xl:16 }}}
                            >
                             In Etsy?
                            </FormLabel>
                            <RadioGroup
                              row
                              name='inEtsy'
                              value={inEtsy.toString()}
                              onChange={(e) =>
                                setInEtsy(e.target.value === 'true')
                              }
                              sx={{ backgroundColor: 'white', }}
                            >
                              <FormControlLabel
                                value='true'
                                control={<Radio />}
                                label='Yes'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio />}
                                label='No'
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} lg={4}>
                          <FormControl
                            component='fieldset'
                            sx={{
                              backgroundColor: 'white',
                              p: 0.5,
                              borderRadius: 1,
                            }}
                          >
                            <FormLabel
                              component='legend'
                              sx={{ color: 'black', pt: 3, fontSize:{xs:14, sm:14, md:16, lg:16, xl:16 } }}
                            >
                              In RedBubble?
                            </FormLabel>
                            <RadioGroup
                              row
                              name='inRedBubble'
                              value={inRedBubble.toString()}
                              onChange={(e) =>
                                setInRedbubble(e.target.value === 'true')
                              }
                              sx={{ backgroundColor: 'white' }}
                            >
                              <FormControlLabel
                                value='true'
                                control={<Radio />}
                                label='Yes'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio />}
                                label='No'
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <FormControl
                            component='fieldset'
                            sx={{
                              backgroundColor: 'white',
                              p: 0.5,
                              borderRadius: 1,
                            }}
                          >
                            <FormLabel
                              component='legend'
                              sx={{ color: 'black', pt: 3, fontSize:{xs:14, sm:14, md:16, lg:16, xl:16 } }}
                            >
                              NFT Available
                            </FormLabel>
                            <RadioGroup
                              row
                              name='nftAvailable'
                              value={nftAvailable.toString()}
                              onChange={(e) =>
                                setNftAvailable(e.target.value === 'true')
                              }
                              sx={{ backgroundColor: 'white', color: 'black' }}
                            >
                              <FormControlLabel
                                value='true'
                                control={<Radio />}
                                label='Yes'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio />}
                                label='No'
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>

                  </Grid>

                  {/* IMAGES */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={8}
                      sx={{
                        ml: { xs: 'auto' },
                        mr: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //border:'2px solid red'
                      }}
                    >
                      {images.length === 0 ? (
                        <Button
                          variant='outlined'
                          component='label'
                          fullWidth
                          disabled={isPosting}
                          sx={{
                            backgroundColor: 'white',
                            //border: '2px dashed blue',
                            pl: 0,
                            pr: 0,
                            pt: 5,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: {
                              xs: 'auto',
                              sm: 'auto',
                              md: 'auto',
                              lg: 260,
                              xl: 260,
                            },
                          }}
                        >
                          <AddPhotoAlternateIcon
                            sx={{
                              fontSize: {
                                xs: 50,
                                sm: 50,
                                md: 70,
                                lg: 80,
                                xl: 80,
                              },
                              color: 'rgb(100,150,250, 0.4)',
                              mb: 2,
                            }}
                          />
                          Add Feature Image
                          <input
                            type='file'
                            hidden
                            multiple
                            accept='image/*'
                            onChange={handleImageChange}
                            disabled={images.length >= parseInt(imageLimit)}
                          />
                        </Button>
                      ) : (
                        <Box
                          sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <img
                            src={URL.createObjectURL(images[0])}
                            alt='feature-upload'
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: 'inherit',
                            }}
                          />
                          <IconButton
                            component='label'
                            sx={{
                              position: 'absolute',
                              bottom: -10,
                              right: -10,
                              backgroundColor: 'white',
                              border: '2px solid lightgrey',
                              zIndex: 10,
                              '&:hover': {
                                backgroundColor: 'lightgrey',
                              },
                            }}
                          >
                            <AddPhotoAlternateIcon />
                            <input
                              type='file'
                              hidden
                              multiple
                              accept='image/*'
                              onChange={handleImageChange}
                              disabled={images.length >= parseInt(imageLimit)}
                            />
                          </IconButton>
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: -6,
                              right: -6,
                              backgroundColor: 'white',
                              zIndex: 10,
                            }}
                            onClick={() => handleRemoveImage(0)}
                          >
                            <ClearIcon fontSize='small' color='error' />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                      {/* where select box for images were */}
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        mt: 2,
                        mb: 2,
                        justifyContent: 'center',
                      }}
                    >
                      {images.map((image, index) => (
                        <Box
                          key={index}
                          sx={{
                            position: 'relative',
                            width: 100,
                            height: 100,
                            border: '1px solid lightgrey',
                            borderRadius: 2,
                          }}
                        >
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`upload-${index}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: 'inherit',
                            }}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: -6,
                              right: -9,
                              backgroundColor: 'black',
                              p: 0,
                            }}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <ClearIcon fontSize='small' color='error' />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Grid> */}
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt:2,
                    mb:1,
                    ml: 'auto',
                    mr: 'auto',
                    width: { lg: '30%', xl: '30%' },
                  }}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={isPosting}
                    sx={{ borderRadius: 8, backgroundColor: 'darkblue' }}
                  >
                    {/* {isPosting
                      ? 'Creating Post...'
                      : `Create Post $${totalPrice}.00`} */}
                       {isPosting
                      ? 'Creating Post...'
                      : `Create Post`}
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant='body1'
                    color='secondary'
                    sx={{ mt: 2, textAlign: 'center' }}
                  >
                    {confirmation}
                  </Typography>
                </Box>
              </Box>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isPosting}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </Box>
          </Container>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarSeverity === 'success' ? 5000 : null}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={snackbarSeverity !== 'success' ? handleSnackbarClose : null}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default CreatePost;



                    {/* <FormControl component='fieldset'>
                      <RadioGroup
                        row
                        aria-label='imageLimit'
                        name='imageLimit'
                        value={imageLimit}
                        onChange={(e) => setImageLimit(e.target.value)}
                        sx={{
                          mt: 2,
                          ml: { xs: 0, sm: 0, md: 0, lg: 11.5, xl: 0 },
                        }}
                      >
                        <Box
                          sx={{
                            border: '1px dashed navy',
                            mb: 2,
                            ml: { xs: 0, sm: 0, md: 0, lg: 3, xl: 3 },
                            borderRadius: 2,
                            p: 1,
                            backgroundColor: 'white',
                          }}
                        >
                          <FormControlLabel
                            value='1'
                            control={<Radio />}
                            label={
                              <Box
                                sx={{
                                  backgroundColor: 'white',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  p: 0.5,
                                  borderRadius: 1,
                                  color: 'navy',
                                  width: {
                                    xs: '245px',
                                    sm: '300px',
                                    md: '300px',
                                    lg: '300px',
                                    xl: '300px',
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Typography variant='body1'>
                                    1 image
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    sx={{
                                      fontSize: 13,
                                      fontStyle: 'italic',
                                    }}
                                  >
                                    You can add 3 images for free
                                  </Typography>
                                </Box>
                                <Typography variant='body1'>Free</Typography>
                              </Box>
                            }
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl> */}

{/* Parent Grid Container */}
                    {/* Remove for now and implement later  when Geolocation is live */}
                    {/* <Grid
                      container
                      spacing={2} // Adds spacing between items
                      sx={{
                        alignItems: 'center', // Vertically center items
                      }}
                    > */}
                    {/* City */}
                    {/* Remove for now and implement later  when Geolocation is live */}

                    {/* <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 5,
                        }}
                      >
                        <TextField
                          fullWidth
                          //placeholder='Enter the title of your post (Max.100 Characters)'
                          //variant='standard'
                          //inputProps={{ maxLength: 100 }}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          label='City'
                          //required
                          // sx={{
                          //   mb: 0,
                          //   //border: 'none',
                          //   '& .MuiInputBase-input': {
                          //     fontFamily: 'Arial, sans-serif',
                          //     fontWeight: 'bold',
                          //     fontSize: '1.42rem',
                          //   },
                          // }}
                        />
                      </Grid> */}
                    {/* Country */}
                    {/* Remove for now and implement later  when Geolocation is live */}
                    {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          pb: 0,
                          maxHeight: country ? '160px' : '50px',
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>Country</InputLabel> */}
                    {/* This method allows me to display country flags */}
                    {/* <Select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            required
                            label='Country'
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                {countriesList.find(
                                  (item) => item.label === selected
                                )?.code && (
                                  <img
                                    src={`https://flagcdn.com/w40/${countriesList
                                      .find((item) => item.label === selected)
                                      .code.toLowerCase()}.png`}
                                    alt=''
                                    width='20'
                                    style={{ borderRadius: '3px' }}
                                  />
                                )}
                                {selected}
                              </Box>
                            )}
                          >
                            {countriesList.map((cat) => (
                              <MenuItem key={cat.code} value={cat.label}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                  }}
                                >
                                  {cat.code && (
                                    <img
                                      src={`https://flagcdn.com/w40/${cat.code.toLowerCase()}.png`}
                                      alt=''
                                      width='20'
                                      style={{ borderRadius: '3px' }}
                                    />
                                  )}
                                  {cat.label}
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid> */}


{/* {collection && (
                        <FormControl fullWidth>
                          <Typography
                            variant='body'
                            gutterBottom
                            sx={{ mt: 2 }}
                          >
                            Add Tags (Optional)
                          </Typography>
                          <FormGroup row>
                            {categoryAndTags
                              .find((cat) => cat.name === category)
                              ?.tags.map((tag) => (
                                <FormControlLabel
                                  key={tag}
                                  control={
                                    <Checkbox
                                      value={tag}
                                      checked={selectedTags.includes(tag)}
                                      onChange={handleTagChange}
                                    />
                                  }
                                  label={tag}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>
                      )} */}
//PREVIOUS COUNTRY SELECT FIELD:
{
  /* <Select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          // required
                          label='Country'
                        >
                          {countriesList.map((cat) => (
                            <MenuItem key={cat} value={cat.label}>
                              {cat.label}
                            </MenuItem>
                          ))}
                        </Select> */
}
