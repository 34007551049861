import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { useActiveComponent } from '../ActiveComponentContext.js/index.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import TemporaryDrawer from '../Drawer/index.jsx';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { auth } from '../../Firebase/firebase.js';
import { deepOrange } from '@mui/material/colors';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import SearchBar from '../SearchBar/index.jsx';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
//import MiniDrawer from '../MiniDrawer/index.jsx';
import { getStorage, ref, getDownloadURL } from 'firebase/storage'; // Import Firebase functions
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CreateIcon from '@mui/icons-material/Create';
import { PostsContext } from '../../contexts/PostsContext/index.jsx';

const MyAppBar = () => {
  //Retrieve Context Values
  const { currentUser, openLogInModal, setShowAlertDialog } = useAuth(); // Use the AuthContext to get current user
  const { activePath, setActivePath, backgroundColor } = useActiveComponent();
  // const { xploras360Image, defaultAvatarImage} = useContext(PostsContext);

  const navigate = useNavigate();

  //Local States
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCreate, setAnchorElCreate] = useState(null);
  //const [xploras360Image, setXploras360Image] = useState(''); // State to store the image URL

  //Menu Dropdown Options for User Icon
  const userDropdownOptions = currentUser
    ? ['Dashboard', 'User Profiles', 'Logout']
    : ['Log In / Sign Up'];
  const createMenuDropdownOptions = ['Create Post', 'Post Ad'];

  const [drawerOpen, setDrawerOpen] = useState(false);

  const activeStyles = {
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '6px',
    '&:hover': {
      boxShadow: '0px 2px 3px grey',
      transform: 'scale(1.04)', // Optional: adds a slight zoom effect
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    },
    // '&:hover': {
    //   boxShadow:'0px 2px 9px red'
    //   // backgroundColor: 'black',
    //   // color: 'white',
    // },
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //FUNCTIONS
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  // const handleOpenCreateMenu = (event) => {
  //   setAnchorElCreate(event.currentTarget);
  // };

  const handleOpenCreateMenu = (event) => {
    setAnchorElCreate(event.currentTarget);
  };

  const handleCloseCreateMenu = () => {
    setAnchorElCreate(null);
  };

  //For Create Icon Button
  const handleCreateMenuItemClick = (item) => {
    if (currentUser) {
      const path = item.toLowerCase().replace(' ', ''); // Convert item to lowercase and remove spaces
      handleNavigation(path); // Navigate to the corresponding path
    } else {
      setShowAlertDialog(true); // Show the alert dialog to login if the user is not signed in
    }
    handleCloseCreateMenu(); // Close the menu after clicking
    // setAnchorElCreate(null);//handled in handleCloseCreateMenu function
  };

  const handleCloseUserMenu = (setting) => {
    if (setting === 'Log In / Sign Up') {
      openLogInModal(); // setLoginOpen(true);
    } else if (setting === 'Logout') {
      auth.signOut(); //.then(() => setAuthState(null)); // Update authState after sign-out
      navigate('/');
      setActivePath('/');
    } else if (setting === 'Dashboard') {
      navigate('/dashboard/myposts');
      setActivePath('/dashboard/myposts');
    } else if (setting === 'User Profiles') {
      navigate('/userprofiles');
      setActivePath('/userprofiles');
    }
    setAnchorElUser(null);
  };

  // const handleCloseCreateMenu = () => {
  //   setAnchorElCreate(null);
  // };

  const handleTitleClick = () => {
    navigate('/');
    setActivePath('/');
  };

  const handleNavigation = (path) => {
    navigate(`/${path}`); // Navigate to the respective path
    setActivePath(`/${path}`);
    //resetExpandedItems();
    //setOpen(false); // Close the drawer after navigating
  };

  const fontColor = backgroundColor === '#ffffff' ? '#030202' : 'lightgrey';

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const storage = getStorage(); // Initialize Firebase Storage
  //     const imageRef = ref(storage, 'siteImages/xploras360.png'); // Reference to the image in Firebase

  //     try {
  //       const url = await getDownloadURL(imageRef); // Get the image URL
  //       setXploras360Image(url); // Update the state with the URL
  //     } catch (error) {
  //       console.error('Error fetching xploras360 image:', error); // Handle errors
  //     }
  //   };

  //   fetchImage();
  // }, []);
  return (
    <>
      <AppBar
        position='static'
        //open={drawerOpen}
        style={{
          backgroundColor: '#030202',
          boxShadow: 'none',
        }}
        // sx={{
        //   zIndex: (theme) => theme.zIndex.drawer + 1, // Set z-index lower than SearchBar
        // }}
      >
        <Container
          maxWidth='xl'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            //border: '2px solid red',
            paddingLeft: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
            paddingRight: { xs: 1, sm: 1, md: 0, lg: 0, xl: 0 },
          }}
        >
          {/* <Toolbar
            disableGutters
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              //border:'2px solid green',
              paddingRight: '1px',
            }}
            sx={{ pr: { xs: 0, lg: '13px' }, marginRight: { xs: -2, lg: 0 } }}
          > */}
          {/* Contains Name & Logo */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ml: 0,
              pb: 1,
              pt: { xs: 1, sm: 1, md: 0.5, lg: 0, xl: 0 },
              cursor: 'pointer',
            }}
            onClick={handleTitleClick}
          >
            <Box
              component='img'
              src={'./images/siteImages/afrospiritartlogo.jpg'} // Use a placeholder if the image URL is not available
              alt='afrospiritartLogo'
              sx={{
                width: {
                  xs: 33,
                  sm: 33,
                  md: 33,
                  lg: 38,
                  xl: 48,
                },
                height: 'auto',
                mr: { xs: 1, sm: 1, md: 1.4, lg: 1.5, xl: 1.5 },
                ml: { xs: 1, sm: 1, md: 1, lg: 1.25, xl: 0 },
                mt: { xs: 1, lg: 2, xl: 2 },
              }}
            />
            <Typography
              variant='h6'
              // noWrap
              component='div'
              sx={{
                // ml: { xs: 1, sm: 1, md: 1, lg: 1.55, xl: 0 },
                // mr: { xs: 1, sm: 1, md: 1, lg: 12, xl: 12 },
                display: { xs: 'flex', md: 'flex' },
                //fontFamily: 'Raleway Dots, cursive',
                fontFamily: 'Montserrat',
                //fontWeight: { xs: 1000, md: 1000, lg: 1000, xl: 1000 },
                textShadow: '0px 0px 0px black',
                letterSpacing: '.3rem',
                fontSize: { xs: 11, sm: 11, md: 12, lg: 14, xl: 14 },
                color: 'white',
                textDecoration: 'none',
                pt: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
              }}
            >
              afrospiritart
            </Typography>
          </Box>

          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              },
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              gap: { xs: 0, sm: 0, md: 5, lg: 8, xl: 8 },
              //border: '2px solid green',
              //width:'40%',
              // ml:'auto',
              // mr:'auto',
              mr: '12%',
              mt: { xs: 1, sm: 1, md: 1, lg: 2, xl: 2 },
            }}
          >
            <Typography
              // variant='h9'
              component='div'
              sx={{
                color: 'white',
                cursor: 'pointer',
                p: 1,
                fontFamily: 'Montserrat',
                //fontFamily: 'Raleway Dots, cursive',
                fontSize: { xs: 11, sm: 11, md: 11, lg: 14, xl: 14 },
                ...(activePath === '/' && activeStyles), // Apply styles if activePath matches
                //fontWeight:1000,
                // '&:hover':{
                //   boxShadow:'0px 2px 3px grey',
                //   transform: 'scale(1.04)', // Optional: adds a slight zoom effect
                //   transition:
                //     'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',}
              }}
              onClick={() => handleNavigation('')}
            >
              Art
            </Typography>
            <Typography
              // variant='h9'
              component='div'
              sx={{
                color: 'white',
                cursor: 'pointer',
                p: 1,
                fontFamily: 'Montserrat',
                //fontFamily: 'Raleway Dots, cursive',
                fontSize: { xs: 11, sm: 11, md: 11, lg: 14, xl: 14 },
                ...(activePath === `/worlds` && activeStyles), // Apply styles if activePath matches
                //fontWeight:1000,
                // '&:hover': {
                //   boxShadow: '0px 2px 3px grey',
                //   transform: 'scale(1.04)', // Optional: adds a slight zoom effect
                //   transition:
                //     'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                // },
              }}
              onClick={() => handleNavigation('worlds')}
            >
              Worlds
            </Typography>
            <Typography
              // variant='h9'
              component='div'
              sx={{
                color: 'white',
                cursor: 'pointer',
                p: 1,
                fontFamily: 'Montserrat',
                //fontFamily: 'Raleway Dots, cursive',
                fontSize: { xs: 11, sm: 11, md: 11, lg: 14, xl: 14 },
                ...(activePath === `/shop` && activeStyles), // Apply styles if activePath matches
                //fontWeight:1000,
                // '&:hover':{
                //   boxShadow:'0px 2px 3px grey',
                //   transform: 'scale(1.04)', // Optional: adds a slight zoom effect
                //   transition:
                //     'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',}
              }}
              onClick={() => handleNavigation('shop')}
            >
              Shop
            </Typography>

            <Typography
              // variant='h9'
              component='div'
              sx={{
                color: 'white',
                cursor: 'pointer',
                p: 1,
                fontFamily: 'Montserrat',
                fontSize: { xs: 11, sm: 11, md: 11, lg: 14, xl: 14 },
                ...(activePath === `/aboutus` && activeStyles), // Apply styles if activePath matches
                //fontWeight:1000,
                // '&:hover': {
                //   boxShadow: '0px 2px 3px grey',
                //   transform: 'scale(1.04)', // Optional: adds a slight zoom effect
                //   transition:
                //     'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                // },
              }}
              onClick={() => handleNavigation('aboutus')}
            >
              About
            </Typography>
            <Typography
              // variant='h9'
              component='div'
              sx={{
                color: 'white',
                cursor: 'pointer',
                p: 1,
                fontFamily: 'Montserrat',
                ...(activePath === `/contactus` && activeStyles), // Apply styles if activePath matches
                fontSize: { xs: 11, sm: 11, md: 11, lg: 14, xl: 14 },
                //fontWeight:1000,
                // '&:hover': {
                //   boxShadow: '0px 2px 3px grey',
                //   transform: 'scale(1.04)', // Optional: adds a slight zoom effect
                //   transition:
                //     'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                // },
              }}
              onClick={() => handleNavigation('contactus')}
            >
              Contact
            </Typography>
          </Box>

          <Box sx={{ ml: -2, mt: 1 }}>
            <TemporaryDrawer
              auth={currentUser}
              openLoginModal={openLogInModal}
            />
          </Box>
        </Container>
      </AppBar>
    </>
  );
};
export default MyAppBar;

{
  /* <Box
              sx={{
                display: { xs: 'none', lg: 'flex', xl: 'flex' },
                ml: { xs: 20, sm: 20, md: 20, lg: 'auto', xl: 'auto' },
                mr: { xs: 20, sm: 20, md: 20, lg: 'auto', xl: 'auto' },
                mt: -1,
              }}
            >
              <SearchBar />
            </Box> */
}

{
  /* Right section of Icon Buttons */
}
{
  /* <Box
              sx={{
                //flexGrow: 0,
                display: 'flex',
                // alignItems: 'center',
                // justifyContent:'center'
                // mr: { xs: 0.5, sm: 0.5, md: 0.5, lg: -3, xl: 0.9 },
              }}
            > */
}
{
  /* Create Post Button */
}
{
  /* <Box
                sx={{
                  display: 'flex',
                  backgroundColor: 'darkblue',
                  alignItems: 'center',
                  mr: 1.5,
                  pl: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 },
                  pr: { xs: 0, sm: 0, md: 1, lg: 1, xl: 1 },
                  pt: { xs: 0.5, sm: 0.5, md: 0.1, lg: 0.1, xl: 0.1 },
                  pb: { xs: 0.5, sm: 0.5, md: 0.1, lg: 0.1, xl: 0.1 },
                  // p: {xs:0.5,sm:0.5,md:0.25,lg:0.25,xl:0.25},

                  borderRadius: 16,
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'mediumblue',
                    //boxShadow: '0px 1px 2px skyblue',
                    //border: '1px solid lightblue',
                  },
                }}
                // onClick={() => handleCreateMenuItemClick('Create Post')}
                onClick={handleOpenCreateMenu}
              >
                <Box display={{ xs: 'none', lg: 'block' }}>
                  <Typography
                    sx={{ ml: 0.5, fontSize: 13, fontFamily: 'Montserrat' }}
                  >
                    Create
                  </Typography>
                </Box>
                <IconButton
                  sx={{
                    mr: { xs: 0.5, sm: 0, md: 0, lg: -1.2, xl: -1.2 },
                    ml: { xs: 0.5, sm: 0, md: 0, lg: 0, xl: 0 },
                  }}
                  // onClick={handleOpenCreateMenu} //to open Menu
                >
                  <AddIcon
                    sx={{
                      color: 'white',
                      fontSize: { xs: 12, sm: 11, md: 15, lg: 20, xl: 20 },
                      mb: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
                      mr: { md: 0, lg: 0.5, xl: 0.5 },
                    }}
                  />
                </IconButton>
              </Box> */
}

{
  /* Create Menu Dropdown */
}
{
  /* <Menu
                sx={{
                  mt: {
                    xs: '40px',
                    sm: '40px',
                    md: '40px',
                    lg: '48px',
                    xl: '48px',
                  },
                  ml: '0px',
                }}
                id='create-menu'
                anchorEl={anchorElCreate}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElCreate)}
                onClose={handleCloseCreateMenu}
              >
                {createMenuDropdownOptions.map((option) => (
                  <MenuItem
                    key={option}
                    onClick={() => handleCreateMenuItemClick(option)}
                  >
                    {option === 'Create Post' ? (
                      <CreateIcon
                        sx={{
                          mr: 1,
                          fontSize: { xs: 18, sm: 18, md: 18, lg: 23, xl: 23 },
                        }}
                      />
                    ) : (
                      <PostAddIcon
                        sx={{
                          mr: 1,
                          fontSize: { xs: 18, sm: 18, md: 18, lg: 23, xl: 23 },
                        }}
                      />
                    )}
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: 12, sm: 12, md: 12, lg: 13, xl: 13 },
                      }}
                      textAlign='center'
                    >
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu> */
}

{
  /* User Account / Icon Button */
}
{
  /* {!currentUser && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0,
                    borderRadius: 12,
                    border: '1px solid grey',
                    // boxShadow: '0px 1px 2px grey',
                    pl: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0.5 },
                    pr: { xs: 0, sm: 0, md: 0, lg: 0.5, xl: 0.5 },
                    mr: { xs: 0.5, sm: 0.5, md: 0.5, lg: 2, xl: 2 },
                    cursor: 'pointer',
                    '&:hover': {
                      borderRadius: 12,
                      boxShadow: '0px 1px 2px grey',
                    },
                  }}
                  onClick={handleOpenUserMenu}
                >
                  <IconButton
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'white' },
                    }}
                  >
                    <PersonIcon
                      sx={{
                        color: 'dimgrey',
                        p: 0,
                        fontSize: { xs: 18, sm: 18, md: 22, lg: 22, xl: 22 },
                      }}
                    />
                  </IconButton>
                  <Box display={{ xs: 'none', lg: 'block' }}>
                    <Typography
                      sx={{
                        mr: 1,
                        color: 'dimgrey',
                        fontSize: 13,
                        fontFamily: 'Montserrat',
                      }}
                    >
                      log in
                    </Typography>
                  </Box>
                </Box>
              )}
              {currentUser && (
                <IconButton
                  disableRipple
                  sx={{
                    mr: 1,
                    p: 0,
                    color: anchorElUser ? 'white' : 'inherit',
                    boxShadow: anchorElUser ? '0px 0px 5px black' : 'none',
                    '&:hover': {
                      boxShadow: '0px 0px 5px black',
                    },
                  }}
                  onClick={handleOpenUserMenu}
                >
                  <Avatar
                    alt={currentUser.displayName || 'User'}
                    // src={currentUser.photoURL || null}
                    src={
                      currentUser.photo || currentUser.photoURL || defaultAvatarImage || null
                    }
                    sx={{
                      fontSize: { xs: 16, sm: 16, md: 17, lg: 21, xl: 21 },
                      width: { xs: 38, sm: 38, md: 35, lg: 35, xl: 35 },
                      height: { xs: 38, sm: 38, md: 35, lg: 35, xl: 35 },
                      bgcolor: currentUser.photoURL
                        ? currentUser.photoURL //  ? 'lightblue'
                        : deepOrange[300],
                    }}
                  >
                    {!currentUser.photoURL &&
                      (currentUser.displayName?.[0] ||
                        currentUser.email?.[0] ||
                        'U')}
                  </Avatar>
                </IconButton>
              )}

              <Menu
                sx={{
                  mt: {
                    xs: '40px',
                    sm: '40px',
                    md: '40px',
                    lg: '44px',
                    xl: '44px',
                  },
                  ml: '0px',
                }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userDropdownOptions.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Montserrat',
                        fontSize: { xs: 12, sm: 12, md: 12, lg: 13, xl: 13 },
                      }}
                      textAlign='center'
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu> */
}
{
  /* <TemporaryDrawer
                auth={currentUser}
                openLoginModal={openLogInModal}
              /> */
}
{
  /* </Box> */
}
{
  /* </Toolbar> */
}

//ACTIVE STYLE SETUP- POSSIBLY USE LATER:
// const activeStyles = {
//   backgroundColor:
//     backgroundColor !== '#ffffff' ? 'whitesmoke' : 'rgb(0, 0, 0,0.8)',
//   boxShadow: '0px 3px 5px  dimgrey',
//   color: backgroundColor,
// };
