// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseApiKey = process.env.VITE_FIREBASE_API_KEY;
const firebaseApiKey = import.meta.env.VITE_FIREBASE_API_KEY //was failing env stuff please help!

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDV_PUa0ZBxpE98Cj8QCM8OfTQeXEqdnD8",
//   authDomain: "afrospiritartsite.firebaseapp.com",
//   projectId: "afrospiritartsite",
//   storageBucket: "afrospiritartsite.firebasestorage.app",
//   messagingSenderId: "273124529990",
//   appId: "1:273124529990:web:f0b530bdb1796e11b8f747",
//   measurementId: "G-Q66GW8H1LE"
// };

// Debug logging
console.log('Environment Variables:', {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
});

//console.log("firebase api Key is: " + firebaseApiKey)
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};


// Add console.log here
console.log('Firebase config loaded:', {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY?.slice(0, 5) + '...',
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//analytics & database ssetup
const analytics = getAnalytics(app);
const db = getFirestore(app);

//Authentication setup
const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider()


const storage = getStorage();
const getImageUrl = async (imagePath) => {
  const imageRef = ref(storage, imagePath);
  return await getDownloadURL(imageRef);
};
export { db, auth, googleProvider, storage, getImageUrl };