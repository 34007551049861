//import { Grid, Box } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, Box, Grid } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import DashboardCards from '../../Components/DashboardCards';
import LargePostCardDialog from '../../Components/LargePostCardDialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const MyPosts = () => {
  const { posts, setPosts } = useOutletContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const { deleteUserPost } = useAuth(); // Get deleteUserPost from AuthContext

  //Function to handle SummaryCard click and display LargePostDialog popUp
  const handleCardClick = (cardData) => {
    if (!cardData) {
      console.error('Card data is not available');
      return;
    }
    setSelectedCard(cardData); // cardData now contains category too so above code not needed
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCard(null);
  };

  const handlePostDelete = (deletedPostId) => {
    setPosts((prevPosts) =>
      prevPosts.filter((post) => post.id !== deletedPostId)
    );
    deleteUserPost(deletedPostId); // Call deleteUserPost to update userPosts in AuthContext
  };

  return (
    <Box
      sx={{
        pt: 0,
        pl: 0,
        //width: '100%',
        // display: 'flex',
        // justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          gap: 3,
          mt: 1,
          mb: 5,
          p: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          boxSizing:'border-box',

          //height:'56vh',
          // width: '100%',
          // border: '2px solid red',
          // boxShadow: '0px 2px 3px inset grey',
          // borderRadius:4,
        }}
      >
        {/* <Grid container spacing={1}> */}
        {posts.map((post) => (
          <DashboardCards
            key={post.id} // Added key to each DashboardCards component
            id={post.userId}
            postID={post.id}
            images={post.images || []} // Pass the entire array of images
            title={post.title}
            summary={post.description}
            externalLinks={post.relatedLinks}
            photoURL={post.userPhotoURL}
            username={post.username} //pass the username to LargePostCard
            certification={post.certification}
            name={post.name}
            email={post.email}
            phoneNumber={post.phoneNumber}
            address={post.address}
            date={post.readableDate}
            category={post.category}
            postsCount={post.length}
            vertical='230px'
            horizontal='240px'
            deletable={true}
            onClick={() => handleCardClick(post)}
            onDelete={() => handlePostDelete(post.id)} // Pass handlePostDelete to DashboardCards
          />
        ))}
        {/* </Grid> */}
      </Box>
      {/* PopUp Dialog displaying LargePostCardDialog */}
      {selectedCard && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth='lg'
          fullWidth
          PaperProps={{
            style: {
              maxHeight: 'calc(100vh - 15px)',
              overflowY: 'auto',
              height: '85vh',
              borderRadius: '24px',
            },
          }}
        >
          <DialogTitle sx={{ mb: 1, p: 2 }}>
            {/* {selectedCard.title} */}
            <IconButton
              aria-label='close'
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: { xs: 2, sm: 2, md: 4, lg: 8, xl: 8 },
                top: { xs: 1, sm: 1, md: 3, lg: 8, xl: 8 },
                color: (theme) => theme.palette.grey[500],
                '&:hover': {
                  backgroundColor: 'indianred',
                  color: 'white',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              mt: 0,
              mb: 0,
              p: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LargePostCardDialog
            id={selectedCard.userId}
              images={selectedCard.images}
              title={selectedCard.title}
              summary={selectedCard.description}
              link={selectedCard.link}
              ratings={selectedCard.ratings}
              postID={selectedCard.id}
              postInfo={selectedCard} // Pass the whole object
              photoURL={selectedCard.userPhotoURL}
              username={selectedCard.username} //pass the username to LargePostCard
              externalLinks={selectedCard.externalLinks}
              certification={selectedCard.certification}
              name={selectedCard.name}
              email={selectedCard.email}
              phoneNumber={selectedCard.phoneNumber}
              address={selectedCard.address}
              date={selectedCard.readableDate}
              category={selectedCard.category}
              country={selectedCard.address.country}
              //navigateToCategory={(category) => handleClick(`/categories/${category}`)} // Pass the navigate function
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default MyPosts;
