import Typography from '@mui/material/Typography';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebase.js';
import React, { useState, useEffect } from 'react';
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';
import { useMediaQuery, useTheme } from '@mui/material';

import { Box, Button, TextField } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext/index.jsx';
import SearchBar from '../../Components/SearchBar/index.jsx';

const ContactUs = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [confirmation, setConfirmation] = useState('');
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name, // Name of the person sending the email
      to_name: 'Xpat360', // Name of the person receiving the email
      message: formData.message, // The content of the message
      reply_to: formData.email, // The reply-to email address
      to_email: 'afrospiritartist@gmail.com', // Ensure this email matches your intended recipient
    };

    //Name:Gmail
    //Service ID: service_7khwg2g
    emailjs
      .send(
        'service_7khwg2g',
        'template_k57u4k4',
        templateParams,
        'W29y5Sf_JXE6KOTi-'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setConfirmation('Your message has been sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' });
      })
      .catch((err) => {
        console.error('FAILED...', err);
        setConfirmation(
          'There was an error sending your message. Please try again later.'
        );
      });
  };

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     if (currentUser) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         email: currentUser.email, // Prefill email
  //       }));

  //       const userDocRef = doc(db, 'users', currentUser.uid);
  //       const userDoc = await getDoc(userDocRef);

  //       if (userDoc.exists()) {
  //         const userData = userDoc.data();
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           name: userData.name || 'Puke', // Prefill name if available
  //         }));
  //       }
  //     }
  //   };

  //   fetchUserDetails();
  // }, []);

  return (
    <Box>

      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: {xs:3, sm:3, md:3, lg:5, xl:5},
          //mb: 25,
        }}
      >
        <Typography
          variant='h4'
          sx={{
            display: {xs:'flex', sm:'flex', md:'none', lg:'none',xl:'none'},
            fontFamily: 'Montserrat',
            color: 'white',
            letterSpacing: '.3rem',
            fontSize: { xs: 12, sm: 12, md: 13, lg: 15, xl: 15 },
            flexDirection: 'center',
            textDecoration: 'none',
            mt: 3
          }}
          gutterBottom
        >
          Contact me
        </Typography>
        <Typography
          variant='body1'
          sx={{
            mt: 2,
            color: 'white',
            display: { xs: 'flex', md: 'flex' },
            fontFamily: 'Montserrat',
            width: { xs: '89%', sm: '85%', md: '70%', lg: '50%', xl: '50%' },
            fontWeight: 100,
            letterSpacing: '0rem',
            fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
            flexDirection: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
          }}
          gutterBottom
        >
          Let’s create something extraordinary together. My creative services include:
          {/* Whether you're looking
          for art commisions or prints, a stunning website, immersive 3D
          webspaces, or unique collaborations, I’m here to bring your vision to
          life.  */}
          
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
          {isMdUp? <ul
            style={{
              //listStyleType: 'none',
              padding: 0,
              margin: 0,
              color: 'white',
              fontFamily: 'Montserrat',
              width: '50%',
              marginTop: '12px',
              fontWeight: 20,
              mb: 10,
              
            }}
          >
             <li>
              <strong>Art Prints:</strong> Bring the essence of AfroSpiritArt
              into your space with prints of my works, perfect for adding a
              touch magic to your living spaces.
            </li>
            <li>
              <strong>Special Commissions:</strong> Exclusive AfroSpiritArt art
              created for you, reflecting the unique fusion of spirit,
              creativity & personal expression.
            </li>
            <li>
              <strong>Website Development:</strong> Custom 2D & 3D websites and
              webapps that blend functionality with visual artistry.
            </li>
            <li>
              <strong>3D Web Spaces:</strong> Design and development of
              interactive 3D environments that captivate and engage.
            </li>
            <li>
              <strong>Art Collaborations:</strong> Partner with me on creative
              projects that push boundaries and explore new dimensions.
            </li>
           
            <li>
              <strong>AI Image Generation:</strong> Customized AI-generated
              images, blending technology with artistic vision tailored to your needs.
            </li>
            </ul>
            : <ul
          style={{
            //listStyleType: 'none',
            fontSize:'10px',
            padding: 0,
            margin: 0,
            color: 'white',
            fontFamily: 'Montserrat',
            width: '80%',
            marginTop: '12px',
            fontWeight: 20,
            mb: 10,
            fontSize:13
          }}
        >
                       <li>
              <strong>Art Prints:</strong> Bring the essence of AfroSpiritArt
              into your space with prints of my works, perfect for adding a
              touch magic to your living spaces.
            </li>
            <li>
              <strong>Special Commissions:</strong> Exclusive AfroSpiritArt art
              created for you, reflecting the unique fusion of spirit,
              creativity & personal expression.
            </li>
            <li>
              <strong>Website Development:</strong> Custom 2D & 3D websites and
              webapps that blend functionality with visual artistry.
            </li>
            <li>
              <strong>3D Web Spaces:</strong> Design and development of
              interactive 3D environments that captivate and engage.
            </li>
            <li>
              <strong>Art Collaborations:</strong> Partner with me on creative
              projects that push boundaries and explore new dimensions.
            </li>
           
            <li>
              <strong>AI Image Generation:</strong> Customized AI-generated
              images, blending technology with artistic vision tailored to your needs.
            </li>
            </ul>}

        </Typography>
        <Typography
          variant='body1'
          sx={{
            mt: 2,
            color: 'white',
            display: { xs: 'flex', md: 'flex' },
            fontFamily: 'Montserrat',
            width: { xs: '89%', sm: '85%', md: '70%', lg: '50%', xl: '50%' },
            fontWeight: 100,
            letterSpacing: '0rem',
            fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 16 },
            flexDirection: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
          }}
          gutterBottom
        >
          {' '}
          Feelfree to reach out with your project ideas, collaboration
          proposals, or inquiries about my art prints or custom commissions. I'm
          excited to hear from you and explore how we can work together to
          channel creativity into something truly special.
        </Typography>

        <Box
          sx={{
            p: 2,
            mt: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: { xs: '90%', sm: '90%', md: '80%', lg: '50%', xl: '50%' },
            overflowY: 'auto',
          }}
        >
          <Box sx={{ mt: 0, p: 2, borderRadius: 4 }}>
            <TextField
              label='Name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              variant='outlined'
              fullWidth
              InputProps={{
                sx: {
                  color: 'black',
                  backgroundColor: 'white',
                  height: '50px',
                  fontFamily: 'Montserrat',
                  fontSize: {
                    xs: '10px',
                    sm: '12px',
                    md: '12px',
                    lg: '14px',
                    xl: '14px',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'black',
                },
              }}
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            />
            <TextField
              label='Email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              variant='outlined'
              fullWidth
              type='email'
              InputProps={{
                sx: {
                  color: 'black',
                  backgroundColor: 'white',
                  height: '50px',
                  fontFamily: 'Montserrat',
                  fontSize: {
                    xs: '12px',
                    sm: '12px',
                    md: '12px',
                    lg: '14px',
                    xl: '14px',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'black',
                },
              }}
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label='Subject'
              name='subject'
              value={formData.subject}
              onChange={handleChange}
              variant='outlined'
              fullWidth
              InputProps={{
                sx: {
                  color: 'black',
                  backgroundColor: 'white',
                  height: '50px',
                  fontFamily: 'Montserrat',
                  fontSize: {
                    xs: '12px',
                    sm: '12px',
                    md: '12px',
                    lg: '14px',
                    xl: '14px',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'black',
                },
              }}
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <TextField
              label='Message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              variant='outlined'
              multiline
              rows={5}
              fullWidth
              InputProps={{
                sx: {
                  color: 'black',
                  backgroundColor: 'white',
                  height: {
                    xs: '140px',
                    sm: '140px',
                    md: '150px',
                    lg: '160px',
                    xl: '160px',
                  },
                  fontFamily: 'Montserrat',
                  fontSize: {
                    xs: '12px',
                    sm: '12px',
                    md: '12px',
                    lg: '14px',
                    xl: '14px',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: 'black',
                },
              }}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  mt: 1,
                  width: { xs: '40%', md: '36%', lg: '20%', xl: '20%' },
                  backgroundColor: 'white',
                  color:'black',
                  borderRadius: '20px',
                   //boxShadow:'0px 1px 2px inset grey',
                  fontSize: { xs: 11, sm: 11, md: 13, lg: 14, xl: 14 },
                  '&:hover': {
                    backgroundColor: 'black',
                    color:'white',
                    boxShadow:'0px 1px 2px inset grey',
                  },
                }}
                type='submit'
                variant='contained'
                color='primary'
              >
                Send Message
              </Button>
            </Box>
            {confirmation && (
              <Typography variant='body1' color='secondary' sx={{ mt: 2 }}>
                {confirmation}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
