import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Create the context
const ActiveComponentContext = createContext();

// Create a provider component
const ActiveComponentProvider = ({ children }) => {
 

  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  // const [activeComponent, setActiveComponent] = useState('MenuBox'); //previous setup(need to check why MenuBox)
  const [activeComponent, setActiveComponent] = useState('');

  //Theme State
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [starCount, setStarCount] = useState(0);

  // Function to Determine the current theme/background based on the time of day
  const determineTheme = (hours) => {
    if (hours >= 6 && hours < 18) {
      return 'daytime';
    } else if (hours >= 18 && hours < 22) {
      return 'nighttime';
    } else if (hours >= 22 || hours <= 3) {
      return 'twilight';
    } else {
      return 'nighttime';
    }
  };

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    // const hours = 3
    const theme = determineTheme(hours);

    //currently made it so theme stays consistent with white background
    switch (theme) {
      case 'daytime':
        setBackgroundColor('#ffffff'); 
        setStarCount(0);
        break;
      case 'nighttime':
        setBackgroundColor('#ffffff'); //for now, keep white
        //setBackgroundColor('#030202');
        //setStarCount(200);//changin stars in the background
        break;
      case 'twilight':
        setBackgroundColor('#ffffff');//for now, keep white
        //setBackgroundColor('#000000');
        // if (hours >= 22) {
        //   setStarCount(((hours - 22) / 4) * 4000);
        // } else if (hours <= 3) {
        //   setStarCount((hours / 3) * 4000);
        // }
        break;
      default:
        setBackgroundColor('#ffffff');
        setStarCount(0);
        break;
    }
  }, []);

  const switchToComponent = (component) => {
    setActiveComponent(component);
  };
  const switchToPath = (path) => {
    setActiveComponent(path);
  };

  return (
    <ActiveComponentContext.Provider
      value={{
        activeComponent,
        switchToComponent,
        activePath,
        setActivePath,
        backgroundColor,
        starCount,
      }}
    >
      {children}
    </ActiveComponentContext.Provider>
  );
};
// Custom Hook to use the context
const useActiveComponent = () => useContext(ActiveComponentContext); //returns values given by provider

export { ActiveComponentProvider, useActiveComponent };
