import { useParams, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { PostsContext } from '../../contexts/PostsContext';
import { Box, Typography } from '@mui/material';
import { LazyLoadedImage } from '../../Components/LazyLoadedImage';
import { useActiveComponent } from '../../Components/ActiveComponentContext.js';

const CollectionPage = () => {
  const { activePath, setActivePath } = useActiveComponent();
  const { artCollection } = useParams(); // Extract the collection name from the route params
  const { postsData } = useContext(PostsContext) || {};
  //console.log(JSON.stringify(postsData,null,2))
  // const collection = postsData.find(
  //   (collection) => collection.artCollection === artCollection
  // );
  const navigate = useNavigate()

  const handleNavigation = (path) => {
    //resetExpandedItems();
    navigate(`/${path}`); // Navigate to the respective path
    setActivePath(`/${path}`);
    //setOpenDrawer(false); // Close the drawer after navigating
  };

  // Retrieve the posts array for the selected artCollection
  const posts = postsData[artCollection] || [];
  // Reverse the order of posts
  //const reversedPosts = [...postsData].reverse();
  const reversedPosts = postsData[artCollection]
    ? [...postsData[artCollection]].reverse()
    : [];

  // // Ensure postsData is treated as an array
  // const collections = Array.isArray(postsData) ? postsData : [];

  // // Find the collection based on the artCollection param
  // const collection = collections.find(
  //   (collection) => collection.artCollection === artCollection
  // );

  // if (!collection) {
  //   return <Typography variant="h5">Collection not found</Typography>;
  // }
  if (posts.length === 0) {
    return (
      <Typography variant='h5'>
        No posts available for this collection
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh', // Full viewport height
        overflowY: 'auto', // Enable vertical scrolling of entire homepage
        mb: 0,
        // border:'2px dashed red'
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000, // Ensure it's above other content while scrolling
          backgroundColor: 'black', // Set background so it's visible while scrolling
          // p: 1,
          // mt:{lg:2, xl:2}
          p: 2,
        }}
      >
        <Typography
          //variant='h6'
          sx={{
            color: 'white',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontSize: { xs: 16, sm: 16, md: 16, lg: 18, xl: 18 },
          }}
        >
          {artCollection}
        </Typography>
        <Typography
          sx={{
            color: 'white',
            textAlign: 'center',
            fontWeight:100,
            mt: 1,
            mb: 1,
            fontSize: { xs: 11, sm: 11, md: 13, lg: 14, xl: 14 },
            fontStyle:'italic',
            cursor:'pointer',
            '&:hover':{
              color:'skyblue',
              transform: 'scale(1.02)', // Optional: adds a slight zoom effect
                transition:
                  'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',

            }
          }}
          onClick={()=>handleNavigation('contactus')}
        >
          (Contact me for custom prints)
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: 2,
          gap: 2,

        }}
      >
        {reversedPosts.map((post) =>
          post.images.map((imageSrc, index) => (
            <LazyLoadedImage
            key={`${post.id}-${index}`}
            src={imageSrc}
            alt={post.title}
            title={post.title}
            collection={post.artCollection}
          />
          ))
        )}
      </Box>
    </Box>
  );
};

export default CollectionPage;

//Other way of doing Lazy loading:
            // <Box
            //   key={post.id}
            //   sx={{
            //     margin: 2,
            //     '&:hover': {
            //       transform: 'scale(1.03)', // Optional: adds a slight zoom effect
            //       transition:
            //         'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            //     },
            //   }}
            // >
            //   <Box
            //     component='img'
            //     loading="lazy" // Enable lazy loading
            //     // src={post.images[0]}
            //     src={imageSrc}
            //     alt={post.title}
            //     sx={{
            //       objectFit: 'cover',
            //       // width: '200px',
            //       // height: 'auto',
            //       width: {
            //         xs: '300px',
            //         sm: '600px',
            //         md: '600px',
            //         lg: '600px',
            //         xl: '600px',
            //       },
            //       height: {
            //         xs: '500px',
            //         sm: '500px',
            //         md: '500px',
            //         lg: '500px',
            //         xl: '500px',
            //       },
            //       borderRadius: '8px',
            //       '&:hover': {
            //         boxShadow: '0px 0px 11px grey',
            //         // transform: 'scale(1.03)', // Optional: adds a slight zoom effect
            //         // transition:
            //         //   'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            //       },
            //     }}
            //   />
            //   <Typography
            //     variant='subtitle1'
            //     sx={{
            //       color: 'white',
            //       fontFamily: 'Montserrat',
            //       textAlign: 'center',
            //       mt: 1,
            //     }}
            //   >
            //     {post.title}
            //   </Typography>
            // </Box>
