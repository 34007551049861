import { Navigate, Outlet } from 'react-router-dom';
//import { useAuth } from '../AuthContext';
import { useAuth } from '../../contexts/AuthContext';

//can wrap around the pages we want to protect, if user not logged in.
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Navigate to='/' replace />;
  }
  return children ? children : <Outlet />;
  //The Outlet component is used to render nested routes (like createdposts and savedposts) inside the Dashboard.
};

export default ProtectedRoute;

//use replace with the redirect to '/',
//it ensures that the currentUser cannot navigate back
//to the protected route they were trying to access before being redirected to the login page.
//This is often desirable in authentication flows to prevent users from accessing restricted content
//by simply pressing the back button.
