import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Dialog, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const LazyLoadedImage = ({ src, alt, title, collection }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(collection.indexOf(src));
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target);
        }
      },
      {
        rootMargin: '100px',
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? collection.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    console.log('handle next clicked');
    setCurrentIndex((prevIndex) =>
      prevIndex === collection.length - 1 ? 0 : prevIndex + 1
    );
  };

  //   const currentImageSrc = collection[currentIndex];

  return (
    <>
      <Box
        sx={{
          margin: 4,
          position: 'relative',
          width: '600px',
          height: '500px',
        }}
      >
        {!isLoaded && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box
          ref={imgRef}
          component='img'
          src={src}
          alt={alt}
          onError={() => setHasError(true)}
          onClick={handleOpen}
          sx={{
            objectFit: 'cover',
            width: {
              xs: '300px',
              sm: '600px',
              md: '600px',
              lg: '600px',
              xl: '600px',
            },
            height: {
              xs: '500px',
              sm: '500px',
              md: '500px',
              lg: '500px',
              xl: '500px',
            },

            borderRadius: '8px',
            '&:hover': {
              boxShadow: {
                xs: 'none',
                sm: 'none',
                md: '0px 0px 11px grey',
                lg: '0px 0px 11px grey',
                xl: '0px 0px 11px grey',
              },
              transform: 'scale(1.03)',
              transition:
                'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            },
          }}
        />
        {hasError && (
          <Typography
            variant='subtitle1'
            sx={{ color: 'red', textAlign: 'center', mt: 1 }}
          >
            Image failed to load
          </Typography>
        )}
        <Typography
          variant='subtitle1'
          sx={{
            color: 'white',
            fontFamily: 'Montserrat',
            textAlign: 'center',
            mt: 1,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <Box
          sx={{
            position: 'relative',
            p: 0,
            backgroundColor: 'black', // Dialog background for better contrast
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: -2,
              right: -2,
              backgroundColor: 'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: 0,
              top:'50%',
            
              backgroundColor:'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          >
            <ArrowBackIosIcon />
          </IconButton> */}

          <Box
            component='img'
            src={src}
            // src={currentImageSrc}
            alt={alt}
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              p: 0,
              borderRadius: '14px',
            }}
          />
          {/* <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top:'50%',
              right: 0,
              backgroundColor:'black',
              color: 'white',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton> */}
        </Box>
      </Dialog>
    </>
  );
};
