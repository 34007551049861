import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  connectStateResults,
  Configure,
} from 'react-instantsearch-dom';
import { fontFamily } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';

// Initialize Algolia Client
const searchClient = algoliasearch(
  'ARBNPWCP6Q',
  '0a4876dcca3c4357ad006a5914bed63d'
);

// Component for displaying search results (hits)
const Hit = ({ hit }) => {
  const handleClick = () => {
    // const postUrl = `${window.location.origin}/post/${hit.objectID}`;
    const postUrl = `${window.location.origin}/${hit.path}`;
    window.open(postUrl, '_blank'); // Open the post page in a new window
  };
  return (
    <Box
      sx={{
        padding: { xs: 1, sm: 1, md: 2, lg: 1.5, xl: 1.5 },
        boxShadow: '0px 1px 2px grey',
        borderRadius: '8px',
        marginBottom: 2,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        //border:'2px solid red',
        width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' },
        //  ml:'auto',
        //  mr:'auto'
      }}
      onClick={handleClick}
    >
      <img
        src={hit.images?.[0] || '/default-image.jpg'}
        alt={hit.title}
        // style={{ width: '100%', borderRadius: '8px' }}
        style={{
          width: '80px',
          height: '80px',
          marginRight: '12px',
          borderRadius: '12px',
          boxShadow: '0px 1px 2px grey',
          objectFit: 'cover',
        }}
      />
      <Box>
        <Typography
          variant='h6'
          component='h2'
          sx={{
            fontFamily: 'Montserrat',
            fontSize: {
              xs: '15px',
              sm: '15px',
              md: '16px',
              lg: '18px',
              xl: '18px',
            },
            fontWeight: '500',
            color: 'black',
            '& .ais-Highlight-highlighted': {
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#0073e6',
            },
          }}
        >
          <Highlight attribute='title' hit={hit} highlightedTagName='span' />
        </Typography>
        <Typography
          variant='body2'
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '11px',
            color: 'black',
            '& .ais-Highlight-highlighted': {
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#0073e6',
            },
          }}
        >
          <Highlight
            attribute='description'
            hit={hit}
            highlightedTagName='span'
          />
        </Typography>
        <Typography
          variant='caption'
          display='block'
          // color='text.secondary'
          color='darkblue'
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '10px',
            '& .ais-Highlight-highlighted': {
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#0073e6',
            },
          }}
        >
          <Highlight attribute='category' hit={hit} highlightedTagName='span' />
        </Typography>
        {/* Add username to the displayed results */}
        {hit.username ? (
          <Typography
            variant='caption'
            display='block'
            color='gray'
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '10px',
              '& .ais-Highlight-highlighted': {
                fontStyle: 'italic',
                fontWeight: 'bold',
                color: '#0073e6',
              },
            }}
          >
            Posted by: {hit.username}
            {/* Posted by: {hit.username || 'Unknown'} */}
          </Typography>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

// Component to display hits or a message based on search state
const Results = connectStateResults(({ searchState, searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0;

  if (!searchState?.query) {
    return (
      <Typography
        variant='body2'
        sx={{
          textAlign: 'center',
          mt: 0,
          fontFamily: 'Montserrat',
          color: 'black',
          fontSize: '14px',
        }}
      >
        Start typing to see results...
      </Typography>
    );
  }

  if (!hasResults) {
    return (
      <Typography
        variant='body2'
        sx={{
          textAlign: 'center',
          mt: 0,
          color: 'black',
          fontFamily: 'Montserrat',
          fontSize: '14px',
        }}
      >
        No results found
      </Typography>
    );
  }

  return <Hits hitComponent={Hit} />;
});

const SearchBar = () => {
  const [query, setQuery] = useState(''); // State to track input value
  const searchBoxRef = useRef(null);

  const handleSearchStateChange = ({ query }) => {
    setQuery(query);
  };

  const handleClose = () => {
    setOpen(false);
    setQuery(''); // Clear query when closing
    console.log('handleClose initiated');
  };

  // Function to programmatically trigger the reset button click
  const triggerReset = () => {
    const resetButton = document.querySelector('.ais-SearchBox-reset');
    if (resetButton) {
      resetButton.click();
    }
  };

  // Handle clicks outside search area
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Get the search container and search input
      const searchContainer = searchBoxRef.current;
      // const resultsBox = document.querySelector('.ais-Hits');
      const resultsBox = document.querySelector('.search-results-box'); // Changed from .ais-Hits - affect when results are found or not found
      const searchInput = document.querySelector('.ais-SearchBox-input');

      // Ignore clicks on the search input and its children
      if (searchInput && searchInput.contains(event.target)) {
        return;
      }

      // Check if click is outside both search box and results
      if (searchContainer && resultsBox) {
        const isClickInSearch = searchContainer.contains(event.target);
        const isClickInResults = resultsBox.contains(event.target);

        if (!isClickInSearch && !isClickInResults) {
          triggerReset();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <Box sx={{ position: 'relative', marginTop: 2, padding: 2, mb: 1 }}>
      {/* <Box sx={{ marginTop: 2, padding: 2 }}> */}
      {/* <InstantSearch searchClient={searchClient} indexName="xpat360"> */}
      <InstantSearch
        searchClient={searchClient}
        indexName='xpat360'
        onSearchStateChange={handleSearchStateChange}
      >
        <Configure typoTolerance={false} />
        {/* Search Box */}
        <Box
          ref={searchBoxRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            //width: '440px',
            margin: '0 auto',
            //border:'2px dashed green'
          }}
        >
          {/* Container for SearchBox and Icon */}
          <Box
            sx={{
              position: 'relative',
              width: '100%', // Ensures the container matches the search box's width
              //border:'2px solid red'
            }}
          >
            <SearchIcon
              sx={{
                position: 'absolute',
                left: '12px', // Adjusts horizontal position
                top: '50%', // Centers vertically
                transform: 'translateY(-50%)',
                color: 'grey.500',
                fontSize: '20px',
              }}
            />
            <SearchBox
              translations={{
                placeholder: 'Search...',
              }}
              className='custom-search-box' // Apply custom styles
              submitIconComponent={() => null} // Removes default submit button
            />
          </Box>
        </Box>

        {/* Config to avoid showing all results */}
        <Configure hitsPerPage={16} query={query} />

        {/* Results */}
        {query && (
          <Box
            className='search-results-box'
            sx={{
              p: { xs: 2, sm: 2, md: 2.5, lg: 2.5, xl: 2.5 },

              position: 'absolute',
              top: '60px',
              left: { xs: '49%', sm: '50%', md: '50%', lg: '50%', xl: '50%' },
              transform: 'translateX(-50%)',
              width: {
                xs: '325px',
                sm: '360px',
                md: '400px',
                lg: '400px',
                xl: '400px',
              },
              maxHeight: {
                xs: '330px',
                sm: '400px',
                md: '500px',
                lg: '500px',
                xl: '500px',
              },
              backgroundColor: '#fff',
              border: '0.8px solid #ccc',
              borderRadius: '9px',
              overflowY: 'auto',
              zIndex: 1300, // Below search box
              boxShadow: '0 0px 6px inset rgba(0,0,0,0.1)',
            }}
          >
            <Results />
            {/* <Hits hitComponent={Hit} /> */}
          </Box>
        )}
      </InstantSearch>
    </Box>
  );
};

export default SearchBar;

//Earlier results component:
// // Connect a custom results handler to conditionally display hits
// const Results = connectStateResults(({ searchState }) =>
//   searchState && searchState.query ? (
//     <Hits hitComponent={Hit} />
//   ) : (
//     <Typography variant='body2' sx={{ textAlign: 'center', mt: 2 }}>
//       Start typing to see results...
//     </Typography>
//   )
// );

//HandleClicks solution:
// // Handle clicks outside search area
// useEffect(() => {
//   const handleClickOutside = (event) => {
//     const searchContainer = searchBoxRef.current;
//     const resultsBox = document.querySelector('.ais-Hits');

//     // Check if click is outside both search box and results
//     if (searchContainer && resultsBox) {
//       const isClickInSearch = searchContainer.contains(event.target);
//       const isClickInResults = resultsBox.contains(event.target);

//       if (!isClickInSearch && !isClickInResults) {
//         triggerReset();
//       }
//     }
//   };

//   document.addEventListener('mousedown', handleClickOutside);
//   return () => document.removeEventListener('mousedown', handleClickOutside);
// }, []);

{
  /* <SearchIcon
            sx={{
              position: 'absolute',
              left: '12px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'grey.500',
              fontSize:'20px'
            }}
          />
          <SearchBox
            translations={{
              placeholder: 'Find anything...',
            }}
            className='custom-search-box' // Apply custom styles
            submitIconComponent={() => null} // Removes default submit button
            style={{
              width: '100%',
              borderRadius: '26px',
              padding: '8px',
              border: '1px solid #ccc',
              position: 'relative',
              zIndex: 1200, // Ensure it's above results
            }}
          /> */
}

//DIALOG

{
  /* Dialog for Search Results */
}

{
  /* <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth='sm'
         
          disableEnforceFocus // Allow typing in search box while dialog is open
          disableRestoreFocus
          //disableRestoreFocus // Ensures the search box stays interactive
          //style={{ borderRadius: '40px' }}
          sx={{maxHeight:'620px', mt:10,zIndex: 1300, // Ensure it appears on top
            '& .MuiPaper-root': {
              borderRadius: '16px', // Rounded corners
            }, }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <Typography
              variant='h9'
              sx={{ fontFamily: 'Montserrat', pt: 1, pl: 1 }}
            >
              Search Results
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent
            sx={{
              overflowY: 'auto',
              //border: '2px solid red',
              p: 3,
              // height: '50vh',
            }}
          >
            <Hits hitComponent={Hit} />
          </DialogContent>
        </Dialog> */
}

// <SearchBox
// translations={{
//   placeholder: 'Find anything...',
// }}
// className='custom-search-box' // Apply custom styles
// submitIconComponent={() => null} // Removes default submit button
// //onFocus={handleOpen} // Open dialog on focus
// // Styling the search box
// // className='search-box'
// // style={{
// //   width: '800px',
// //   borderRadius: '26px',
// //   padding: '8px',
// //   border: '1px solid #ccc',
// // }}
// style={{
//   width: '100%',
//   borderRadius: '26px',
//   padding: '8px',
//   border: '1px solid #ccc',
//   position: 'relative',
//   zIndex: 1200, // Ensure it's above results
// }}
// />

{
  /* <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <Box sx={{ position: 'relative', padding: 2 }}>
         
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 16, top: 16 }}
            >
              <CloseIcon />
            </IconButton>

       
            <Box
              sx={{
                height: '50vh',
                overflowY: 'auto',
                marginTop: 3,
              }}
            >
              <Results />
            </Box>
          </Box>
        </Dialog> */
}

// import React, { useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import InputBase from '@mui/material/InputBase';
// import { alpha, styled } from '@mui/material/styles';
// import IconButton from '@mui/material/IconButton';
// import { Box } from '@mui/material';
// import { db } from '../../Firebase/firebase.js';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import algoliasearch from 'algoliasearch/lite'; // Import Algolia client
// import { InstantSearch, SearchBox, Hits, Pagination, Highlight } from 'react-instantsearch-dom'; // InstantSearch widgets

// const searchClient = algoliasearch('ARBNPWCP6Q', '0a4876dcca3c4357ad006a5914bed63d');

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));

// // Component for rendering individual hits
// const Hit = ({ hit }) => (
//   <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: '8px', marginBottom: 2 }}>
//     <img src={hit.images[0]} alt={hit.title} style={{ width: '100%', borderRadius: '8px' }} />
//     <Typography variant="h6" component="h2">
//       <Highlight attribute="title" hit={hit} />
//     </Typography>
//     <Typography variant="body2">
//       <Highlight attribute="description" hit={hit} />
//     </Typography>
//     <Typography variant="caption" display="block" color="text.secondary">
//       <Highlight attribute="category" hit={hit} />
//     </Typography>
//   </Box>
// );

// const SearchBar = (width) => {
//   const [searchOpen, setSearchOpen] = useState(true);
//   const [searchQuery, setSearchQuery] = useState('');

//   // const searchClient = algoliasearch('ARBNPWCP6Q', '0a4876dcca3c4357ad006a5914bed63d');

//   //SEARCH FUNCTIONS
//   const handleSearchClick = () => {
//     setSearchOpen((prevOpen) => !prevOpen);
//   };

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const searchFirestore = async (queryText) => {
//     const postsRef = collection(db, 'posts');
//     const commentsRef = collection(db, 'comments');
//     const usersRef = collection(db, 'users');

//     // Create Firestore queries
//     const postTitleQuery = query(postsRef, where('title', '>=', queryText));
//     const postDescriptionQuery = query(
//       postsRef,
//       where('description', '>=', queryText)
//     );
//     const commentsQuery = query(commentsRef, where('comment', '>=', queryText));
//     const userQuery = query(usersRef, where('username', '>=', queryText));

//     // Get query results
//     const postTitleResults = await getDocs(postTitleQuery);
//     const postDescriptionResults = await getDocs(postDescriptionQuery);
//     const commentsResults = await getDocs(commentsQuery);
//     const userResults = await getDocs(userQuery);

//     return {
//       postTitleResults,
//       postDescriptionResults,
//       commentsResults,
//       userResults,
//     };
//   };

//   const handleSearchSubmit = async () => {
//     if (searchQuery.trim()) {
//       const results = await searchFirestore(searchQuery);
//       const postDescriptionResults = results.postDescriptionResults;

//       // Check if there are any results
//       if (!postDescriptionResults.empty) {
//         // Extract and log/display each post
//         postDescriptionResults.forEach((doc) => {
//           const postData = doc.data(); // This contains the actual post data
//           console.log('postDescriptionResults Post found:', postData);
//           // You can now display this data to the user
//           // e.g., set it to state or pass it to a display component
//         });
//       } else {
//         console.log('No posts found with the given query');
//       }
//     }
//     // Handle search logic here
//   };

//   return (
//     <Box>
//       <IconButton onClick={handleSearchClick} disableRipple>
//         {/* <SearchIcon
//           sx={{
//             fontSize: { xs: 24, sm: 24, md: 25, lg: 26, xl: 26 },
//             mr: { xs: 0.5, sm: 0.5, md: 1, lg: 6, xl: 6 },
//           }}
//         /> */}
//       </IconButton>
//       {/* {searchOpen && ( */}
//       <Search>
//         <SearchIconWrapper>
//           <SearchIcon
//             sx={{
//               color: 'black',
//               fontSize: { xs: 16, sm: 18, md: 20, lg: 22, xl: 22 },
//               mr: { xs: 0.5, sm: 0.5, md: 1, lg: 6, xl: 6 },
//               mb: 0.8,
//             }}
//           />
//         </SearchIconWrapper>
//         <StyledInputBase
//           placeholder='Find anything'
//           inputProps={{ 'aria-label': 'search' }}
//           value={searchQuery}
//           onChange={handleSearchChange}
//           onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
//           sx={{
//             borderRadius: '20px',
//             boxShadow: '0px 0px 0.5px grey',
//             color: 'black',
//             backgroundColor: 'lightgrey',
//             opacity: '0.6',
//             fontFamily: 'Montserrat',
//             // width:{xs:width,sm:width,md:width,lg:width,xl:width},
//             width: width,
//             mr: { xs: 0, sm: 0, md: 0, lg: 2, xl: 2 },
//             mb: 1,
//             p: 0,
//           }}
//         />
//       </Search>
//       {/* )} */}
//     </Box>
//   );
// };

// export default SearchBar;

// //WHEN I WASUSING FIRESTORE SEARCH BUT ALGOLIA IS BETTER FOR SPECIFIC SEARCH
// //   const searchFirestore = async (query) => {
// //     const postsRef = db.collection('posts');
// //     const commentsRef = db.collection('comments');
// //     const usersRef = db.collection('users');

// //     // Perform search by post titles, ads titles, or usernames
// //     const postTitleResults = await postsRef.where('title', '>=', query).get();
// //     const postDescriptionResults = await postsRef
// //       .where('description', '>=', query)
// //       .get();

// //     const commentsResults = await commentsRef
// //       .where('comment', '>=', query)
// //       .get();

// //     const userResults = await usersRef.where('username', '>=', query).get();

// //     return {
// //       postTitleResults,
// //       postDescriptionResults,
// //       commentsResults,
// //       userResults,
// //     };
// //   };
